import { appConfigService } from "@/providers/services/app/app-config";
import router from "@/router/router";
import { utils } from "@/utils/utils";
import axios from "axios";

/**
 * Api is a generic REST Api handler. Set your API url first.
 */
export class Http {
  private static INSTANCE = new Http();

  static get instance() {
    return this.INSTANCE;
  }

  public get(url: string, headers?: any, params?: any) {
    const options = {
      url: this.formURL(url),
      method: "GET",
      headers,
      params,
    };
    return axios(options);
  }

  public post(
    url: string,
    headers?: any,
    data?: any,
    isFile = false,
    onUploadProgress?: any
  ) {
    const options: any = {
      url: this.formURL(url),
      method: "POST",
      headers,
      data: isFile ? data : data ? JSON.stringify(data) : "{}",
    };
    if (isFile) {
      options["onUploadProgress"] = onUploadProgress;
    }
    return axios(options);
  }

  public put(url: string, headers?: any, data?: any) {
    const options = {
      url: this.formURL(url),
      method: "PUT",
      headers,
      data,
    };
    return axios(options);
  }

  public delete(url: string, headers?: any, data?: any) {
    const options = {
      url: this.formURL(url),
      method: "DELETE",
      headers,
      data,
    };
    return axios(options);
  }

  public getBasicHeaders(token: string) {
    return {
      "Content-Type": "application/json",
      Authorization: `Basic ${window.btoa(token)}`,
    };
  }
  public getAnonymousTokenHeaders() {
    return {
      "Content-Type": "application/json",
      Authorization: `Basic ${localStorage.getItem("anonymousToken")}`,
    };
  }
  public getTokenHeaders(token?: string) {
    const access_token = token ? token : localStorage.getItem("access_token");
    return {
      "Content-Type": "application/json",
      Authorization: `Token ${access_token}`,
    };
  }

  private formURL(url: string) {
    const apiUrl = appConfigService.getApiUrl();
    if (apiUrl && !url.includes("http")) {
      url = `${apiUrl}/${url}`;
    }
    return url;
  }
}

export const http = Http.instance;

axios.interceptors.response.use(
  function (successRes: any) {
    return successRes;
  },
  function (error: any) {
    if (error && error.response) {
      if (error.response.status === 401) {
        const getUserDetail = utils.getUserDetails();
        if (getUserDetail) {
          utils.deleteLocalStorage();
          router
            .push({
              name: "login-page",
              params: {
                isTokenExpiry: "true",
              },
            })
            .catch(() => {
              /* ignore */
            });
        }
      }
    }
    return Promise.reject(error);
  }
);
