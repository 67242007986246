import { MORE_INFORMATION_QUERY } from "@/graphql/more-information";
import gql from "graphql-tag";
import Vue from "vue";
import Component from "vue-class-component";
import { InformationData } from "./more-information.interface";

@Component({})
export default class MoreInformation extends Vue {
  public informationData: InformationData[] = [];
  public currentRoute = "";

  public async created() {
    this.currentRoute = this.$route.path.split("/")[1];
    await this.$apollo
      .query({
        query: gql`query ${MORE_INFORMATION_QUERY}`,
      })
      .then((result) => {
        this.informationData = result.data.forMoreInformations.data;
      });
    this.$nextTick(() => {
      const getDescriptionElement =
        document.getElementsByClassName("description")[0];
      const getImageFigureElement =
        getDescriptionElement.getElementsByClassName("image");
      for (let i = 0; i < getImageFigureElement.length; i++) {
        getImageFigureElement[i].classList.add(`image-${i + 1}`);
      }
    });
  }
}
