import { http } from "../http";

export class CommonService {
  private static INSTANCE = new CommonService();

  static get instance() {
    return this.INSTANCE;
  }

  public addTraining(addTrainingdata: any) {
    let data = {};
    data = addTrainingdata;
    const endpoint = "cyapss/user/training";
    const headers = http.getTokenHeaders();
    return http.post(endpoint, headers, data).then((response) => {
      return response;
    });
  }

  public addPeerSupport(addPeerdata: any) {
    let data = {};
    data = addPeerdata;
    const endpoint = "cyapss/user/peer/support";
    const headers = http.getTokenHeaders();
    return http.post(endpoint, headers, data).then((response) => {
      return response;
    });
  }

  public rubricApplication(scoringRubric: any, typeofApplication: string) {
    let data = {};
    data = scoringRubric;
    const endpoint = `cyapss/${typeofApplication}/rubric`;
    const headers = http.getTokenHeaders();
    return http.put(endpoint, headers, data).then((response) => {
      return response.data;
    });
  }

  public approveApplication(comments: any, typeofApplication: string) {
    let data = {};
    data = comments;
    const endpoint = `cyapss/${typeofApplication}/approve`;
    const headers = http.getTokenHeaders();
    return http.put(endpoint, headers, data).then((response) => {
      return response.data;
    });
  }

  public applicationMyStatus() {
    const endpoint = `cyapss/user/stats`;
    const headers = http.getTokenHeaders();
    return http.get(endpoint, headers).then((response) => {
      return response.data;
    });
  }

  public adminDashboard() {
    const endpoint = `cyapss/stats`;
    const headers = http.getTokenHeaders();
    return http.get(endpoint, headers).then((response) => {
      return response.data;
    });
  }

  public uploadFile(file: any, onUploadProgress?: any, form = "") {
    const formdata = new FormData();
    let headers = {};
    for (let index = 0; index < file.length; index++) {
      formdata.append("file", file[index]);
    }
    const endpoint = "upload";
    if (form === "training-form") {
      const anonymousToken = localStorage.getItem("anonymousToken");
      headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Token ${anonymousToken}`,
      };
    } else {
      headers = {
        ...http.getTokenHeaders(),
        "Content-Type": "multipart/form-data",
      };
    }
    return http
      .post(endpoint, headers, formdata, true, onUploadProgress)
      .then((response) => {
        return response;
      });
  }
}

export const commonService = CommonService.instance;
