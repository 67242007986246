var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"score-rubic-form-main-container"},[_c('div',{staticClass:"score-rubic-form-container"},[_c('form',{class:_vm.formData.class,on:{"submit":function($event){$event.preventDefault();_vm.onSubmit(_vm.currentRoute.split('/')[2])}}},[_vm._l((_vm.formData.formFields),function(scoringRubric,index){return _c('div',{key:index},[_vm._l((scoringRubric.fields),function(formFieldsContainer,indexi){return _c('div',{key:indexi},[(scoringRubric.parentSlug === _vm.currentRoute.split('/')[2])?[(formFieldsContainer[0].header)?_c('label',{staticClass:"heading",domProps:{"innerHTML":_vm._s(formFieldsContainer[0].header)}}):_vm._e(),_vm._l((formFieldsContainer),function(formFields,indexj){return _c('div',{key:indexj},[(formFields.type !== 'scoring')?_c('div',{class:formFields.class},[(!_vm.hideDiv.includes(formFields.name))?[_c('label',{staticClass:"field-label"},[_vm._v(_vm._s(formFields.label)+" "),(formFields?.validate?.required)?_c('span',{staticClass:"required-symbol"},[_vm._v(" * ")]):_vm._e()]),_c(formFields.field,_vm._b({directives:[{name:"validate",rawName:"v-validate",value:(formFields?.validate?.veeValidate),expression:"formFields?.validate?.veeValidate"}],tag:"component",class:[
                      formFields.elementClass,
                      _vm.isPreviewRubric &&
                      (_vm.progressStatus !== 'NEED MORE INFO' || _vm.hideStatus)
                        ? 'pointer-events'
                        : '',
                    ],attrs:{"formIndex":`${indexi}` + `${indexj}`,"editForm":_vm.disableEvent ? false : true,"formType":_vm.disableEvent ? 'preview' : ''},model:{value:(
                      _vm.didtheApplicantFields[scoringRubric.parentSlug][
                        formFields.name
                      ]
                    ),callback:function ($$v) {_vm.$set(_vm.didtheApplicantFields[scoringRubric.parentSlug], 
                        formFields.name
                      , $$v)},expression:"\n                      didtheApplicantFields[scoringRubric.parentSlug][\n                        formFields.name\n                      ]\n                    "}},'component',{
                      ...formFields.propBind,
                      ...formFields.validate,
                      disabled: _vm.disableEvent,
                      name:
                        formFields?.validate?.name +
                        `${indexi}` +
                        `${indexj}`,
                    },false)),(
                      formFields?.validate?.required == true &&
                      _vm.$validator.errors.has(
                        formFields?.validate?.name + `${indexi}` + `${indexj}`
                      )
                    )?_c('span',{staticClass:"is-invalid"},[_vm._v(" This field is required ")]):_vm._e()]:_vm._e()],2):_vm._e(),(formFields.type === 'scoring')?_c('div',{class:formFields.class},[_c('label',{staticClass:"field-label rating-label"},[_vm._v(_vm._s(formFields.title)+" ")]),(formFields.description)?_c('p',{staticClass:"description"},[_vm._v(" "+_vm._s(formFields.description)+" ")]):_vm._e(),_vm._l((formFields.fields),function(pointsField,indexj){return _c('div',{key:indexj,class:pointsField.class},[_c('label',{staticClass:"points-label"},[_vm._v(_vm._s(pointsField.label))]),_c('ScoreRatingComponent',{class:_vm.isPreviewRubric &&
                      (_vm.progressStatus !== 'NEED MORE INFO' || _vm.hideStatus)
                        ? 'pointer-events'
                        : '',attrs:{"options":pointsField.points,"parentSlug":formFields.name,"isScoringSubmited":_vm.isScoringSubmited,"value":_vm.isPreviewRubric &&
                      _vm.rubricRevokeObject[pointsField.points.key] >= 0
                        ? _vm.rubricRevokeObject[pointsField.points.key]
                        : -1},on:{"onChangeScore":function($event){return _vm.onChangeScore($event, formFields.name)},"scoredData":function($event){return _vm.scored($event)}}})],1)})],2):_vm._e()])})]:_vm._e()],2)}),(
            scoringRubric.parentSlug === 'training' && _vm.formType === 'training'
          )?_c('div',{staticClass:"scoring-summary"},[_c('label',{staticClass:"scoring-label"},[_vm._v("Scoring Summary")]),_c('div',{staticClass:"calculation"},[_c('p',[_vm._v("Personal Recovery Score")]),_c('span',[_vm._v(_vm._s(_vm.personalRecoveryScoredTotal)+"/"+_vm._s(_vm.personalRecoveryTotal))])]),_c('div',{staticClass:"calculation"},[_c('p',[_vm._v("Motivation Score")]),_c('span',[_vm._v(_vm._s(_vm.motivationScoredTotal)+"/"+_vm._s(_vm.motivationTotal))])]),_c('div',{staticClass:"calculation"},[_c('p',[_vm._v("Writing Score")]),_c('span',[_vm._v(_vm._s(_vm.writingScoredTotal)+"/"+_vm._s(_vm.writingTotal))])]),_c('div',{staticClass:"calculation total-calculation"},[_c('p',[_vm._v(_vm._s(scoringRubric.totalSoredLabel))]),_c('span',[_vm._v(_vm._s(_vm.overallScored)+"/"+_vm._s(_vm.overallTotal))])]),_c('div',{staticClass:"calculation multiple-total-calculation"},[_c('p',[_vm._v(_vm._s(scoringRubric.multipleTotalSoredLabel))]),_c('span',[_vm._v(_vm._s(_vm.overallScored * 2)+"/"+_vm._s(_vm.overallTotal * 2))])])]):_vm._e()],2)}),_c('div',{staticClass:"updates-status-container"},[_c('div',{staticClass:"evaluator-section",class:_vm.isPreviewRubric &&
            (_vm.progressStatus !== 'NEED MORE INFO' || _vm.hideStatus)
              ? 'pointer-events'
              : ''},[_c('p',[_vm._v("Evaluator's Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.evaluatorName),expression:"evaluatorName"}],staticClass:"evaluator-name-input",attrs:{"type":"text","disabled":_vm.disableEvent},domProps:{"value":(_vm.evaluatorName)},on:{"input":function($event){if($event.target.composing)return;_vm.evaluatorName=$event.target.value}}})]),_c('div',{staticClass:"evaluator-section"},[_c('p',[_vm._v("Date")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.evaluatorDate),expression:"evaluatorDate"}],staticClass:"evaluator-date-input",attrs:{"type":"text","disabled":true},domProps:{"value":(_vm.evaluatorDate)},on:{"input":function($event){if($event.target.composing)return;_vm.evaluatorDate=$event.target.value}}})]),_c('div',{staticClass:"comment-section"},[((_vm.hideStatus && _vm.comment) || !_vm.hideStatus)?_c('div',{class:_vm.isPreviewRubric &&
              (_vm.progressStatus !== 'NEED MORE INFO' || _vm.hideStatus)
                ? 'pointer-events'
                : 'comment'},[_c('label',[_vm._v("Comments")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.comment),expression:"comment"}],staticClass:"comment-textarea",attrs:{"disabled":_vm.disableEvent},domProps:{"value":(_vm.comment)},on:{"input":function($event){if($event.target.composing)return;_vm.comment=$event.target.value}}})]):_vm._e(),(!_vm.files.length && !_vm.isPreviewRubric)?_c('div',{staticClass:"file-container"},[(!_vm.files.length)?_c('label',{staticClass:"btn browse",attrs:{"for":"files"}},[_vm._v("Upload Document"),_c('b-icon',{staticClass:"upload-icon",attrs:{"icon":"upload"}})],1):_vm._e(),_c('input',{staticStyle:{"visibility":"hidden","width":"0px"},attrs:{"id":"files","type":"file","multiple":""},on:{"change":function($event){return _vm.handleFileSelect($event)}}})]):_vm._e(),(_vm.files.length)?_c('div',{staticClass:"fileSelect-div"},_vm._l((_vm.files),function(file,index){return _c('div',{key:index,staticClass:"file-container"},[_c('b-icon',{attrs:{"icon":_vm.fileType === 'application'
                    ? 'file-earmark-text'
                    : 'file-image'}}),_c('p',{staticClass:"file-name"},[_vm._v(" "+_vm._s(file.originalFileName || file.name)+" ")]),_c('div',{staticClass:"actions"},[(!file.fileName)?_c('b-icon',{staticClass:"action-icon",attrs:{"icon":"eye"},on:{"click":function($event){return _vm.previewFile(index)}}}):_vm._e(),(file.fileName)?_c('b-icon',{staticClass:"action-icon",attrs:{"icon":"eye"},on:{"click":function($event){return _vm.toggleModal(file)}}}):_vm._e(),(!_vm.isPreviewRubric)?_c('b-icon',{staticClass:"close-icon",attrs:{"icon":"trash"},on:{"click":function($event){return _vm.deleteFile(index)}}}):_vm._e()],1)],1)}),0):_vm._e(),(_vm.files.length && !_vm.isPreviewRubric)?_c('div',{staticClass:"additional-image-upload"},[_c('label',{staticClass:"file-name",attrs:{"for":"files"}},[_c('b-icon',{staticClass:"add-icon",attrs:{"icon":"plus-lg"}}),_vm._v("Add more attachments")],1),_c('input',{staticClass:"file-input",attrs:{"id":"files","type":"file"},on:{"change":function($event){return _vm.handleFileSelect($event)}}})]):_vm._e()]),(!_vm.hideStatus)?_c('div',{staticClass:"updates-status"},[_c('p',{staticClass:"update-label"},[_vm._v("Application Status")]),_c('div',{staticClass:"status-dropdown-div",class:[
              'btn',
              _vm.statusName === 'processing'
                ? 'processing'
                : _vm.statusName === 'accepted'
                ? 'accepted'
                : _vm.statusName === 'not accepted'
                ? 'rejected'
                : _vm.statusName === 'need more info'
                ? 'more-info'
                : _vm.statusName === 'waiting for training'
                ? 'waiting-for-training'
                : _vm.statusName === 'completed training'
                ? 'completed-training'
                : 'status',
              _vm.applicantPreviewForm.progressStatus === 'NOT ACCEPTED'
                ? 'not-accepted'
                : '',
            ]},[_c('div',{staticClass:"status-dropdown-default",class:_vm.isPreviewRubric ? 'justify-center' : '',on:{"click":function($event){return _vm.checkApplicationSubmissionStatus()}}},[_c('p',[_vm._v(" "+_vm._s(_vm.statusName ? _vm.statusName : "Update Status")+" ")]),(
                  !_vm.isPreviewRubric ||
                  (_vm.isPreviewRubric && _vm.progressStatus === 'NEED MORE INFO')
                )?_c('b-icon',{staticClass:"arrow-icon",attrs:{"icon":"caret-down-fill"}}):_vm._e()],1),(_vm.toogleStatus)?_c('ul',{staticClass:"dropdown-list"},[_c('li',{staticClass:"more-info",on:{"click":function($event){return _vm.statusUpdate('need more info')}}},[_vm._v(" More Info ")]),(!_vm.isProcessingAvailable)?_c('li',{class:[
                  'processing',
                  !_vm.allApplicationSubmissionStatus ? 'disabled' : '',
                ],on:{"click":function($event){return _vm.statusUpdate('processing')}}},[_vm._v(" Processing ")]):_vm._e(),(_vm.isProcessingAvailable)?_c('li',{class:[
                  'accepted',
                  !_vm.allApplicationSubmissionStatus ? 'disabled' : '',
                ],on:{"click":function($event){return _vm.statusUpdate('accepted')}}},[_vm._v(" Accepted ")]):_vm._e(),_c('li',{staticClass:"rejected",on:{"click":function($event){return _vm.statusUpdate('not accepted')}}},[_vm._v(" Not Accepted ")]),(
                  (_vm.currentRoute.split('/')[2] === 'training' ||
                    (_vm.currentRoute.split('/')[2] === 'peers-preview' &&
                      _vm.applicationName === 'PART ONE')) &&
                  (_vm.progressStatus === 'WAITING FOR TRAINING' ||
                    _vm.progressStatus === 'COMPLETED TRAINING' ||
                    _vm.progressStatus === 'NEED MORE INFO' ||
                    _vm.progressStatus === 'RECEIVED' ||
                    _vm.progressStatus === 'PROCESSING')
                )?_c('li',{staticClass:"waiting-for-training",on:{"click":function($event){return _vm.statusUpdate('waiting for training')}}},[_vm._v(" Waiting for Training ")]):_vm._e(),(
                  (_vm.currentRoute.split('/')[2] === 'training' ||
                    (_vm.currentRoute.split('/')[2] === 'peers-preview' &&
                      _vm.applicationName === 'PART ONE')) &&
                  (_vm.progressStatus === 'WAITING FOR TRAINING' ||
                    _vm.progressStatus === 'COMPLETED TRAINING' ||
                    _vm.progressStatus === 'NEED MORE INFO' ||
                    _vm.progressStatus === 'RECEIVED' ||
                    _vm.progressStatus === 'PROCESSING')
                )?_c('li',{staticClass:"completed-training",on:{"click":function($event){return _vm.statusUpdate('completed training')}}},[_vm._v(" Completed Training ")]):_vm._e()]):_vm._e(),(_vm.isStatusEmpty)?_c('p',{staticClass:"invalid",staticStyle:{"position":"absolute"}},[_vm._v(" Please update status ")]):_vm._e()])]):_vm._e(),(
            !_vm.isPreviewRubric ||
            (_vm.isPreviewRubric &&
              (_vm.progressStatus !== 'ACCEPTED' ||
                _vm.progressStatus !== 'NOT ACCEPTED') &&
              _vm.progressStatus.toLocaleLowerCase() !== _vm.statusName)
          )?_c('button',{staticClass:"update-status-btn"},[_vm._v(" Update Application "),_c('img',{staticClass:"update-icon",attrs:{"src":require("../../assets/icons/update-icon.svg")}})]):_vm._e(),(!_vm.isValid)?_c('p',{staticClass:"invalid"},[_vm._v("All fields are required *")]):_vm._e()]),(
          _vm.applicantPreviewForm &&
          _vm.applicantPreviewForm.submittedBy &&
          _vm.applicantPreviewForm.progressStatus !== 'ACCEPTED'
        )?_c('div',{staticClass:"update-history-container"},[_c('p',{staticClass:"history-label"},[_vm._v("Application Update History")]),(_vm.applicantPreviewForm && _vm.applicantPreviewForm.submittedBy)?_c('div',{staticClass:"history"},[_c('span',[_vm._v(_vm._s(_vm.applicantPreviewForm.submittedAt))]),_c('p',[_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.applicantPreviewForm.submittedByFirstName)+" "+_vm._s(_vm.applicantPreviewForm.submittedByLastName))]),_vm._v(" has submitted the application. ")])]):_vm._e()]):_vm._e()],2)]),(_vm.previewFileData && _vm.showPreviewPopup)?_c('PreviewPopupComponent',{attrs:{"fileDetails":_vm.previewFileData,"keyName":'scoring'},on:{"hidePreviewPopup":function($event){return _vm.hidePreviewPopup($event)}}}):_vm._e(),(_vm.errorMessage)?_c('ErrorPopupComponent',{attrs:{"componentName":'scoring-error-popup',"errorMessage":_vm.errorMessage},on:{"closdedErrorPopup":function($event){return _vm.closdedErrorPopup($event)}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }