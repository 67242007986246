export const CYAPSS_APPLICATIONS_QUERY = `CyapssApplication {  
    cyapssApplications(sort: "id:ASC"){
      data {
        id
        attributes {
            title
            description
            url
            slug
            header
            docKeyName
        }
      }
    }
  }`;
