import { User, navList } from "@/models/common-interface/common.interface";
import { loginApi } from "@/providers/apis/login";
import { utils } from "@/utils/utils";
import Vue from "vue";
import Component from "vue-class-component";
import { appConfigService } from "@/providers/services/app/app-config";

@Component({})
export default class UserDasboardComponent extends Vue {
  public currentRoute = "";
  public navListsData: navList = [];
  public isCollapse = false;
  public isMenuEnabled = false;
  public isScroll = false;
  public userDetails: User = {} as User;
  public toogleStatus = false;
  public userDashboard = false;
  public profileDetail: any;
  public S3Path = "";
  public isAdmin: any;

  public mounted() {
    this.userDetails = utils.getUserDetails();
    this.isAdmin = localStorage.getItem("impersonateId");
    if (this.userDetails.roles === null || !this.userDetails.roles) {
      this.userDashboard = true;
    } else {
      this.userDashboard = false;
    }
    if (utils.checkIsMobileView() || utils.checkIsTabView()) {
      this.isMenuEnabled = true;
    } else {
      this.isMenuEnabled = false;
    }
    this.profileDetail = utils.getProfileDetails();
    this.S3Path = appConfigService.getS3FilesPathUrl();
    this.currentRoute = this.$route.path.split("/")[1];
    this.navListsData =
      this.currentRoute === "user-dashboard"
        ? [
            {
              name: "Home",
              icon_class: "user_home",
              url: "/user-dashboard/home",
              visible: true,
            },
            {
              name: "My Applications",
              icon_class: "my-application",
              url: "/user-dashboard/my-application",
              visible: true,
            },
            {
              name: "Continuing Education",
              icon_class: "education",
              url: "/user-dashboard/education",
              visible: true,
            },
            {
              name: "Documents Library",
              icon_class: "document",
              url: "/user-dashboard/document-library",
              visible: true,
            },
            {
              name: "Conversations",
              icon_class: "conversations",
              url: "/user-dashboard/conversations",
              visible: true,
            },
          ]
        : [
            {
              name: "Home",
              icon_class: "home",
              url: "/reviewer-dashboard/home",
              visible: true,
            },
            {
              name: "Training",
              icon_class: "training",
              url: "/reviewer-dashboard/training",
              visible: true,
            },
            {
              name: "Certification",
              icon_class: "certification",
              url: "/reviewer-dashboard/certification",
              visible: true,
            },
            {
              name: "Renewal",
              icon_class: "renewal",
              url: "/reviewer-dashboard/renewal",
              visible: true,
            },
            {
              name: "Peers",
              icon_class: "peers",
              url: "/reviewer-dashboard/peers",
              visible: true,
            },
            {
              name: "Reports",
              icon_class: "reports",
              url: "/reviewer-dashboard/reports",
              visible: true,
            },
          ];
  }

  public updated() {
    window.document.onclick = (event) => {
      if (this.toogleStatus) {
        const getFilterElement = document.getElementsByClassName(
          "logo-and-details-container"
        )[0];
        if (
          getFilterElement &&
          !getFilterElement.contains(event.target as Node)
        ) {
          this.toogleStatus = !this.toogleStatus;
        }
      }
    };
    window.document.onscroll = () => {
      const navbar = document.getElementById("main-container");
      if (navbar) {
        if (window.scrollY > 55) {
          this.isScroll = true;
        } else if (window.scrollY < 55) {
          this.isScroll = false;
        }
      }
    };
    if (
      this.$route.path.split("/").length > 3 ||
      this.$route.path === "/reviewer-dashboard/peers-preview"
    ) {
      this.isCollapse = true;
    } else {
      this.isCollapse = false;
    }
  }

  public logout() {
    loginApi
      .logOut()
      .then(() => {
        utils.deleteLocalStorage();
        this.$router.push("/home").catch(() => {
          //todo
        });
      })
      .catch((error) => {
        return error;
      });
  }

  public changePassword() {
    this.$router.push({
      path: "/login",
      query: {
        loginType: "changePassword",
      },
    });
  }
  public backToAdmin() {
    const accessToken: any = localStorage.getItem("admin_access_token");
    const userDetail: any = localStorage.getItem("admin_user_details");
    localStorage.setItem("access_token", accessToken);
    localStorage.setItem("user_details", userDetail);
    localStorage.setItem("profileData", userDetail);
    this.$router.push({
      path: `/reviewer-dashboard/peers-preview`,
      query: {
        id: localStorage.getItem("impersonateId"),
      },
    });
    localStorage.removeItem("impersonateId");
    localStorage.removeItem("partTwoCertificationPayload");
    localStorage.removeItem("renewalPayLoadData");
    window.location.reload();
  }
}
