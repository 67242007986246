export const TRAINING_APPLICATION_FORM_QUERY = `TrainingApplicationForms {  
    trainingApplicationForms(sort: "id:ASC"){
        data{
            id
            attributes{
                main_title
                application_name
                navbar{
                    id
                    name
                    slug    
                },
                form_fields{
                    id
                    parent_slug
                    fields
                }
            }
        }
    }
}`;
