var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"user-conversations user-conversations-main-container"},[_c('div',{staticClass:"conversations-heading"},[_c('b-icon',{staticClass:"chat-icon",attrs:{"icon":"chat"}}),_c('h1',{staticClass:"main-heading"},[_vm._v("Messages")])],1),(_vm.conversionData.length && !_vm.isLoading)?_c('div',{staticClass:"conversations-main"},_vm._l((_vm.conversionData),function(conversion,index){return _c('div',{key:index,staticClass:"conversations-container"},[_c('div',{directives:[{name:"b-toggle",rawName:"v-b-toggle.conversation-sidebar",modifiers:{"conversation-sidebar":true}}],staticClass:"conversations",on:{"click":function($event){return _vm.getCurrentConversations(conversion)}}},[(conversion.profileImg)?_c('div',{staticClass:"profile-img",style:({
            'background-image': `url(${conversion.profileImg})`,
          })}):_c('img',{staticClass:"user-profile",attrs:{"src":require("../../assets/images/cyapss-logo.png"),"alt":"user-profile"}}),_c('div',{staticClass:"details"},[_c('label',{staticClass:"name-and-date"},[_c('p',{domProps:{"innerHTML":_vm._s(
                conversion.sendorFirstName + ' ' + conversion.sendorLastName
              )}}),_c('p',{staticClass:"date",domProps:{"innerHTML":_vm._s(conversion.displayDate)}})]),_c('p',{staticClass:"collapsed-message"},[_vm._v(_vm._s(conversion.collapsedMessage))])])])])}),0):_vm._e(),(!_vm.conversionData.length && !_vm.isLoading)?[_vm._m(0)]:_vm._e(),(_vm.currentConversation)?_c('b-sidebar',{attrs:{"id":"conversation-sidebar","right":"","backdrop":"","no-header":""},scopedSlots:_vm._u([{key:"default",fn:function({ hide }){return [_c('div',{staticClass:"sidebar-container"},[_c('div',{staticClass:"back-btn",on:{"click":hide}},[_c('b-icon',{attrs:{"icon":"arrow-left"}}),_c('p',{staticClass:"back-label"},[_vm._v("Back")])],1),_c('div',{directives:[{name:"b-toggle",rawName:"v-b-toggle.conversation-sidebar",modifiers:{"conversation-sidebar":true}}],staticClass:"conversations"},[(_vm.currentConversation.profileImg)?_c('div',{staticClass:"profile-img",style:({
              'background-image': `url(${_vm.currentConversation.profileImg})`,
            })}):_c('img',{staticClass:"user-profile",attrs:{"src":require("../../assets/images/cyapss-logo.png"),"alt":"user-profile"}}),_c('div',{staticClass:"details"},[_c('label',{staticClass:"name-and-email"},[_c('p',[_vm._v(" "+_vm._s(_vm.currentConversation.sendorFirstName + " " + _vm.currentConversation.sendorLastName)+" ")]),_c('span',[_vm._v(" "+_vm._s("<" + _vm.currentConversation.sendorEmail + ">")+" ")])]),_c('p',{staticClass:"to-email"},[_vm._v(" "+_vm._s(_vm.userDetails.email === _vm.currentConversation.email ? "to: me" + "<" + _vm.currentConversation.email + ">" : "to: " + _vm.currentConversation.email)+" ")])])]),_c('div',{staticClass:"message-container"},[_c('p',{staticClass:"message",domProps:{"innerHTML":_vm._s(_vm.currentConversation.message)}})]),_c('div',{staticClass:"thumbnail-image-container"},_vm._l((_vm.currentConversation.attachments),function(thumbnailImage,thumbnailIndex){return _c('div',{key:thumbnailIndex,staticClass:"thumbnail-image",on:{"mouseenter":function($event){return _vm.attachmentMouseOver(thumbnailIndex)},"mouseleave":function($event){return _vm.attachmentMouseLeave()}}},[(thumbnailIndex === _vm.attachmentMouseOverIdx)?_c('div',{staticClass:"overlay"},[_c('p',{staticClass:"attachment-name"},[(_vm.imgType.includes(thumbnailImage.type))?_c('b-icon',{attrs:{"icon":"file-earmark-image"}}):_vm._e(),(_vm.docType.includes(thumbnailImage.type))?_c('b-icon',{attrs:{"icon":"file-earmark-pdf-fill"}}):_vm._e(),_c('span',{domProps:{"innerHTML":_vm._s(thumbnailImage.originalFileName)}})],1),_c('div',{staticClass:"action-buttons"},[_c('b-icon',{staticClass:"download-icon",attrs:{"id":"button-1","icon":"download"},on:{"click":function($event){return _vm.downloadFile(
                      _vm.S3Path + '/' + thumbnailImage.uploadedFileName,
                      thumbnailImage.originalFileName
                    )}}}),_c('b-icon',{staticClass:"preview-icon",attrs:{"id":"button-2","icon":"eye"},on:{"click":function($event){return _vm.emailAttachmentPreview(thumbnailIndex)}}})],1),_c('b-tooltip',{attrs:{"target":"button-1","placement":"bottom"}},[_vm._v(" Download ")]),_c('b-tooltip',{attrs:{"target":"button-2","placement":"bottom"}},[_vm._v(" Preview ")])],1):_vm._e(),(_vm.imgType.includes(thumbnailImage.type))?_c('img',{staticClass:"image",attrs:{"src":_vm.S3Path + '/' + thumbnailImage.uploadedFileName}}):_c('div',{staticClass:"other"},[(_vm.docType.includes(thumbnailImage.type))?_c('img',{staticClass:"pdf-image",attrs:{"src":require("../../assets/icons/pdf-icon.png")}}):_vm._e()])])}),0)])]}}],null,false,2646888563)}):_vm._e(),(_vm.isLoading)?_c('AppLoaderComponent',{attrs:{"type":'large'}}):_vm._e(),(_vm.previewFileData && _vm.showPreviewPopup)?_c('PreviewPopupComponent',{attrs:{"fileDetails":_vm.previewFileData,"keyName":'email'},on:{"hidePreviewPopup":function($event){return _vm.hidePreviewPopup($event)}}}):_vm._e(),(_vm.errorMessage)?_c('ErrorPopupComponent',{attrs:{"componentName":'user-conversation-error-popup',"errorMessage":_vm.errorMessage},on:{"closdedErrorPopup":function($event){return _vm.closdedErrorPopup($event)}}}):_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"user-messages-container"},[_c('p',[_vm._v("No Messages")])])
}]

export { render, staticRenderFns }