import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "b-form-tag-input",
})
export default class InputTagComponent extends Vue {
  @Prop()
  public value!: string[];
  @Prop()
  public isPreviewForm!: boolean;

  public text: string[] = [];

  public created() {
    this.text = this.value.length ? this.value : [];
  }

  onChangeInput() {
    this.$emit("input", this.text);
  }
}
