var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"login-main-container"},[_c('div',{staticClass:"login-container"},[_c('div',{staticClass:"background-overlay"}),_c('div',{staticClass:"form-container"},[_c('div',{staticClass:"form-div"},[_c('form',{key:_vm.loginType,staticClass:"login-form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_vm._m(0),(_vm.isEmailNotVerified)?_c('div',{staticClass:"resend"},[_vm._m(1),_c('div',{staticClass:"form-group"},[_c('label',{class:_vm.activeField === 'email' || _vm.loginData.username_email
                    ? 'activeEmail'
                    : '',attrs:{"for":"email"}},[_vm._v("Email ID")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.loginData.username_email),expression:"loginData.username_email"},{name:"validate",rawName:"v-validate",value:('required|email'),expression:"'required|email'"}],class:{
                  'is-danger': _vm.checkValidation
                    ? _vm.$validator.errors.has('email')
                    : '',
                },attrs:{"type":"text","name":"email","autocomplete":"off"},domProps:{"value":(_vm.loginData.username_email)},on:{"focusin":function($event){return _vm.activeInput('email')},"focusout":function($event){return _vm.valueCheck('email')},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.loginData, "username_email", $event.target.value)}}}),(_vm.checkValidation)?_c('span',{staticClass:"is-invalid"},[_vm._v(_vm._s(_vm.$validator.errors.first("email")))]):_vm._e()]),_c('div',{staticClass:"submit-btn"},[_c('button',{staticClass:"btn",class:_vm.isLoading ? 'disable-btn' : '',attrs:{"type":"submit"}},[_c('span',[_vm._v("Resend")]),(!_vm.isLoading)?_c('b-icon',{attrs:{"icon":"arrow-right"}}):_vm._e(),(_vm.isLoading)?_c('AppLoaderComponent',{attrs:{"type":'small'}}):_vm._e()],1)])]):_vm._e(),(!_vm.isEmailNotVerified && _vm.isResend)?_c('div',{staticClass:"resend-container"},[_vm._m(2),_c('div',{staticClass:"back-btn"},[_c('button',{staticClass:"btn",on:{"click":function($event){return _vm.goBack()}}},[_c('span',[_vm._v("Goback to Login")])])])]):_vm._e(),(
              _vm.loginType !== 'changePassword' &&
              !_vm.isEmailNotVerified &&
              !_vm.isResend
            )?_c('div',{staticClass:"login"},[_c('div',{staticClass:"content"},[_c('h3',{staticClass:"login-title"},[_vm._v("Log In")]),_c('p',{staticClass:"greeting"},[_vm._v("Welcome back! Please enter your details")]),(_vm.isInvalidUser)?_c('p',{staticClass:"is-invalid"},[_vm._v(" Email or password is incorrect ")]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('label',{class:_vm.activeField === 'email' || _vm.loginData.username_email
                    ? 'activeEmail'
                    : '',attrs:{"for":"email"}},[_vm._v("Email ID")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.loginData.username_email),expression:"loginData.username_email"},{name:"validate",rawName:"v-validate",value:('required|email'),expression:"'required|email'"}],class:{
                  'is-danger': _vm.checkValidation
                    ? _vm.$validator.errors.has('email')
                    : '',
                },attrs:{"type":"text","name":"email","autocomplete":"off"},domProps:{"value":(_vm.loginData.username_email)},on:{"focusin":function($event){return _vm.activeInput('email')},"focusout":function($event){return _vm.valueCheck('email')},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.loginData, "username_email", $event.target.value)}}}),(_vm.checkValidation)?_c('span',{staticClass:"is-invalid"},[_vm._v(_vm._s(_vm.$validator.errors.first("email")))]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"password-label",class:_vm.activeField === 'password' || _vm.loginData.password
                    ? 'activePassword'
                    : '',attrs:{"for":"password"}},[_vm._v("Password")]),_c('div',{staticClass:"password-container password-login"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.loginData.password),expression:"loginData.password"},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"password-input",class:{
                    'is-danger': _vm.checkValidation
                      ? _vm.$validator.errors.has('password')
                      : '',
                  },attrs:{"type":"password","name":"password"},domProps:{"value":(_vm.loginData.password)},on:{"focusin":function($event){return _vm.activeInput('password')},"focusout":function($event){return _vm.valueCheck('password')},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.loginData, "password", $event.target.value)}}}),_c('div',{staticClass:"eye-icon-container",class:[
                    _vm.activeField === 'password' || _vm.loginData.password
                      ? 'activePassword'
                      : '',
                    {
                      'is-danger': _vm.checkValidation
                        ? _vm.$validator.errors.has('password')
                        : '',
                    },
                  ],on:{"click":function($event){return _vm.showAndHide('login')}}},[(!_vm.showandHidePassword.loginPassword)?_c('b-icon',{staticClass:"eye-icon",attrs:{"icon":"eye-slash"}}):_vm._e(),(_vm.showandHidePassword.loginPassword)?_c('b-icon',{staticClass:"eye-icon",attrs:{"icon":"eye"}}):_vm._e()],1)]),(_vm.checkValidation)?_c('span',{staticClass:"is-invalid"},[_vm._v(_vm._s(_vm.$validator.errors.first("password")))]):_vm._e()]),_c('div',{staticClass:"submit-btn"},[_c('button',{staticClass:"btn",class:_vm.isLoading ? 'disable-btn' : '',attrs:{"type":"submit"}},[_c('span',[_vm._v("Sign In")]),(!_vm.isLoading)?_c('b-icon',{attrs:{"icon":"arrow-right"}}):_vm._e(),(_vm.isLoading)?_c('AppLoaderComponent',{attrs:{"type":'small'}}):_vm._e()],1)]),_c('div',{staticClass:"forgot-password-button-container"},[_c('button',{staticClass:"btn",attrs:{"type":"button"},on:{"click":function($event){return _vm.forgetpasswordcheck()}}},[_vm._v(" Forgot Password? ")])])]):_vm._e(),(_vm.loginType === 'changePassword')?_c('div',{staticClass:"forget-password"},[_c('div',{staticClass:"form-group"},[_c('label',{class:_vm.activeField === 'old-password' || _vm.forgetPassword.oldPassword
                    ? 'activePassword'
                    : '',attrs:{"for":"password"}},[_vm._v("Old Password")]),_c('div',{staticClass:"password-container password-old"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.forgetPassword.oldPassword),expression:"forgetPassword.oldPassword"},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"password-input",class:{
                    'is-danger': _vm.checkValidation
                      ? _vm.$validator.errors.has('old password')
                      : '',
                  },attrs:{"type":"password","name":"old password"},domProps:{"value":(_vm.forgetPassword.oldPassword)},on:{"focusin":function($event){return _vm.activeInput('old-password')},"focusout":function($event){return _vm.valueCheck('old-password')},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.forgetPassword, "oldPassword", $event.target.value)}}}),_c('div',{staticClass:"eye-icon-container",class:[
                    _vm.activeField === 'old-password' ||
                    _vm.forgetPassword.oldPassword
                      ? 'activePassword'
                      : '',
                    {
                      'is-danger': _vm.checkValidation
                        ? _vm.$validator.errors.has('old password')
                        : '',
                    },
                  ],on:{"click":function($event){return _vm.showAndHide('old')}}},[(!_vm.showandHidePassword.oldPassword)?_c('b-icon',{staticClass:"eye-icon",attrs:{"icon":"eye-slash"}}):_vm._e(),(_vm.showandHidePassword.oldPassword)?_c('b-icon',{staticClass:"eye-icon",attrs:{"icon":"eye"}}):_vm._e()],1)]),(_vm.checkValidation)?_c('span',{staticClass:"is-invalid"},[_vm._v(_vm._s(_vm.$validator.errors.first("old password")))]):_vm._e(),(_vm.oldPassIncorrect)?_c('span',{staticClass:"is-invalid"},[_vm._v("Old password is incorrect ")]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('label',{class:_vm.activeField === 'new-password' || _vm.forgetPassword.newPassword
                    ? 'activePassword'
                    : '',attrs:{"for":"password"}},[_vm._v("New Password")]),_c('div',{staticClass:"password-container password-new"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.forgetPassword.newPassword),expression:"forgetPassword.newPassword"},{name:"validate",rawName:"v-validate",value:('required|min:6'),expression:"'required|min:6'"}],staticClass:"password-input",class:{
                    'is-danger': _vm.checkValidation
                      ? _vm.$validator.errors.has('new password')
                      : '',
                  },attrs:{"type":"password","name":"new password"},domProps:{"value":(_vm.forgetPassword.newPassword)},on:{"focusin":function($event){return _vm.activeInput('new-password')},"focusout":function($event){return _vm.valueCheck('new-password')},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.forgetPassword, "newPassword", $event.target.value)}}}),_c('div',{staticClass:"eye-icon-container",class:[
                    _vm.activeField === 'new-password' ||
                    _vm.forgetPassword.newPassword
                      ? 'activePassword'
                      : '',
                    {
                      'is-danger': _vm.checkValidation
                        ? _vm.$validator.errors.has('new password')
                        : '',
                    },
                  ],on:{"click":function($event){return _vm.showAndHide('new')}}},[(!_vm.showandHidePassword.newPassword)?_c('b-icon',{staticClass:"eye-icon",attrs:{"icon":"eye-slash"}}):_vm._e(),(_vm.showandHidePassword.newPassword)?_c('b-icon',{staticClass:"eye-icon",attrs:{"icon":"eye"}}):_vm._e()],1)]),(_vm.checkValidation)?_c('span',{staticClass:"is-invalid"},[_vm._v(_vm._s(_vm.$validator.errors.first("new password")))]):_vm._e(),(_vm.isDuplicatePass)?_c('span',{staticClass:"is-invalid"},[_vm._v("New password must be different from old password ")]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('label',{class:_vm.activeField === 'confirm-password' || _vm.confirmPassword
                    ? 'activePassword'
                    : '',attrs:{"for":"password"}},[_vm._v("Confirm Password")]),_c('div',{staticClass:"password-container password-confirm"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirmPassword),expression:"confirmPassword"},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"password-input",class:{
                    'is-danger': _vm.checkValidation
                      ? _vm.$validator.errors.has('confirm password')
                      : '',
                  },attrs:{"type":"password","name":"confirm password"},domProps:{"value":(_vm.confirmPassword)},on:{"focusin":function($event){return _vm.activeInput('confirm-password')},"focusout":function($event){return _vm.valueCheck('confirm-password')},"input":function($event){if($event.target.composing)return;_vm.confirmPassword=$event.target.value}}}),_c('div',{staticClass:"eye-icon-container",class:[
                    _vm.activeField === 'confirm-password' || _vm.confirmPassword
                      ? 'activePassword'
                      : '',
                    {
                      'is-danger': _vm.checkValidation
                        ? _vm.$validator.errors.has('confirm password')
                        : '',
                    },
                  ],on:{"click":function($event){return _vm.showAndHide('confirm')}}},[(!_vm.showandHidePassword.confirmPass)?_c('b-icon',{staticClass:"eye-icon",attrs:{"icon":"eye-slash"}}):_vm._e(),(_vm.showandHidePassword.confirmPass)?_c('b-icon',{staticClass:"eye-icon",attrs:{"icon":"eye"}}):_vm._e()],1)]),(_vm.checkValidation)?_c('span',{staticClass:"is-invalid"},[_vm._v(_vm._s(_vm.$validator.errors.first("confirm password")))]):_vm._e(),(!_vm.isconfirmPassValid)?_c('span',{staticClass:"is-invalid"},[_vm._v("Confirm password must be same as new password ")]):_vm._e()]),_c('div',{staticClass:"submit-btn"},[_c('button',{staticClass:"btn",class:_vm.isLoading ? 'disable-btn' : '',attrs:{"type":"submit"}},[_c('span',[_vm._v("Update")]),(!_vm.isLoading)?_c('b-icon',{attrs:{"icon":"arrow-right"}}):_vm._e(),(_vm.isLoading)?_c('AppLoaderComponent',{attrs:{"type":'small'}}):_vm._e()],1)])]):_vm._e()])]),_vm._m(3)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"program-content"},[_c('a',{staticClass:"image-route",attrs:{"href":"/home"}},[_c('img',{attrs:{"src":require("../../assets/images/cyapss-logo.png")}})]),_c('p',{staticClass:"program-title"},[_vm._v(" Certified Young Adult Peer Support Specialist ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"content"},[_c('h3',{staticClass:"login-title"},[_vm._v("Resend")]),_c('p',{staticClass:"greeting"},[_vm._v(" Your account has not been verified yet. Please check your Inbox for the verification email.Enter your email address below, if you want us to resend the verification email ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('p',[_vm._v(" Please check your inbox and verify your email address to continue ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"login-image-container"},[_c('img',{staticClass:"login-img",attrs:{"src":require("../../assets/images/login-page.png")}}),_c('div',{staticClass:"overlay"})])
}]

export { render, staticRenderFns }