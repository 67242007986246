import { Component, Vue } from "vue-property-decorator";
import { reportsApi } from "@/providers/apis/reports";
import VueApexCharts from "vue-apexcharts";
import moment from "moment";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import { dateRange } from "@/models/common-interface/common.interface";
import DateRangePicker from "vue2-daterange-picker";

@Component({
  name: "reviewer-reports-page",
  components: { apexchart: VueApexCharts, AppLoaderComponent, DateRangePicker },
})
export default class ReviewerReportsPage extends Vue {
  public toogleStatus = false;
  public selected: any = [];
  public options = [
    { text: "Received", value: "RECEIVED" },
    { text: "Need More Info", value: "NEED MORE INFO" },
    { text: "Processing", value: "PROCESSING" },
    { text: "Accepted", value: "ACCEPTED" },
    { text: "Not Accepted", value: "NOT ACCEPTED" },
    { text: "Completed Training", value: "COMPLETED TRAINING" },
    { text: "Waiting For Training", value: "WAITING FOR TRAINING" },
  ];
  public reportsData: any = [];
  public colors = [
    "#00A3AF",
    "#ED8E36",
    "#4B9740",
    "#F8BA41",
    "#0072BC",
    "#1AA9EB",
    "#00B478",
    "#F46360",
  ];
  public chartOptions: any;
  public trainingCitiesChart: any;
  public ageChart: any;
  public trainingCitiesSeries: any;
  public trainingCitiesCategories: any;
  public educationsSeries: any;
  public educationsCategories: any;
  public educationsChart: any;
  public genderChart: any;
  public isLoading = false;
  public fromDate: any;
  public toDate: any;
  public applicationData: any;
  public ethnicDetail: any;
  public genderDetail: any;
  public educationOptions: any;
  public regions: any;
  public filterDate: any;
  public formData: any;
  public raceAndEthnicityChartOption: any;
  public ethnicitySeries: any;
  public selectedFilter = "This Year";
  public certifiedReporDateRange: dateRange = {} as dateRange;
  public isCustomPicker = false;
  public localeDate = {
    format: "yyyy-m-dd",
    applyLabel: "Apply",
    firstDay: 0,
  };
  public isReport: any;
  public reportsfromDate: any;
  public reportstoDate: any;
  public dateRange: dateRange = {} as dateRange;
  public created() {
    this.isReport = this.$route.query.isReport;
    if (this.isReport) {
      this.reportsfromDate = this.$route.query.fromDate;
      this.reportstoDate = this.$route.query.toDate;
      this.filterDate =
        moment(this.reportsfromDate).format("MM-DD-YYYY") +
        "  to  " +
        moment(this.reportstoDate).format("MM-DD-YYYY");
      this.getReports(this.reportsfromDate, this.reportstoDate);
    } else {
      const fromDate = moment().startOf("year").format("YYYY-MM-DD");
      const toDate = moment().format("YYYY-MM-DD");
      this.filterDate =
        moment(fromDate).format("MM-DD-YYYY") +
        "  to  " +
        moment(toDate).format("MM-DD-YYYY");
      this.getReports(fromDate, toDate);
    }
  }
  public filterChange(type: any) {
    let fromDate;
    let toDate;
    this.selectedFilter = type;
    if (type === "today") {
      fromDate = moment().format("YYYY-MM-DD");
      toDate = moment().format("YYYY-MM-DD");
    }
    if (type === "yesterday") {
      fromDate = moment().subtract(1, "day").format("YYYY-MM-DD");
      toDate = moment().subtract(1, "day").format("YYYY-MM-DD");
    }
    if (type === "last 7 days") {
      fromDate = moment().subtract(7, "d").format("YYYY-MM-DD");
      toDate = moment().format("YYYY-MM-DD");
    }
    if (type === "last 30 days") {
      fromDate = moment().subtract(30, "d").format("YYYY-MM-DD");
      toDate = moment().format("YYYY-MM-DD");
    }
    if (type === "this month") {
      fromDate = moment().startOf("month").format("YYYY-MM-DD");
      toDate = moment().format("YYYY-MM-DD");
    }
    if (type === "last month") {
      fromDate = moment()
        .subtract(1, "months")
        .startOf("month")
        .format("YYYY-MM-DD");
      toDate = moment()
        .subtract(1, "months")
        .endOf("month")
        .format("YYYY-MM-DD");
    }
    if (type === "this year") {
      fromDate = moment().startOf("year").format("YYYY-MM-DD");
      toDate = moment().format("YYYY-MM-DD");
    }
    if (type === "last year") {
      const lastYear = moment().year() - 1;
      fromDate = lastYear + "-01-01";
      toDate = lastYear + "-12-31";
    }
    this.toogleStatus = false;
    this.getReports(fromDate, toDate);
  }
  public getReports(fromDate: any, toDate: any) {
    this.fromDate = fromDate;
    this.toDate = toDate;
    this.isLoading = true;
    reportsApi.getReportlist(fromDate, toDate).then((res) => {
      this.isLoading = false;
      this.isCustomPicker = false;
      let trainingCities: any;
      if (res && res.data && res.data.trainingCities) {
        trainingCities = res.data.trainingCities;
        trainingCities.map((data: any, index: any) => {
          data.color = this.colors[index];
        });
        res.data.trainingCities = trainingCities;
      }
      if (res.data.trainingCities) {
        this.trainingCitiesSeries = res.data.trainingCities.map((data: any) => {
          return data.count;
        });
        this.trainingCitiesCategories = res.data.trainingCities.map(
          (data: any) => {
            return data.name;
          }
        );
      }
      if (res.data.educations) {
        this.educationsSeries = res.data.educations.map((data: any) => {
          return data.count;
        });
        this.educationsCategories = res.data.educations.map((data: any) => {
          return data.name;
        });
      }
      this.reportsData = res.data;
      // trainingCities chart data
      const ethnicitySeries =
        this.reportsData && this.reportsData.trainingCities
          ? this.reportsData.trainingCities.map((data: any) => {
              return data.count;
            })
          : [];
      const isAllEthnivityZero = ethnicitySeries.every(
        (item: any) => item === 0
      );
      this.ethnicitySeries = isAllEthnivityZero ? [1] : ethnicitySeries;
      this.chartOptions = {
        chart: {
          type: "donut",
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          enabled: false,
        },
        stroke: {
          show: true,
          curve: "straight",
          width: 1,
          borderRadius: 1,
        },
        plotOptions: {
          bar: {
            borderRadius: 1,
          },
          pie: {
            donut: {
              size: "80%",
            },
          },
        },
        responsive: [
          {
            breakpoint: 200,
            options: {
              chart: {
                width: 200,
              },
            },
          },
        ],
        fill: {
          colors: isAllEthnivityZero ? ["#F2F4F8"] : this.colors,
        },
      };

      // Education Chart Data
      this.educationsChart = {
        series: [
          {
            name: "Count",
            data: this.educationsSeries,
          },
        ],
        chartOptions: {
          chart: {
            type: "bar",
            toolbar: {
              show: false,
            },
            events: {
              dataPointSelection: (
                event: any,
                chartContext: any,
                opts: any
              ) => {
                const query = {
                  type: "education",
                  isReport: "true",
                  fromDate: this.fromDate,
                  toDate: this.toDate,
                  education: this.educationsCategories[opts.dataPointIndex],
                };
                this.$router.push({
                  path: `/reviewer-dashboard/peers`,
                  query: query,
                });
              },
            },
          },
          grid: {
            show: false,
          },
          plotOptions: {
            bar: {
              columnWidth: "40px",
              distributed: true,
              horizontal: false,
              dataLabels: {
                position: "top",
              },
            },
          },
          dataLabels: {
            position: "top",
            enabled: true,
            textAnchor: "start",
            style: {
              fontSize: "10px",
              colors: ["#000"],
              padding: "10px",
              marginTop: "30px",
            },
            offsetX: 0,
            offsetY: -30,
            horizontal: true,
            hideOverlappingLabels: true,
          },
          legend: {
            show: false,
          },
          xaxis: {
            categories: this.educationsCategories,
            labels: {
              show: true,
              rotate: 0,
              rotateAlways: false,
              hideOverlappingLabels: false,
              trim: true,
              minHeight: 40,
              minWidth: 100,
            },
          },
          yaxis: {
            labels: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            tickAmount: 5,
            min: 0,
            max: 200,
          },
          fill: {
            colors: ["#00A3AF"],
          },
          responsive: [
            {
              breakpoint: 200,
              options: {
                chart: {
                  width: 200,
                },
              },
            },
          ],
        },
      };

      // Age chart data
      const ageSeries = res.data.age18To30
        ? [
            res.data.age18To30.age18To30,
            // res.data.age18To30.notAge18To30,
            // res.data.age18To30.noAge,
          ]
        : [];
      const categories = [
        ["18-30"],
        //  ["31-50"], ["51+"]
      ];
      this.ageChart = {
        series: [
          {
            name: "Count",
            data: ageSeries,
          },
        ],
        chartOptions: {
          chart: {
            type: "bar",
            toolbar: {
              show: false,
            },
            events: {
              dataPointSelection: (
                event: any,
                chartContext: any,
                opts: any
              ) => {
                const toAge =
                  categories[opts.dataPointIndex].toString().split("-")[0] ===
                  "51+"
                    ? "100"
                    : categories[opts.dataPointIndex].toString().split("-")[1];
                const query = {
                  type: "age",
                  isReport: "true",
                  fromDate: this.fromDate,
                  toDate: this.toDate,
                  age: "Age" + "" + categories[opts.dataPointIndex],
                  fromAge: categories[opts.dataPointIndex]
                    .toString()
                    .split("-")[0],
                  toAge: toAge,
                };
                this.$router.push({
                  path: `/reviewer-dashboard/peers`,
                  query: query,
                });
              },
            },
          },
          grid: {
            show: false,
          },
          plotOptions: {
            bar: {
              columnWidth: "80px",
              distributed: true,
              dataLabels: {
                position: "top",
              },
            },
          },
          dataLabels: {
            position: "top",
            enabled: true,
            textAnchor: "start",
            style: {
              fontSize: "10px",
              colors: ["#000"],
            },
            offsetX: 0,
            offsetY: -30,
            horizontal: true,
            hideOverlappingLabels: true,
          },
          legend: {
            show: false,
          },
          xaxis: {
            categories: categories,
          },
          yaxis: {
            labels: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            tickAmount: 10,
            min: 0,
            max: 500,
          },
          fill: {
            colors: ["#00A3AF"],
          },
          responsive: [
            {
              breakpoint: 200,
              options: {
                chart: {
                  width: 200,
                },
              },
            },
          ],
        },
      };
      // gender chart data
      const genderSeries = res.data.gender
        ? [
            res.data.gender.males,
            res.data.gender.females,
            res.data.gender.otherGenders,
          ]
        : [];
      const isgenderZero = genderSeries.every((item: any) => item === 0);
      this.genderChart = {
        series: isgenderZero ? [1] : genderSeries,
        chartOptions: {
          chart: {
            type: "donut",
          },
          legend: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: true,
            curve: "straight",
            width: 1,
            borderRadius: 1,
          },
          plotOptions: {
            bar: {
              borderRadius: 1,
            },
            pie: {
              donut: {
                size: "80%",
              },
            },
          },
          responsive: [
            {
              breakpoint: 200,
              options: {
                chart: {
                  width: 200,
                },
              },
            },
          ],
          fill: {
            colors: isgenderZero
              ? ["#F2F4F8"]
              : ["#00A3AF", "#00B478", "#006CB5"],
          },
        },
      };
    });
  }
  public genderAverageValue() {
    if (
      this.reportsData &&
      this.reportsData.gender &&
      this.reportsData.gender.males &&
      this.reportsData.gender.females &&
      this.reportsData.gender.otherGenders
    ) {
      const averageCount = [
        this.reportsData.gender.males,
        this.reportsData.gender.females,
        this.reportsData.gender.otherGenders,
      ];
      return averageCount;
    }
  }
  public ageAverageValue() {
    if (this.reportsData && this.reportsData.age) {
      const averageCount = this.reportsData.age.map((data: any) => {
        return data.count;
      });
      return averageCount;
    }
  }
  public onDrilDown(content: any, type: string, value = "") {
    const query = {
      type: type,
      isReport: "true",
      count: type === "gender" ? content : content.count,
      fromDate: this.fromDate,
      toDate: this.toDate,
    };

    if (type === "region") {
      query.count = content.userCount;
      Object.assign(query, { regionId: content.id });
      Object.assign(query, { region: content.name });
    }
    if (type === "training") {
      Object.assign(query, { trainingcity: content.name });
    }
    if (type === "gender") {
      Object.assign(query, { gender: value });
    }
    this.$router.push({
      path: `/reviewer-dashboard/peers`,
      query: query,
    });
  }
  public dateChip() {
    this.fromDate = moment(
      new Date(this.certifiedReporDateRange.startDate)
    ).format("MM-DD-YYYY");
    this.toDate = moment(new Date(this.certifiedReporDateRange.endDate)).format(
      "MM-DD-YYYY"
    );
    this.filterDate =
      moment(this.certifiedReporDateRange.startDate).format("MM-DD-YYYY") +
      "  to  " +
      moment(this.certifiedReporDateRange.endDate).format("MM-DD-YYYY");
    this.getReports(this.fromDate, this.toDate);
  }
  public onPrintDownload() {
    window.print();
  }
}
