export const EMPLOYMENT_SUMMARY_FORM_QUERY = `EmploymentSummaries {  
    employmentSummaries(sort: "id:ASC"){
        data{
            id
            attributes{
                main_title
                application_name
                form_fields{
                    id
                    parent_slug
                    fields
                }
            }
        }
    }
}`;
