import { Component, Vue } from "vue-property-decorator";
import ReviewerTrainingPreviewForm from "../reviewer-training-preview/reviewer-training-preview.component.vue";
import ReviewerCertificationPreviewForm from "../reviewer-certification-preview/reviewer-certification-preview.component.vue";
import RenewalPreviewApplicationForm from "../reviewer-renewal-preview/reviewer-renewal-preview.component.vue";
@Component({
  name: "reviewer-peers-preview-application",
  components: {
    ReviewerTrainingPreviewForm,
    ReviewerCertificationPreviewForm,
    RenewalPreviewApplicationForm,
  },
})
export default class ReviewerPeersPreviewApplication extends Vue {
  public applicationName: any;

  public async created() {
    this.applicationName = this.$route.query.application;
  }
}
