var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"renewal-employment-summary-preview-container"},[_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"status-dropdown"},[_c('b-dropdown',{staticClass:"m-md-2",class:[
          _vm.supervisorStatus
            ? _vm.statusWordChange(_vm.supervisorStatus)
            : _vm.statusWordChange(_vm.activeSupervisor.status),
          _vm.status.includes(_vm.progressStatus) ? 'btn-pointer-events-none' : '',
        ],attrs:{"text":_vm.supervisorStatus
            ? _vm.supervisorStatus.toLocaleLowerCase()
            : _vm.status.includes(_vm.progressStatus)
            ? _vm.activeSupervisor.status.toLocaleLowerCase()
            : _vm.activeSupervisor.status.includes('RECEIVED')
            ? 'Select Status'
            : _vm.activeSupervisor.status.toLocaleLowerCase()}},[_c('b-dropdown-item',{staticClass:"accepted",on:{"click":function($event){return _vm.selectStatus('ACCEPTED', _vm.activeSupervisor.email)}}},[_vm._v("Accepted")]),_c('b-dropdown-item',{staticClass:"not-accepted",on:{"click":function($event){return _vm.selectStatus('NOT ACCEPTED', _vm.activeSupervisor.email)}}},[_vm._v("Not Accepted")])],1)],1),_vm._l((_vm.formData),function(formContent,index){return _c('div',{key:index,staticClass:"container-form"},[_c('b-form',{staticClass:"preview-form-container"},[[(_vm.isReviewerPreviewForm)?_c('div',{staticClass:"description"},[_c('h1',{staticClass:"form-title"},[_vm._v(" "+_vm._s(formContent.fields.formHeader)+" ")])]):_vm._e(),_vm._l((formContent.fields.formInputs),function(formInputData,indexi){return _c('div',{key:indexi,class:['fields-main-container', formInputData[0].mainClass]},[_c('div',{staticClass:"form-div",class:[
                formInputData.length == 2
                  ? 'double-input'
                  : formInputData.length > 2
                  ? 'multi-input'
                  : '',
              ]},_vm._l((formInputData),function(formInput,indexj){return _c('div',{key:indexj,staticClass:"form-label",class:[
                  formInput.fieldClass,
                  _vm.fieldUpdate(formInput.name) === true ? 'display-none' : '',
                  formInput.field === 'b-form-textarea'
                    ? 'textarea-field'
                    : '',
                  _vm.classNameChooser(formInput?.class),
                ]},[(!formInput.label)?_c('div',{class:'label-container'},[_c('span',{staticClass:"visiblity-hidden label"},[_vm._v(" "+_vm._s(formInputData[0].label)+" ")])]):_vm._e(),(formInput.label)?_c('div',{class:formInput.label !== null
                      ? 'label-container'
                      : 'display-label'},[(formInput.label)?_c('span',{staticClass:"label",domProps:{"innerHTML":_vm._s(formInput.label + `&nbsp;&nbsp;`)}}):_vm._e(),(
                      formInput.label &&
                      formInput?.validate?.required === true
                    )?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]):_vm._e(),_c(formInput.field,_vm._b({tag:"component",class:formInput.class,attrs:{"name":formInput?.validate?.name,"formInput":formInput.propBind,"autocomplete":"off","signature":_vm.activeSupervisor.data['employmentSignature'],"signatureLabelAndValidate":{
                    label: formInput?.signatureLabel,
                    validate: formInput?.signatureValidate,
                  },"disabled":true,"textAreaMaxlength":formInput?.propBind?.maxlength,"editForm":_vm.disableEvent ? false : true,"formType":'preview'},model:{value:(
                    _vm.activeSupervisor.data[formContent.parent_slug][
                      formInput.name
                    ]
                  ),callback:function ($$v) {_vm.$set(_vm.activeSupervisor.data[formContent.parent_slug], 
                      formInput.name
                    , $$v)},expression:"\n                    activeSupervisor.data[formContent.parent_slug][\n                      formInput.name\n                    ]\n                  "}},'component',{
                    ...formInput.propBind,
                    disabled: true,
                  },false)),_c('label',{staticClass:"label-span"},[_vm._v(_vm._s(formInput.subLabel))])],1)}),0)])})]],2)],1)})],2),(_vm.isLoading)?_c('span',{staticClass:"loading-container"},[_c('p',[_vm._v("Loading...")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }