export const CYAPSS_TESTIMONIALS_QUERY = `CyapssTestimonial {  
  cyapssTestimonials{
      data {
        id
        attributes {
              label
              title
              slideInterval
              testimonials {
                profile_image {
                    data {
                        attributes {
                            name
                            url
                        }
                    }
                }
                name
                program
                description
                full_image {
                  data {
                      attributes {
                          name
                          url
                      }
                  }
              }
              }
        }
      }
    }
  }`;
