var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"letter-of-reference-preview"},[(!_vm.isLoading)?_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"status-dropdown"},[_c('b-dropdown',{staticClass:"m-md-2",class:[
          _vm.referenceStatus
            ? _vm.statusWordChange(_vm.referenceStatus)
            : _vm.statusWordChange(_vm.activeReferral.status),
          _vm.status.includes(_vm.progressStatus) ? 'btn-pointer-events-none' : '',
        ],attrs:{"text":_vm.referenceStatus
            ? _vm.referenceStatus.toLocaleLowerCase()
            : _vm.status.includes(_vm.progressStatus)
            ? _vm.activeReferral.status.toLocaleLowerCase()
            : _vm.activeReferral.status.includes('RECEIVED')
            ? 'Select Status'
            : _vm.activeReferral.status.toLocaleLowerCase()}},[_c('b-dropdown-item',{staticClass:"accepted",on:{"click":function($event){!_vm.status.includes(_vm.progressStatus)
              ? _vm.selectStatus('ACCEPTED', _vm.activeReferral.email)
              : ''}}},[_vm._v("Accepted")]),_c('b-dropdown-item',{staticClass:"not-accepted",on:{"click":function($event){!_vm.status.includes(_vm.progressStatus)
              ? _vm.selectStatus('NOT ACCEPTED', _vm.activeReferral.email)
              : ''}}},[_vm._v("Not Accepted")])],1)],1),_vm._l((_vm.formData),function(formContent,index){return _c('div',{key:index,staticClass:"container-form"},[_c('b-form',{staticClass:"preview-form-container",class:formContent.fields.class},[_c('div',{staticClass:"description"},[_c('h1',{staticClass:"form-title"},[_vm._v(_vm._s(formContent.fields.formHeader))])]),_vm._l((formContent.fields.formInputs),function(formInputData,indexi){return _c('div',{key:indexi,class:['fields-main-container', formInputData[0].mainClass]},[_c('div',{staticClass:"small-content form-div",class:[
              formInputData.length == 2
                ? 'double-input'
                : formInputData.length > 2
                ? 'multi-input'
                : '',
            ]},_vm._l((formInputData),function(formInput,indexj){return _c('div',{key:indexj,staticClass:"form-label",class:[
                formInput.fieldClass,
                _vm.fieldUpdate(formInput.name) === true ? 'display-none' : '',
                formInput.field === 'b-form-textarea' ? 'textarea-field' : '',
                _vm.classNameChooser(formInput?.class),
              ]},[(!formInput.label)?_c('div',{class:'label-container'},[_c('span',{staticClass:"visiblity-hidden label"},[_vm._v(" "+_vm._s(formInputData[0].label)+" ")])]):_vm._e(),(formInput.label)?_c('div',{class:formInput.label !== null
                    ? 'label-container'
                    : 'display-label'},[(formInput.label)?_c('span',{staticClass:"label",domProps:{"innerHTML":_vm._s(formInput.label + `&nbsp;&nbsp;`)}}):_vm._e(),(
                    formInput.label && formInput?.validate?.required === true
                  )?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]):_vm._e(),_c('span',{staticClass:"label-span"},[_vm._v(_vm._s(formInput.note))]),_c(formInput.field,_vm._b({directives:[{name:"validate",rawName:"v-validate",value:(formInput?.validate?.veeValidate),expression:"formInput?.validate?.veeValidate"}],tag:"component",class:formInput.class,attrs:{"name":formInput?.validate?.name,"formInput":formInput.propBind,"autocomplete":"off","signature":_vm.activeReferral.data[
                    'letterOfReferenceSignatureAndSubmission'
                  ],"signatureLabelAndValidate":{
                  label: formInput?.signatureLabel,
                  validate: formInput?.signatureValidate,
                },"textAreaMaxlength":formInput?.propBind?.maxlength,"editForm":false,"formType":'preview'},model:{value:(
                  _vm.activeReferral.data[formContent.parent_slug][formInput.name]
                ),callback:function ($$v) {_vm.$set(_vm.activeReferral.data[formContent.parent_slug], formInput.name, $$v)},expression:"\n                  activeReferral.data[formContent.parent_slug][formInput.name]\n                "}},'component',{ ...formInput.propBind, disabled: true },false))],1)}),0)])})],2)],1)})],2):_vm._e(),(_vm.errorMessage)?_c('ErrorPopupComponent',{attrs:{"componentName":'reference-preview-form-error-popup',"errorMessage":_vm.errorMessage},on:{"closdedErrorPopup":function($event){return _vm.closdedErrorPopup($event)}}}):_vm._e(),(_vm.isLoading)?_c('span',{staticClass:"loading-container"},[_c('p',[_vm._v("Loading...")])]):_vm._e(),(_vm.isLargeLoading)?_c('AppLoaderComponent',{attrs:{"type":'large'}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }