import BannerComponent from "@/components/banner/main-banner.vue";
import CyapssApplicationsComponent from "@/components/cyapss-applications/cyapss-applications.component.vue";
import IntroductionComponent from "@/components/cyapss-introduction/cyapss-introduction.component.vue";
import CyapssTestimonialsComponent from "@/components/cyapss-testimonials/cyapss-testimonials.component.vue";
import MoreInformation from "@/components/more-information/more-information.vue";
import { Component, Vue } from "vue-property-decorator";
import { appConfigService } from "@/providers/services/app/app-config";
import { loginApi } from "@/providers/apis/login";
@Component({
  name: "home-page",
  components: {
    BannerComponent,
    IntroductionComponent,
    CyapssApplicationsComponent,
    CyapssTestimonialsComponent,
    MoreInformation,
  },
})
export default class HomePage extends Vue {
  public created() {
    loginApi
      .logInWithCredential(
        appConfigService.getClientId(),
        appConfigService.getClientKey(),
        "anonymous"
      )
      .then((data) => {
        localStorage.setItem("anonymousToken", data.access_token);
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
