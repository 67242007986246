import { Component, Vue } from "vue-property-decorator";
import { gql } from "graphql-tag";
import RadioButtonComponent from "../radio-button/radio-button.component.vue";
import SignatureSubmissionComponent from "../signature-submission/signature-submission.component.vue";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import EligibilityCriteriaComponent from "../eligibility-criteria/eligibility-criteria.component.vue";
import { EMPLOYMENT_SUMMARY_FORM_QUERY } from "@/graphql/employment-summary-form-query";
import {
  EmploymentSummariesForm,
  FormFieldContents,
  FormNavbar,
} from "@/models/employment-summary-form/employment-summary-form.interface";
import { employmentVolunteerFormApi } from "@/providers/apis/employment-volunteer-form";
import { utils } from "@/utils/utils";
import moment from "moment";
import ErrorPopupComponent from "../error-popup/error-popup.component.vue";
import FormInputDatepickerComponent from "../form-input-datepicker/form-input-datepicker.component.vue";

@Component({
  name: "certification-form",
  components: {
    "b-form-radio-group": RadioButtonComponent,
    "b-form-signature-submission": SignatureSubmissionComponent,
    AppLoaderComponent,
    EligibilityCriteriaComponent,
    ErrorPopupComponent,
    "b-form-input-datepicker": FormInputDatepickerComponent,
  },
})
export default class EmploymentSummaryForm extends Vue {
  public formPagination = 0;
  public formNames = [
    "employmentApplicantInformation",
    "employmentVolunteerQuestions",
    "supervisorContactInformation",
    "employmentSignature",
  ];
  public applicationData: Array<EmploymentSummariesForm> = [];
  public formData: FormFieldContents[] = [];
  public applicationNavData: FormNavbar[] = [];
  public isScroll = false;
  public signatureInvalid = false;
  public saveDataDraft = false;
  public payLoadData: any = {
    fillStatus: "DRAFT",
    id: "",
    isEligibility: false,
    employmentApplicantInformation: {
      applicantFirstName: "",
      applicantLastName: "",
      applicantAgencyOrOrganization: "",
      applicantPositionOrTitle: "",
      startDate: "",
      hours: "",
      servicesPosition: "",
      hoursPeerSupportService: "",
    },
    employmentVolunteerQuestions: {
      applicantWork: "",
      supervisionInteractions: "",
      developmentPlan: "",
    },
    supervisorContactInformation: {
      firstName: "",
      lastName: "",
      supervisorCredentials: "",
      supervisorPositionOrTitle: "",
      phone: "",
      email: "",
      agencyOrOrganization: "",
      addressLine1: "",
      addressLine2: "",
      state: "",
      city: "",
      zip: "",
    },
    employmentSignature: {
      isSignature: "",
      submissionDate: moment().format("MM-DD-YYYY"),
    },
  };
  public formTitle = {
    main_title: "",
    application_name: "",
  };
  public isLoading = false;
  public isEligibility = false;
  public token: any;
  public applicationDetials: any;
  public applicationDataDetials: any;
  public eventBind: any = {
    acceptNumberOnly: this.numberValidation,
    avoidNumbersAndSpecialChar: this.avoidNumbersAndSpecialCharacter,
    telCharacter: this.telCharacterValidate,
    onlyAlphabets: this.onlyAlphabets,
  };
  public checkValidForm = false;
  public errorMessage = "";
  public mainId = "app";
  public supervisorOfInformation: any;
  public supervisorData: any;

  public async created() {
    this.token = this.$route.query?.token;
    let payloadStorage: any = localStorage.getItem("employmentPayLoadData");
    if (payloadStorage) {
      payloadStorage = JSON.parse(payloadStorage);
      this.payLoadData = { ...payloadStorage };
      if (this.payLoadData.isEligibility) {
        this.isEligibility = true;
        this.formPagination = 1;
      }
    }
    this.$apollo
      .query({
        query: gql`query ${EMPLOYMENT_SUMMARY_FORM_QUERY}`,
      })
      .then((result) => {
        this.applicationData = result.data.employmentSummaries.data;
        this.formTitle = {
          main_title: this.applicationData[0].attributes.main_title,
          application_name: this.applicationData[0].attributes.application_name,
        };
        this.formData = this.applicationData[0].attributes.form_fields;
        this.applicationNavData = this.applicationData[0].attributes.navbar;
      });
    if (this.token) {
      this.isLoading = true;
      employmentVolunteerFormApi
        .getEmploymentVolunteerForm(this.token)
        .then((result: any) => {
          this.isLoading = false;
          this.applicationDetials = result.data;
          this.applicationDataDetials =
            this.applicationDetials.data.certificationPartTwoApplicationForm;
          if (this.applicationDetials) {
            this.payLoadData.employmentApplicantInformation.applicantFirstName =
              this.applicationDetials.firstName;
            this.payLoadData.employmentApplicantInformation.applicantLastName =
              this.applicationDetials.lastName;

            this.supervisorOfInformation =
              this.applicationDetials.data.certificationPartTwoSupervisorInformation;

            const decodeToken = JSON.parse(atob(this.token.split(".")[1]));
            const emailData = decodeToken.email;

            this.supervisorOfInformation.map((data: any) => {
              this.supervisorData = data;
              if (data.supervisorEmail == emailData) {
                this.payLoadData.supervisorContactInformation.firstName =
                  this.supervisorData.supervisorFirstName;
                this.payLoadData.supervisorContactInformation.lastName =
                  this.supervisorData.supervisorLastName;
                this.payLoadData.supervisorContactInformation.credentials =
                  this.supervisorData.supervisorCredentials;
                this.payLoadData.supervisorContactInformation.position =
                  this.supervisorData.supervisorPositionOrTitle;
                this.payLoadData.supervisorContactInformation.phone =
                  this.supervisorData.supervisorPhone;
                this.payLoadData.supervisorContactInformation.email =
                  this.supervisorData.supervisorEmail;
                this.payLoadData.supervisorContactInformation.agencyOrOrganization =
                  this.supervisorData.supervisorAgencyOrOrganization;
              }
            });
          }
          localStorage.setItem(
            "employmentPayLoadData",
            JSON.stringify(this.payLoadData)
          );
        })
        .catch((error) => {
          this.errorMessage = utils.errorMessage(error, true);
          this.isLoading = false;
        });
    }
  }

  public nextButton() {
    this.checkValidForm = true;
    if (this.payLoadData.isEligibility) {
      const el = document.getElementById(this.mainId);
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
      if (this.formPagination < this.formData.length) {
        const page = this.formPagination + 1;
        this.formPagination = page;
      }
      localStorage.setItem(
        "employmentPayLoadData",
        JSON.stringify(this.payLoadData)
      );
    }
  }

  public saveDraft() {
    this.$bvToast.show("example-toast");
    localStorage.setItem(
      "employmentPayLoadData",
      JSON.stringify(this.payLoadData)
    );
    this.saveDataDraft = true;

    setTimeout(() => {
      this.saveDataDraft = false;
    }, 1000);
  }

  public previousButton() {
    if (this.formPagination !== 0) {
      const el = document.getElementById(this.mainId);
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
      this.formPagination = this.formPagination - 1;
    }
  }

  public onSubmit() {
    this.checkValidForm = true;
    this.$validator.validate().then((validate) => {
      if (validate) {
        if (this.payLoadData.employmentSignature.isSignature) {
          this.signatureInvalid = false;
        } else {
          this.signatureInvalid = true;
          setTimeout(() => {
            const el = document.getElementsByClassName("is-invalid")[0];
            if (el) {
              el.scrollIntoView({ behavior: "smooth", block: "end" });
            }
          }, 10);
          return;
        }
        localStorage.setItem(
          "employmentPayLoadData",
          JSON.stringify(this.payLoadData)
        );
        this.isLoading = true;
        const el = document.getElementById(this.mainId);
        if (el) {
          el.scrollIntoView({ behavior: "smooth" });
        }
        let payloadStorage: any = localStorage.getItem("employmentPayLoadData");
        if (payloadStorage) {
          payloadStorage = JSON.parse(payloadStorage);
          this.payLoadData = { ...payloadStorage };
        }
        employmentVolunteerFormApi
          .postEmploymentApplicationForm(this.token, this.payLoadData)
          .then(() => {
            this.checkValidForm = false;
            const someData = {
              title: "Certified Young Adult Peer Support Specialist",
              SubTitle: "Employment / Volunteer Form",
            };
            this.$router.push({
              path: "/form/submitted",
              query: {
                subTitle: someData.SubTitle,
              },
            });
            localStorage.removeItem("employmentPayLoadData");
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            this.errorMessage = utils.errorMessage(error);
          });
      } else {
        const el = document.getElementsByClassName("is-invalid")[0];
        if (el) {
          el.scrollIntoView({ behavior: "smooth", block: "end" });
        }
      }
    });
  }

  public closeToast() {
    this.$bvToast.hide("example-toast");
  }

  public eligibilityChecked(event: any) {
    this.payLoadData.isEligibility = event[0];
    this.isEligibility = true;
    this.formPagination = 1;
  }

  public numberValidation(evt: any, length?: number) {
    utils.onlyNumberKey(evt, length);
  }

  public avoidNumbersAndSpecialCharacter(evt: any) {
    utils.avoidNumbersAndSpecialCharacters(evt);
  }

  public telCharacterValidate(evt: any, length?: number) {
    utils.telCharacterValidate(evt, length);
  }

  public onlyAlphabets(evt: any, fieldName = "") {
    utils.onlyAlphabets(evt, fieldName);
  }

  public emptyEvent() {
    return;
  }

  public fieldValidation(
    value: any,
    eventName: string,
    fieldType: string,
    maxLength = 0,
    fieldName = ""
  ) {
    if (fieldType === "number") {
      this.eventBind[eventName](value, maxLength);
    } else if (fieldName === "state") {
      this.eventBind[eventName](value, fieldName);
    } else {
      this.eventBind[eventName](value);
    }
  }

  public closdedErrorPopup(event: any) {
    if (event) {
      this.errorMessage = "";
    }
  }

  public classNameChooser(inputClass: string) {
    return utils.classNameChooser(inputClass);
  }
}
