import gql from "graphql-tag";
import Vue, { watch } from "vue";
import Component from "vue-class-component";
import {
  RubricAttributes,
  ScoringRubricForm,
} from "@/models/scoring-rubric-form/scoring-rubric.interface";
import ScoreRatingComponent from "@/layouts/score-rating/score-rating.component.vue";
import { scoredData, User } from "@/models/common-interface/common.interface";
import { utils } from "@/utils/utils";
import { Prop, Watch } from "vue-property-decorator";
import { commonService } from "@/providers/services/common-service";
import { SCORING_RUBRIC_QUERY } from "@/graphql/scoring-rubric-query";
import RadioButtonComponent from "../radio-button/radio-button.component.vue";
import PreviewPopupComponent from "../preview-popup/preview-popup.component.vue";
import { appConfigService } from "@/providers/services/app/app-config";
import ErrorPopupComponent from "../error-popup/error-popup.component.vue";
import moment from "moment";

@Component({
  components: {
    ScoreRatingComponent,
    "b-form-radio-group": RadioButtonComponent,
    PreviewPopupComponent,
    ErrorPopupComponent,
  },
})
export default class ScoreRubicFormComponent extends Vue {
  @Prop()
  public progressStatus!: string;
  @Prop()
  public applicationId!: string;
  @Prop()
  public rubricData: any;
  @Prop()
  public formType!: string;
  @Prop()
  public allApplicationSubmissionStatus!: boolean;
  @Prop()
  public hideStatus!: boolean;
  @Prop()
  public disableEvent!: boolean;

  @Prop()
  public isProcessingAvailable: any;

  @Prop()
  public applicantPreviewForm: any;

  public scoreRubricFormData: any = {};
  public scoringRubricData: ScoringRubricForm[] = [];
  public formData: RubricAttributes = {} as RubricAttributes;
  public toogleStatus = false;
  public statusName = "";
  public personalRecoveryTotal = 0;
  public personalRecoveryScoredTotal = 0;
  public motivationTotal = 0;
  public motivationScoredTotal = 0;
  public writingTotal = 0;
  public writingScoredTotal = 0;
  public addition: number[] = [];
  public overallTotal = 0;
  public scoredData: scoredData[] = [];
  public overallScored = 0;
  public scoringPayload: any = {};
  public scoringObject: any = {};
  public didtheApplicantFields: any = {
    training: {
      sexOffenderRegistry: "",
      abuseRegistry: "",
      requiredInformation: "",
      highestLevelofEducation: "",
      previousJobs: "",
      MHorSU: "",
      otherNotes: "",
    },
    certification: {
      markYes: "",
      requiredInformation: "",
      livedExperience: "",
      supervisorMarkYes: "",
      volunteerResponsibilities: "",
      supervisionInteractions: "",
      planorGoals: "",
      otherNotes: "",
      refOneRelationshipWiththeApplicant: "",
      refOneAtleastThreeMonths: "",
      refOneRelatestoOthers: "",
      refOneApplicantStrengths: "",
      refOneAnythingElse: "",
      refTwoRelationshipWiththeApplicant: "",
      refTwoAtleastThreeMonths: "",
      refTwoRelatestoOthers: "",
      refTwoApplicantStrengths: "",
      refTwoAnythingElse: "",
      refThreeRelationshipWiththeApplicant: "",
      refThreeAtleastThreeMonths: "",
      refThreeRelatestoOthers: "",
      refThreeApplicantStrengths: "",
      refThreeAnythingElse: "",
    },
    renewal: {
      includingSignature: "",
      continuingEducation: "",
      numberofHours: "",
      onlineTraining: "",
      ethics: "",
      culturalCompetency: "",
      certificationsofCompletion: "",
      fillOutPage: "",
      supervisorSignature: "",
      peerSupportServices: "",
      supervision: "",
      otherNotes: "",
    },
  };
  public isStatusEmpty = false;
  public isPreviewRubric = false;
  public rubricRevokeObject: any = {};
  public evaluatorName = "";
  public comment = "";
  public userDetails: User = {} as User;
  public isScoringSubmited = false;
  public scoringRubricLength: any;
  public isValid = true;
  public evaluatorDate = moment(new Date()).format("MM-DD-YYYY");
  public file: any = null;
  public fileType = "";
  public fileName: any;
  public filePreviewName = "";
  public fileLink = "";
  public scoringField = ["personalRecovery", "motivation", "writing"];
  public currentRoute = "";
  public files: any[] = [];
  public S3Path = "";
  public hideDiv: string[] = [];
  public checkField: string[] = [];
  public previewFileData: any[] = [];
  public imgType = ["jpg", "png", "jpeg", "tiff"];
  public showPreviewPopup = false;
  public errorMessage = "";
  public applicationName: any;

  public created() {
    this.applicationName = this.$route.query.application;
    this.S3Path = appConfigService.getS3FilesPathUrl();
    this.currentRoute = this.$route.path;
    this.userDetails = utils.getUserDetails();
    if (!this.rubricData) {
      this.evaluatorName = this.userDetails.displayName;
    } else {
      this.buildRubricData();
    }
    this.$apollo
      .query({
        query: gql`query ${SCORING_RUBRIC_QUERY}`,
      })
      .then((response) => {
        this.scoringRubricData = response.data.scoringRubricForms.data;
        this.formData = this.scoringRubricData[0].attributes.scoring_rubric;
        for (let index = 0; index < this.formData.formFields.length; index++) {
          this.formData.formFields[index].fields?.map((field) => {
            field?.map((data) => {
              if (data.name === "personalRecovery") {
                this.scoringRubricLength = data.fields;
                data.fields.map((data) => {
                  this.addition.push(data.points.value - 1);
                  this.personalRecoveryTotal = utils.addition(this.addition);
                });
                this.addition = [];
              }
              if (data.name === "motivation") {
                data.fields.map((data) => {
                  this.addition.push(data.points.value - 1);
                  this.motivationTotal = utils.addition(this.addition);
                });
                this.addition = [];
              }
              if (data.name === "writing") {
                data.fields.map((data) => {
                  this.addition.push(data.points.value - 1);
                  this.writingTotal = utils.addition(this.addition);
                });
                this.addition = [];
              }
            });
          });
        }
        this.overallTotal =
          this.personalRecoveryTotal + this.writingTotal + this.motivationTotal;
      });
  }

  public handleFileSelect(evt: any) {
    evt.target.files.forEach((data: File) => {
      this.files.push(data);
    });
    evt.target.value = null;
  }

  public getType(type: string) {
    if (type.includes("pdf" || "doc" || "docx")) {
      return "application";
    } else {
      return "image";
    }
  }

  public onSubmit(parentSlug: string) {
    this.$validator.validate().then(async (validate) => {
      if (!this.statusName) {
        this.isStatusEmpty = true;
      } else {
        this.isStatusEmpty = false;
      }
      const isValidScore =
        this.currentRoute.split("/")[2] === "training"
          ? await this.validateRubricForm()
          : true;
      if (!validate) {
        this.isValid = false;
      }
      if (validate && !this.isStatusEmpty && this.formType && isValidScore) {
        this.$emit("reloadPage", false);
        for (const property in this.didtheApplicantFields[parentSlug]) {
          if (
            typeof this.didtheApplicantFields[parentSlug][property] ===
              "string" ||
            typeof this.didtheApplicantFields[parentSlug][property] ===
              "boolean"
          ) {
            this.scoringObject[property] =
              this.didtheApplicantFields[parentSlug][property];
          }
        }
        if (this.files.length) {
          await commonService
            .uploadFile(this.files)
            .then((result: any) => {
              let fileBlock = result.data;
              if (fileBlock.length) {
                this.files = [];
                fileBlock = fileBlock.filter(
                  (data: any) => !(data instanceof File)
                );
                for (let index = 0; index < fileBlock.length; index++) {
                  this.files.push(fileBlock[index]);
                }
              }
            })
            .catch((error) => {
              return error;
            });
        }
        this.scoringObject["evaluatorName"] = this.evaluatorName;
        this.scoringObject["comment"] = this.comment;
        this.scoringObject["date"] = moment(
          new Date(this.evaluatorDate)
        ).format("MM-DD-YYYY");
        this.scoringObject["file"] = this.files;
        this.scoringPayload = {
          id: this.applicationId,
          rubric: this.scoringObject,
          status: this.statusName.toUpperCase(),
        };
        if (
          this.statusName === "accepted" ||
          this.statusName === "not accepted"
        ) {
          this.$emit("emitApproveStatus", {
            status: this.statusName.toUpperCase(),
            comment: this.comment,
            rubric: this.scoringObject,
          });
        } else {
          this.getRubricApplication();
        }
      } else {
        const el = document.getElementsByClassName("is-invalid")[0];
        if (el) {
          el.scrollIntoView({ behavior: "smooth", block: "end" });
        }
      }
    });
    this.isScoringSubmited = true;
  }
  public getRubricApplication() {
    commonService
      .rubricApplication(this.scoringPayload, this.formType)
      .then(() => {
        this.$emit("reloadPage", true);
      })
      .catch((error) => {
        this.errorMessage = utils.errorMessage(error);
      });
  }

  public statusUpdate(status: string) {
    this.statusName = status;
    this.isStatusEmpty = false;
    this.toogleStatus = !this.toogleStatus;
  }

  public scored(score: scoredData) {
    if (this.scoredData.length === 0) {
      this.scoredData = [score];
    } else {
      const index = this.scoredData.findIndex((data) => {
        return data.parentSlug === score.parentSlug;
      });
      if (index >= 0) {
        const scoredIndex = this.scoredData[index].scored.findIndex((data) => {
          return data.key === score.scored[0].key;
        });
        if (scoredIndex >= 0) {
          this.scoredData[index].scored.splice(scoredIndex, 1);
          this.scoredData[index].scored.push(score.scored[0]);
        } else {
          this.scoredData[index].scored.push(score.scored[0]);
        }
      } else {
        this.scoredData.push(score);
      }
    }
    this.scoreAddition(score);
    this.scoredData.map((score: any) => {
      score.scored.map((item: any) => {
        if (this.scoringObject[score.parentSlug]) {
          this.scoringObject[score.parentSlug] = {
            ...this.scoringObject[score.parentSlug],
            [item.key]: item.points,
          };
        } else {
          this.scoringObject[score.parentSlug] = { [item.key]: item.points };
        }
      });
    });
  }

  public scoreAddition(score: scoredData) {
    const indexi = this.scoredData.findIndex((data) => {
      return data.parentSlug === score.parentSlug;
    });
    this.scoredData[indexi].scored.map((data) => {
      this.addition.push(data.points);
      this.scoredData[indexi].scoredPoints = utils.addition(this.addition);
      if (this.scoredData[indexi].parentSlug === "personalRecovery") {
        this.personalRecoveryScoredTotal =
          this.scoredData[indexi].scoredPoints || 0;
      } else if (this.scoredData[indexi].parentSlug === "motivation") {
        this.motivationScoredTotal = this.scoredData[indexi].scoredPoints || 0;
      } else if (this.scoredData[indexi].parentSlug === "writing") {
        this.writingScoredTotal = this.scoredData[indexi].scoredPoints || 0;
      } else {
        return;
      }
    });
    this.addition = [];
    this.overallScored = utils.addition([
      this.personalRecoveryScoredTotal,
      this.writingScoredTotal,
      this.motivationScoredTotal,
    ]);
  }

  public onChangeScore(item: any, parentSlug: string) {
    const scoreRubricFormData = this.scoreRubricFormData;
    scoreRubricFormData[parentSlug] = {
      ...scoreRubricFormData[parentSlug],
      ...item[parentSlug],
    };
  }

  public async validateRubricForm() {
    this.isValid = true;
    const formFields: any = this.formData.formFields[0].fields;
    const scoringFields = await formFields.filter((formField: any) => {
      return formField.find((item: any) => item.type === "scoring");
    });
    scoringFields.forEach((scoringField: any) => {
      const formRubricFields = scoringField[0].fields;
      const fieldCounts = formRubricFields.length;
      if (fieldCounts) {
        const activeFormData =
          this.scoreRubricFormData[scoringField[0].name] || {};
        if (
          fieldCounts !== Object.keys(activeFormData).length &&
          !this.rubricData
        ) {
          this.isValid = false;
        }
      }
    });
    return this.isValid;
  }

  public checkApplicationSubmissionStatus() {
    this.toogleStatus = !this.toogleStatus;
    this.isStatusEmpty = false;
  }

  @Watch("rubricData")
  public buildRubricData() {
    if (this.rubricData?.file) {
      this.files = this.rubricData?.file;
    }
    this.isPreviewRubric = true;
    this.didtheApplicantFields = {
      training: {
        sexOffenderRegistry: this.rubricData?.sexOffenderRegistry,
        abuseRegistry: this.rubricData?.abuseRegistry,
        requiredInformation: this.rubricData?.requiredInformation,
        highestLevelofEducation: this.rubricData?.highestLevelofEducation,
        previousJobs: this.rubricData?.previousJobs,
        MHorSU: this.rubricData?.MHorSU,
        livedExperienceNotAccept: this.rubricData?.livedExperienceNotAccept,
      },
      certification: {
        markYes: this.rubricData?.markYes,
        requiredInformation: this.rubricData?.requiredInformation,
        livedExperience: this.rubricData?.livedExperience,
        supervisorMarkYes: this.rubricData?.supervisorMarkYes,
        volunteerResponsibilities: this.rubricData?.volunteerResponsibilities,
        supervisionInteractions: this.rubricData?.supervisionInteractions,
        planorGoals: this.rubricData?.planorGoals,
        planorGoalsNotAccept: this.rubricData?.planorGoalsNotAccept,
        refOneRelationshipWiththeApplicant:
          this.rubricData?.refOneRelationshipWiththeApplicant,
        refOneAtleastThreeMonths: this.rubricData?.refOneAtleastThreeMonths,
        refOneRelatestoOthers: this.rubricData?.refOneRelatestoOthers,
        refOneApplicantStrengths: this.rubricData?.refOneApplicantStrengths,
        refOneAnythingElse: this.rubricData?.refOneAnythingElse,
        refTwoRelationshipWiththeApplicant:
          this.rubricData?.refTwoRelationshipWiththeApplicant,
        refTwoAtleastThreeMonths: this.rubricData?.refTwoAtleastThreeMonths,
        refTwoRelatestoOthers: this.rubricData?.refTwoRelatestoOthers,
        refTwoApplicantStrengths: this.rubricData?.refTwoApplicantStrengths,
        refTwoAnythingElse: this.rubricData?.refTwoAnythingElse,
        refThreeRelationshipWiththeApplicant:
          this.rubricData?.refThreeRelationshipWiththeApplicant,
        refThreeAtleastThreeMonths: this.rubricData?.refThreeAtleastThreeMonths,
        refThreeRelatestoOthers: this.rubricData?.refThreeRelatestoOthers,
        refThreeApplicantStrengths: this.rubricData?.refThreeApplicantStrengths,
        refThreeAnythingElse: this.rubricData?.refThreeAnythingElse,
      },
      renewal: {
        includingSignature: this.rubricData?.includingSignature,
        continuingEducation: this.rubricData?.continuingEducation,
        numberofHours: this.rubricData?.numberofHours,
        onlineTraining: this.rubricData?.onlineTraining,
        ethics: this.rubricData?.ethics,
        culturalCompetency: this.rubricData?.culturalCompetency,
        certificationsofCompletion: this.rubricData?.certificationsofCompletion,
        fillOutPage: this.rubricData?.fillOutPage,
        supervisorSignature: this.rubricData?.supervisorSignature,
        peerSupportServices: this.rubricData?.peerSupportServices,
        supervision: this.rubricData?.supervision,
        otherNotes: this.rubricData?.otherNotes,
      },
    };
    for (const property in this.rubricData) {
      if (this.scoringField.includes(property)) {
        for (const innerProperty in this.rubricData[property]) {
          const buildScoringFormat: any = {
            parentSlug: property,
            scored: [
              {
                key: innerProperty,
                points: this.rubricData[property][innerProperty],
              },
            ],
            scoredPoints: this.rubricData[property][innerProperty],
          };
          this.rubricRevokeObject[innerProperty] =
            this.rubricData[property][innerProperty];
          this.scored(buildScoringFormat);
        }
      }
    }
    if (this.rubricData["personalRecovery"]) {
      Object.keys(this.rubricData["personalRecovery"]).map((data) => {
        this.addition.push(this.rubricData["personalRecovery"][data]);
      });
      this.personalRecoveryScoredTotal = utils.addition(this.addition);
      this.addition = [];
    }
    if (this.rubricData["motivation"]) {
      Object.keys(this.rubricData["motivation"]).map((data) => {
        this.addition.push(this.rubricData["motivation"][data]);
      });
      this.motivationScoredTotal = utils.addition(this.addition);
      this.addition = [];
    }
    if (this.rubricData["writing"]) {
      Object.keys(this.rubricData["writing"]).map((data) => {
        this.addition.push(this.rubricData["writing"][data]);
      });
      this.writingScoredTotal = utils.addition(this.addition);
      this.addition = [];
    }
    this.overallScored = utils.addition([
      this.personalRecoveryScoredTotal,
      this.writingScoredTotal,
      this.motivationScoredTotal,
    ]);
    this.statusName = this.progressStatus.toLowerCase();
    this.comment = this.rubricData.comment;
    this.evaluatorName = this.rubricData.evaluatorName;
    this.evaluatorDate = this.rubricData.date;
  }

  public previewFile(index: number) {
    this.file = this.files[index];
    if (this.file) {
      if (this.file.type.split("/")[0] === "application") {
        const file = new Blob([this.file], { type: this.file.type });
        const fileURL = URL.createObjectURL(file);
        this.previewFileData = [
          {
            url: fileURL,
            name: this.file.name,
            type: this.file.type,
          },
        ];
        setTimeout(() => {
          this.showPreviewPopup = true;
        }, 50);
      } else {
        const fileURL = URL.createObjectURL(this.file);
        const img = new Image();
        img.src = fileURL;
        this.previewFileData = [
          {
            width: img.width,
            height: img.height,
            url: fileURL,
            name: this.file.name,
            type: this.file.type,
          },
        ];
        this.showPreviewPopup = true;
      }
    }
  }

  public async toggleModal(file: any) {
    if (this.imgType.includes(file.fileName.split(".")[1])) {
      const img = new Image();
      img.src = `${this.S3Path}/${file.fileName}`;
      file.width = img.width;
      file.height = img.height;
    }
    this.previewFileData = [file];
    this.showPreviewPopup = true;
  }

  public deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  public hidePreviewPopup(event: boolean) {
    if (event === true) {
      this.showPreviewPopup = false;
      this.previewFileData = [];
    }
  }

  public closdedErrorPopup(event: any) {
    if (event) {
      this.errorMessage = "";
    }
  }
}
