import { Component, Vue } from "vue-property-decorator";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import { applicantDetialsApi } from "@/providers/apis/applicant-dashboard";
import moment from "moment";
import { User } from "@/models/common-interface/common.interface";
import { utils } from "@/utils/utils";
import PreviewPopupComponent from "../preview-popup/preview-popup.component.vue";
import { appConfigService } from "@/providers/services/app/app-config";
import ErrorPopupComponent from "../error-popup/error-popup.component.vue";

@Component({
  name: "user-conversations",
  components: {
    AppLoaderComponent,
    PreviewPopupComponent,
    ErrorPopupComponent,
  },
})
export default class UserConversationsComponent extends Vue {
  public conversionData: any[] = [];
  public isLoading = true;
  public S3Path = "";
  public currentConversation: any = {};
  public userDetails: User = {} as User;
  public attachmentMouseOverIdx = -1;
  public imgType = ["png", "jpg", "jpeg"];
  public docType = ["pdf", "doc"];
  public imageType = "";
  public showPreviewPopup = false;
  public previewFileData: any[] = [];
  public errorMessage = "";

  public created() {
    this.S3Path = appConfigService.getS3FilesPathUrl();
    this.userDetails = utils.getUserDetails();
    applicantDetialsApi
      .getUserConversations()
      .then((conversation: any) => {
        this.conversionData = conversation.data.data;
        this.conversionData.map((data: any) => {
          data.displayDate = moment(data.createdAt).format("ll");
          if (data.sendorThumbnail) {
            data.profileImg = this.S3Path + "/" + data.sendorThumbnail;
          }
          data.collapsedMessage = data.message.replace(/<[^>]*>/g, " ");
          data.attachments &&
            data.attachments.map((attachment: any) => {
              attachment.type = attachment.uploadedFileName.split(".")[1];
            });
        });
        this.conversionData = this.conversionData.sort((a: any, b: any) => {
          a.createdAt = moment(a.createdAt).format("MM-DD-YYYY HH:mm:ss:SSS");
          b.createdAt = moment(b.createdAt).format("MM-DD-YYYY HH:mm:ss:SSS");
          return (
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
          );
        });
        this.conversionData.reverse();
        this.isLoading = false;
      })
      .catch((error) => {
        this.errorMessage = utils.errorMessage(error);
        this.isLoading = false;
      });
  }

  public getCurrentConversations(data: any) {
    this.currentConversation = data;
  }

  public attachmentMouseOver(idx: number) {
    this.imageType =
      this.currentConversation.attachments[idx].uploadedFileName.split(".")[1];
    this.attachmentMouseOverIdx = idx;
  }
  public attachmentMouseLeave() {
    this.attachmentMouseOverIdx = -1;
  }

  public emailAttachmentPreview(attachmentIdx: number) {
    const previewFile = this.currentConversation.attachments[attachmentIdx];
    if (previewFile) {
      if (this.imgType.includes(previewFile.uploadedFileName.split(".")[1])) {
        const img = new Image();
        img.src = `${this.S3Path}/${previewFile.uploadedFileName}`;
        previewFile.width = img.width;
        previewFile.height = img.height;
      }
      this.previewFileData = [previewFile];
      this.showPreviewPopup = true;
    }
  }

  public hidePreviewPopup(event: boolean) {
    if (event === true) {
      this.showPreviewPopup = false;
      this.previewFileData = [];
    }
  }

  public async downloadFile(fileUrl: string, fileName: string) {
    const blob = new Blob([fileUrl], { type: "text/javascript" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    link.click();
  }

  public closdedErrorPopup(event: any) {
    if (event) {
      this.errorMessage = "";
    }
  }
}
