import { LETTER_OF_REFERENCE_QUERY } from "@/graphql/letter-of-reference-form-query";
import { Component, Vue } from "vue-property-decorator";
import { gql } from "graphql-tag";
import RadioButtonComponent from "../radio-button/radio-button.component.vue";
import SignatureSubmissionComponent from "../signature-submission/signature-submission.component.vue";
import { letterOfReferenceFormApi } from "@/providers/apis/letter-of-reference";
import {
  FormFieldContents,
  LetterOfReference,
} from "@/models/letter-of-reference-form/letter-of-reference-from.interface";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import EligibilityCriteriaComponent from "../eligibility-criteria/eligibility-criteria.component.vue";
import { utils } from "@/utils/utils";
import moment from "moment";
import ErrorPopupComponent from "../error-popup/error-popup.component.vue";

@Component({
  name: "letter-of-reference",
  components: {
    "b-form-radio-group": RadioButtonComponent,
    "b-form-signature-submission": SignatureSubmissionComponent,
    AppLoaderComponent,
    EligibilityCriteriaComponent,
    ErrorPopupComponent,
  },
})
export default class LetterOfReferenceForm extends Vue {
  public applicationDetials: any;
  public applicationData: Array<LetterOfReference> = [];
  public formData: FormFieldContents[] = [];
  public formPagination = 0;
  public formName = "letterOfReferenceApplicantInformation";
  public formNames = [
    "letterOfReferenceApplicantInformation",
    "letterOfReferenceReferenceDetails",
    "letterOfReferenceSignatureAndSubmission",
  ];
  public referenceOfInformation: any;
  public isValid = false;
  public signatureInvalid = false;
  public isScroll = false;
  public tokenEmailArray: any = [];
  public token: any;
  public routeQuery: any;
  public currentForm = "";
  public referenceData: any;
  public saveDataDraft = false;
  public partOneEmailError: any;
  public formTitle = {
    main_title: "",
    application_name: "",
  };
  public isLoading = false;
  public isEligibility = false;
  public mainId = "app";
  public letterOfReferencePayload: any = {
    isEligibility: false,
    letterOfReferenceApplicantInformation: {
      applicantFirstName: "",
      applicantLastName: "",
      applicantType: "",
      howLongYouKnow: "",
      natureYourRelationship: "",
      paidOrVolunteer: "",
      applicantStrengths: "",
      additionalInfoPertinent: "",
    },
    letterOfReferenceReferenceDetails: {
      referenceFirstName: "",
      referenceLastName: "",
      agencyOrOrganization: "",
      title: "",
      credentials: "",
      addressLine1: "",
      addressLine2: "",
      state: "",
      city: "",
      zip: "",
    },
    letterOfReferenceSignatureAndSubmission: {
      isSignature: "",
      submissionDate: moment().format("MM-DD-YYYY"),
    },
  };
  public eventBind: any = {
    acceptNumberOnly: this.numberValidation,
    avoidNumbersAndSpecialChar: this.avoidNumbersAndSpecialCharacter,
    telCharacter: this.telCharacterValidate,
    onlyAlphabets: this.onlyAlphabets,
  };
  public errorMessage = "";
  public checkValidForm = false;
  public softRefersh = "false";

  public async created() {
    this.isLoading = true;
    let payloadStorage: any = localStorage.getItem("letterOfReferencePayload");
    if (payloadStorage) {
      payloadStorage = JSON.parse(payloadStorage);
      this.letterOfReferencePayload = { ...payloadStorage };
    }
    if (this.letterOfReferencePayload.isEligibility) {
      this.isEligibility = true;
      this.formPagination = 1;
    }
    this.routeQuery = this.$route.query;
    this.token = this.routeQuery?.token;
    if (this.token) {
      letterOfReferenceFormApi
        .getRenewalApplicationForm(this.token)
        .then((result) => {
          // Applicant Details From API
          this.applicationDetials =
            result.data.data.certificationPartTwoApplicationForm;
          if (this.applicationDetials) {
            this.letterOfReferencePayload.letterOfReferenceApplicantInformation.applicantFirstName =
              this.applicationDetials.firstName;
            this.letterOfReferencePayload.letterOfReferenceApplicantInformation.applicantLastName =
              this.applicationDetials?.lastName;
          }

          // Refrence Detial from API
          const decodeToken = JSON.parse(atob(this.token.split(".")[1]));
          const emailData = decodeToken.email;

          this.referenceOfInformation =
            result.data.data.certificationPartTwoRefererenceInformation;
          this.referenceOfInformation.map((data: any) => {
            if (data.referenceEmailAddress == emailData) {
              this.referenceData = data;
              this.letterOfReferencePayload.letterOfReferenceReferenceDetails.referenceFirstName =
                this.referenceData.firstName;
              this.letterOfReferencePayload.letterOfReferenceReferenceDetails.referenceLastName =
                this.referenceData.lastName;
              localStorage.setItem(
                "letterOfReferencePayload",
                JSON.stringify(this.letterOfReferencePayload)
              );
            }
          });
        })
        .catch((error) => {
          this.errorMessage = utils.errorMessage(error, true);
          this.isLoading = false;
        });
    }
    this.$apollo
      .query({
        query: gql`query ${LETTER_OF_REFERENCE_QUERY}`,
      })
      .then((result) => {
        this.isLoading = false;
        this.applicationData = result.data.letterOfReferences.data;
        this.formTitle = {
          main_title: this.applicationData[0].attributes.main_title,
          application_name: this.applicationData[0].attributes.application_name,
        };
        this.formData = this.applicationData[0].attributes.form_fields;
      });
  }

  public nextButton() {
    if (this.letterOfReferencePayload.isEligibility) {
      if (this.formPagination < this.formData.length) {
        const page = this.formPagination + 1;
        this.formPagination = page;
      }
      localStorage.setItem(
        "letterOfReferencePayload",
        JSON.stringify(this.letterOfReferencePayload)
      );
    }
  }

  public saveDraft() {
    this.$bvToast.show("example-toast");
    localStorage.setItem(
      "letterOfReferencePayload",
      JSON.stringify(this.letterOfReferencePayload)
    );
    this.saveDataDraft = true;

    setTimeout(() => {
      this.saveDataDraft = false;
    }, 1000);
  }

  public closeToast() {
    this.$bvToast.hide("example-toast");
  }

  public onChangeInput() {
    localStorage.setItem(
      "letterOfReferencePayload",
      JSON.stringify(this.letterOfReferencePayload)
    );
  }

  public previousButton() {
    if (this.formPagination !== 0) {
      const el = document.getElementById(this.mainId);
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
      this.formPagination = this.formPagination - 1;
    }
  }

  public fieldUpdateReference(inputName: string) {
    const nameNotVisiable = [
      "applicantFullName",
      "referenceFullName",
      "fullAddress",
    ];
    return nameNotVisiable.includes(inputName);
  }

  public onSubmit() {
    this.checkValidForm = true;
    this.$validator.validate().then((validate) => {
      if (validate) {
        if (
          this.letterOfReferencePayload.letterOfReferenceSignatureAndSubmission
            .isSignature
        ) {
          this.signatureInvalid = false;
        } else {
          this.signatureInvalid = true;
          setTimeout(() => {
            const el = document.getElementsByClassName("is-invalid")[0];
            if (el) {
              el.scrollIntoView({ behavior: "smooth", block: "end" });
            }
          }, 10);
          return;
        }
        localStorage.setItem(
          "letterOfReferencePayload",
          JSON.stringify(this.letterOfReferencePayload)
        );
        this.isLoading = true;
        let payloadStorage: any = localStorage.getItem(
          "letterOfReferencePayload"
        );
        if (payloadStorage) {
          payloadStorage = JSON.parse(payloadStorage);
          this.letterOfReferencePayload = { ...payloadStorage };
        }
        letterOfReferenceFormApi
          .postRenewalApplicationForm(this.token, this.letterOfReferencePayload)
          .then(() => {
            this.checkValidForm = false;
            this.isLoading = false;
            const someData = {
              title: "Certified Young Adult Peer Support Specialist",
              SubTitle: "Letter Of Reference",
            };
            this.$router.push({
              path: "/form/submitted",
              query: {
                subTitle: someData.SubTitle,
              },
            });
            const el = document.getElementById(this.mainId);
            if (el) {
              el.scrollIntoView({ behavior: "smooth" });
            }
            localStorage.removeItem("letterOfReferencePayload");
          })
          .catch((error) => {
            this.errorMessage = utils.errorMessage(error);
            this.isLoading = false;
          });
      } else {
        const el = document.getElementsByClassName("is-invalid")[0];
        if (el) {
          el.scrollIntoView({ behavior: "smooth", block: "end" });
        }
      }
    });
  }

  public eligibilityChecked(event: any) {
    this.letterOfReferencePayload.isEligibility = event[0];
    this.isEligibility = true;
    this.formPagination = 1;
  }

  public numberValidation(evt: any, length?: number) {
    utils.onlyNumberKey(evt, length);
  }

  public avoidNumbersAndSpecialCharacter(evt: any) {
    utils.avoidNumbersAndSpecialCharacters(evt);
  }

  public telCharacterValidate(evt: any, length?: number) {
    utils.telCharacterValidate(evt, length);
  }

  public onlyAlphabets(evt: any, fieldName = "") {
    utils.onlyAlphabets(evt, fieldName);
  }

  public emptyEvent() {
    return;
  }

  public fieldValidation(
    value: any,
    eventName: string,
    fieldType: string,
    maxLength = 0,
    fieldName = ""
  ) {
    if (fieldType === "number") {
      this.eventBind[eventName](value, maxLength);
    } else if (fieldName === "state") {
      this.eventBind[eventName](value, fieldName);
    } else {
      this.eventBind[eventName](value);
    }
  }

  public validationDisable(groupName = "") {
    this.formData = this.applicationData[0].attributes.form_fields;
    const tempPickList = JSON.parse(JSON.stringify(this.formData)); // It uses of fix the "Cannot assign to read only property 'attributes' of object";
    switch (groupName) {
      case "applicantType":
        if (
          this.letterOfReferencePayload.letterOfReferenceApplicantInformation
            .applicantType === "Professional"
        ) {
          tempPickList.map((data: any) => {
            if (data !== null && data) {
              if (data.parent_slug === "letterOfReferenceReferenceDetails") {
                delete data.fields.formInputs[2][1].validate;
                return tempPickList;
              }
            }
          });
          this.formData = tempPickList;
        } else if (
          this.letterOfReferencePayload.letterOfReferenceApplicantInformation
            .applicantType === "Personal"
        ) {
          tempPickList.map((data: any) => {
            if (data !== null && data) {
              if (data.parent_slug === "letterOfReferenceReferenceDetails") {
                delete data.fields.formInputs[1][0].validate;
                delete data.fields.formInputs[2][0].validate;
                delete data.fields.formInputs[2][1].validate;
                return tempPickList;
              }
            }
          });
          this.formData = tempPickList;
        }
        setTimeout(() => {
          this.softRefersh = this.changeStateForTraining(this.softRefersh);
        }, 10);
        break;
    }
  }

  public changeStateForTraining(value: string) {
    if (value === "false") {
      return "true";
    } else {
      return "false";
    }
  }

  public classNameChooser(inputClass: string) {
    return utils.classNameChooser(inputClass);
  }

  public closdedErrorPopup(event: any) {
    if (event) {
      this.errorMessage = "";
    }
  }
}
