var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"application-pages-main-container"},_vm._l((_vm.applicationPagesData),function(applicationPages,index){return _c('div',{key:index,staticClass:"application-pages-div applications-div"},[(applicationPages.attributes.parent_slug === _vm.param)?[_c('div',{staticClass:"application-pages-container"},[_c('h1',{staticClass:"program-title",domProps:{"innerHTML":_vm._s(applicationPages.attributes.program_title)}}),_c('label',{staticClass:"form_name",domProps:{"innerHTML":_vm._s(applicationPages.attributes.form_name)}}),_c('div',{staticClass:"eligibility_criteria-container"},[_c('div',{staticClass:"content",domProps:{"innerHTML":_vm._s(applicationPages.attributes.eligibility_criteria)}})]),_c('div',{staticClass:"buttons-container"},_vm._l((applicationPages.attributes.buttons),function(button,bIndex){return _c('button',{key:bIndex,staticClass:"router-button",class:button.slug === 'online-form'
                ? 'online-button'
                : button.slug === 'printed-form'
                ? 'printed-button'
                : '',attrs:{"to":button.url},on:{"click":function($event){return _vm.navigateTo(button.url)}}},[_c('p',{staticClass:"button-label",domProps:{"innerHTML":_vm._s(button.label)}}),_c('span',{staticClass:"icon",class:button.slug === 'online-form'
                  ? 'online-icon'
                  : button.slug === 'printed-form'
                  ? 'printed-icon'
                  : ''})])}),0),(applicationPages.attributes.Other_forms.length)?_c('div',{staticClass:"other-forms-container"},_vm._l((applicationPages.attributes
              .Other_forms),function(otherForms,indexJ){return _c('div',{key:indexJ,staticClass:"other-forms"},[_c('h2',{staticClass:"others-form_name",domProps:{"innerHTML":_vm._s(otherForms.heading)}}),(otherForms.description)?_c('p',{staticClass:"description",domProps:{"innerHTML":_vm._s(otherForms.description)}}):_vm._e(),_c('div',{staticClass:"buttons-container"},_vm._l((otherForms.buttons),function(button,bIdx){return _c('button',{key:bIdx,staticClass:"router-button",class:button.slug === 'online-form'
                    ? 'online-button'
                    : button.slug === 'printed-form'
                    ? 'printed-button'
                    : '',attrs:{"to":button.url},on:{"click":function($event){return _vm.navigateTo(button.url)}}},[_c('p',{staticClass:"button-label",domProps:{"innerHTML":_vm._s(button.label)}}),_c('span',{staticClass:"icon",class:button.slug === 'online-form'
                      ? 'online-icon'
                      : button.slug === 'printed-form'
                      ? 'printed-icon'
                      : ''})])}),0)])}),0):_vm._e(),_c('MoreInformation')],1)]:_vm._e()],2)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }