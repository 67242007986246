import {
  STATUS,
  User,
  certificationDetails,
} from "@/models/common-interface/common.interface";
import { ReviewerCertificationApplicationInterface } from "@/models/reviewer-certification-applications/reviewer-certification-applications.interface";
import { certificationFormApi } from "@/providers/apis/certification-form";
import { commonService } from "@/providers/services/common-service";
import { utils } from "@/utils/utils";
import moment from "moment";
import { Component, Prop, Vue } from "vue-property-decorator";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import CertificationPreviewForm from "../certification-preview-form/certification-preview-form.component.vue";
import EmploymentSummariesPreviewComponent from "../employment-summaries-preview/employment-summaries-preview.component.vue";
import LetterOfReferencePreviewComponent from "../letter-of-reference-preview-form/letter-of-reference-preview-form.component.vue";
import ScoreRubicFormComponent from "../score-rubic-form/score-rubic-form.component.vue";
import PDFViewerComponent from "../pdf-viewer/pdf-viewer.component.vue";
import FileUploadComponent from "../file-upload/file-upload.component.vue";
import { appConfigService } from "@/providers/services/app/app-config";
import PreviewPopupComponent from "../preview-popup/preview-popup.component.vue";
import ErrorPopupComponent from "../error-popup/error-popup.component.vue";

@Component({
  name: "reviewer-certification-preview",
  components: {
    AppLoaderComponent,
    ScoreRubicFormComponent,
    CertificationPreviewForm,
    EmploymentSummariesPreviewComponent,
    LetterOfReferencePreviewComponent,
    PDFViewerComponent,
    FileUploadComponent,
    PreviewPopupComponent,
    ErrorPopupComponent,
  },
})
export default class ReviewerCertificationPreview extends Vue {
  @Prop()
  public applicationPreviewForm!: any;

  public certificateApplicationId = "";
  public certificatePreviewForm: ReviewerCertificationApplicationInterface =
    {} as ReviewerCertificationApplicationInterface;
  public isLoading = true;
  public refrenceActiveIndex = 0;
  public isMobileView = false;
  public tabSelected = "application-form";
  public applicationsTabSelect = "certification-basic-details";
  public formsSelect = "application-form";
  public headerHeight = 0;
  public sectionTabHeight = 0;
  public mobileSectionTabHeight = 0;
  public historys: any;
  public toogleStatus = false;
  public approveStatus = "";
  public applicantFullName = "";
  public approveComment = "";
  public userDetails!: User;
  public commentEventNone = false;
  public mostLastRecord: any = null;
  public referenceIndex = 0;
  public isAllApplicationSubmitted = false;
  public isFormEdit = false;
  public isApproveStatus = true;
  public isSubmit = false;
  public showCommentBox = false;
  public showScoringForm = false;
  public showCommentBoxWithDisable = false;
  public showRubricTab = false;
  public isReferenceData = false;
  public formType = "";
  public PDFApplicationPrintName = "";
  public source = "";
  public isUpdatePageNumber = false;
  public editedUserDetails: any[] = [];
  public tooglebutton = false;
  public collapseIdx = "";
  public filesEdit: string[] = [];
  public isFormValid = false;
  public validForm = true;
  public refereshReference: any;
  public isUserApplication = false;
  public isMigrationForm = false;
  public certification = {
    certificateNumber: "",
    certificateFile: [] as any[],
  };
  public checkCertificateValid = false;
  public checkFileValidation = false;
  public showCertification = false;
  public isEditCertificate = false;
  public totalRejectedReferenceCount = 0;
  public isInformedApplicant = false;
  public envType = "";
  public applicationReceivedDate = "";
  public tabScrollMove: any = {};
  public previewData: any[] = [];
  public imgType = ["jpg", "png", "jpeg", "tiff"];
  public showPreviewPopup = false;
  public getCertificatesFilesPathUrl = "";
  public errorMessage = "";
  public supervisorIndex = 0;
  public totalRejectedSupervisorCount = 0;
  public activeSupervisor: any = [];
  public activeReferral: any = [];
  public refereshReferenceAndSupervisor: any;
  public isSupervisorData = false;
  public newlyRejectedReference: any[] = [];
  public newlyRejectedSupervisor: any[] = [];
  public userTaskAllStatus: string[] = [];
  public isReferenceResend = false;
  public isEmpResend = false;
  public updateReviewer: any;

  @Prop()
  public isPeerPreview!: any;
  public editIndex: any = 0;

  public async created() {
    this.getCertificatesFilesPathUrl =
      appConfigService.getS3CertificatesFilesPathUrl();
    this.envType = appConfigService.getEnvironmentType();
    this.formType = this.$route.query.formType?.toString();
    this.userDetails = utils.getUserDetails();
    if (utils.checkIsMobileView() || utils.checkIsTabView()) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    this.certificateApplicationId = this.$route.path.split("/")[3];
    if (this.$route.path.split("/")[1] === "reviewer-dashboard") {
      this.isUserApplication = false;
      this.applicationsTabChange(
        this.applicationsTabSelect,
        "application",
        true
      );
      await this.getCertificationApplicationData();
    } else {
      this.isUserApplication = true;
      await this.getUserApplicationData();
      this.applicationsTabChange(
        this.applicationsTabSelect,
        "application",
        true
      );
    }
    if (this.$route.path.split("/")[2] === "my-application") {
      this.statusChange();
    }
  }

  public getUserApplicationData() {
    this.certificatePreviewForm = this.applicationPreviewForm;
    if (this.applicationPreviewForm.submittedMode === "migration") {
      this.isMigrationForm = true;
    }
    this.applicantFullName = `${this.certificatePreviewForm.data.certificationPartTwoApplicationForm.firstName} ${this.certificatePreviewForm.data.certificationPartTwoApplicationForm.lastName}`;
    this.certificatePreviewForm.data.certificationPartTwoApplicationForm.fullName =
      this.applicantFullName;
    const fullAddress = utils.addressBuilder(
      this.certificatePreviewForm.data,
      "certificationPartTwoApplicationForm",
      "default"
    );
    this.certificatePreviewForm.data.certificationPartTwoApplicationForm.fullAddress =
      fullAddress;

    if (
      this.certificatePreviewForm.data.certificationPartTwoSupervisorInformation
        ?.length
    ) {
      for (
        let idx = 0;
        idx <
        this.certificatePreviewForm.data
          .certificationPartTwoSupervisorInformation.length;
        idx++
      ) {
        this.certificatePreviewForm.data.certificationPartTwoSupervisorInformation[
          idx
        ].supervisorFullName = `${this.certificatePreviewForm.data.certificationPartTwoSupervisorInformation[idx].supervisorFirstName} ${this.certificatePreviewForm.data.certificationPartTwoSupervisorInformation[idx].supervisorLastName}`;
        const supervisorFullAddress = utils.addressBuilder(
          this.certificatePreviewForm.data,
          "certificationPartTwoSupervisorInformation",
          "supervisor",
          true,
          idx
        );
        this.certificatePreviewForm.data.certificationPartTwoSupervisorInformation[
          idx
        ].supervisorFullAddress = supervisorFullAddress;
      }
    }

    this.applicationReceivedDate = this.certificatePreviewForm.data
      .certificationSignature.submissionDate
      ? moment(
          this.certificatePreviewForm.data.certificationSignature.submissionDate
        ).format("MM-DD-YYYY")
      : "-";
    this.isLoading = false;
    const previewClas = document.getElementById(
      "certification-preview-form-container"
    );
    if (previewClas) {
      previewClas.focus();
    }
    setTimeout(() => {
      this.getHeaderHeight();
    }, 1000);
  }

  public getUpdatedData() {
    this.getCertificationApplicationData();
  }

  public getApplicantId() {
    this.isUpdatePageNumber = false;
    if (this.formsSelect === "application-form") {
      this.PDFApplicationPrintName = `Certification-application-${this.certificatePreviewForm.code}`;
    } else if (this.formsSelect === "employment-summary") {
      this.PDFApplicationPrintName = `Employment-summary-application-${this.certificatePreviewForm.code}`;
    } else if (this.formsSelect === "letter-references") {
      this.PDFApplicationPrintName = `Letter-of-references-application-${this.certificatePreviewForm.code}`;
    }
  }

  public getCertificationApplicationData() {
    this.totalRejectedReferenceCount = 0;
    this.totalRejectedSupervisorCount = 0;
    this.editedUserDetails = [];
    this.isLoading = true;
    let getAllEditedData: any[] = [];
    let temp: any;
    certificationFormApi
      .getCertificationApplicationForm(this.certificateApplicationId)
      .then((result: any) => {
        const userId = this.userDetails.userId;
        const userTask = result.data.userTask;
        this.historys = userTask.sort((a: any, b: any) => {
          a.createdAt = moment(a.createdAt).format("MM-DD-YYYY HH:mm:ss:SSS");
          b.createdAt = moment(b.createdAt).format("MM-DD-YYYY HH:mm:ss:SSS");
          return (
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
          );
        });

        //Store all userTask status

        this.userTaskAllStatus = userTask.map((task: any) => {
          return task.status;
        });

        // current userTask and findStatus
        const currentUserTask = userTask.filter(
          (task: any) => task.userId === userId
        );

        const mostRecentHistory = currentUserTask.sort((a: any, b: any) => {
          return moment(a.createdAt, "MM-DD-YYYY HH:mm:ss").diff(
            moment(b.createdAt, "MM-DD-YYYY HH:mm:ss")
          );
        });
        this.mostLastRecord = mostRecentHistory[mostRecentHistory.length - 1];

        this.historys.map((data: any) => {
          data.createdAt = moment
            .utc(data.createdAt)
            .local()
            .format("MM-DD-YYYY HH:mm A");
        });
        this.historys.reverse();
        this.activeReferral = [result.data.referral[0]];
        this.activeSupervisor = [result.data.employeeSummary[0]];
        result.data.createdAt = moment(result.data.createdAt).format(
          "MM-DD-YYYY"
        );
        this.certificatePreviewForm = result.data;
        if (this.certificatePreviewForm.submittedMode === "migration") {
          this.isMigrationForm = true;
        }
        if (
          this.certificatePreviewForm.referralStatus === "COMPLETED" &&
          this.certificatePreviewForm.summaryStatus === "COMPLETED"
        ) {
          this.isAllApplicationSubmitted = true;
        } else {
          this.isAllApplicationSubmitted = false;
        }
        this.applicantFullName = `${this.certificatePreviewForm.data.certificationPartTwoApplicationForm.firstName} ${this.certificatePreviewForm.data.certificationPartTwoApplicationForm.lastName}`;
        this.certificatePreviewForm.data.certificationPartTwoApplicationForm.fullName =
          this.applicantFullName;
        const fullAddress = utils.addressBuilder(
          this.certificatePreviewForm.data,
          "certificationPartTwoApplicationForm",
          "default"
        );
        this.certificatePreviewForm.data.certificationPartTwoApplicationForm.fullAddress =
          fullAddress;
        if (
          this.certificatePreviewForm.data
            .certificationPartTwoRefererenceInformation?.length
        ) {
          this.certificatePreviewForm.data.certificationPartTwoRefererenceInformation.forEach(
            (item: any) => {
              const checkStatus = this.certificatePreviewForm.referral.find(
                (ref: any) => {
                  return ref.email === item.referenceEmailAddress;
                }
              );
              item.status = checkStatus?.status;
            }
          );
        }
        if (
          this.certificatePreviewForm.data
            .certificationPartTwoSupervisorInformation?.length
        ) {
          for (
            let idx = 0;
            idx <
            this.certificatePreviewForm.data
              .certificationPartTwoSupervisorInformation.length;
            idx++
          ) {
            this.certificatePreviewForm.data.certificationPartTwoSupervisorInformation[
              idx
            ].supervisorFullName = `${this.certificatePreviewForm.data.certificationPartTwoSupervisorInformation[idx].supervisorFirstName} ${this.certificatePreviewForm.data.certificationPartTwoSupervisorInformation[idx].supervisorLastName}`;
            const supervisorFullAddress = utils.addressBuilder(
              this.certificatePreviewForm.data,
              "certificationPartTwoSupervisorInformation",
              "supervisor",
              true,
              idx
            );
            this.certificatePreviewForm.data.certificationPartTwoSupervisorInformation[
              idx
            ].supervisorFullAddress = supervisorFullAddress;
            const checkStatus =
              this.certificatePreviewForm.employeeSummary.find((ref: any) => {
                return (
                  ref.email ===
                  this.certificatePreviewForm.data
                    .certificationPartTwoSupervisorInformation[idx]
                    .supervisorEmail
                );
              });
            this.certificatePreviewForm.data.certificationPartTwoSupervisorInformation[
              idx
            ].status = checkStatus?.status;
          }
        }

        this.applicationReceivedDate = this.certificatePreviewForm.data
          .certificationSignature.submissionDate
          ? moment(
              this.certificatePreviewForm.data.certificationSignature
                .submissionDate
            ).format("MM-DD-YYYY")
          : "-";
        this.certificatePreviewForm.data.certificationPartTwoSummaryForm =
          this.certificatePreviewForm.employeeSummary[0]?.data?.data;
        if (
          this.certificatePreviewForm.progressStatus === STATUS.accepted ||
          this.certificatePreviewForm.progressStatus === STATUS.notAccepted ||
          this.certificatePreviewForm.progressStatus === STATUS.needMoreInfo
        ) {
          this.approveComment = this.certificatePreviewForm.comment;
          this.approveStatus = this.certificatePreviewForm.progressStatus;
        }
        this.certification.certificateNumber =
          this.certificatePreviewForm.certificateNumber;
        this.certification.certificateFile = this.certificatePreviewForm
          .certificateFile
          ? [this.certificatePreviewForm.certificateFile]
          : [];

        if (this.formsSelect === "letter-references") {
          this.refrenceClickable(
            this.certificatePreviewForm.data
              .certificationPartTwoRefererenceInformation[
              this.refereshReferenceAndSupervisor
                ? this.refereshReferenceAndSupervisor.idx
                : 0
            ].referenceEmailAddress,
            this.refereshReferenceAndSupervisor
              ? this.refereshReferenceAndSupervisor.idx
              : 0
          );
        }
        if (this.formsSelect === "employment-summary") {
          this.supervisorClickable(
            this.certificatePreviewForm.data
              .certificationPartTwoSupervisorInformation[
              this.refereshReferenceAndSupervisor
                ? this.refereshReferenceAndSupervisor.idx
                : 0
            ].supervisorEmail,
            this.refereshReferenceAndSupervisor
              ? this.refereshReferenceAndSupervisor.idx
              : 0
          );
        }

        //reference rejection count

        this.certificatePreviewForm.referral.find((reference: any) => {
          if (reference.status === STATUS.notAccepted) {
            this.totalRejectedReferenceCount++;
            if (reference.notificationStatus === "NOTIFICATION_PENDING") {
              const referenceDetail = {
                name:
                  reference.data.letterOfReferenceReferenceDetails
                    .referenceFirstName +
                  " " +
                  reference.data.letterOfReferenceReferenceDetails
                    .referenceLastName,
                email: reference.email,
              };
              if (this.newlyRejectedReference.length) {
                const idx = this.newlyRejectedReference.findIndex(
                  (element) => element.email === referenceDetail.email
                );
                if (idx < 0) {
                  this.newlyRejectedReference.push(referenceDetail);
                }
              } else {
                this.newlyRejectedReference = [referenceDetail];
              }
            }
          }
        });

        //supervisor rejection count

        this.certificatePreviewForm.employeeSummary.find((supervisor: any) => {
          if (supervisor.status === STATUS.notAccepted) {
            this.totalRejectedSupervisorCount++;
            if (supervisor.notificationStatus === "NOTIFICATION_PENDING") {
              const supervisorDetail = {
                name:
                  supervisor.data.supervisorContactInformation.firstName +
                  " " +
                  supervisor.data.supervisorContactInformation.lastName,
                email: supervisor.email,
              };
              if (this.newlyRejectedSupervisor.length) {
                const idx = this.newlyRejectedSupervisor.findIndex(
                  (element) => element.email === supervisorDetail.email
                );
                if (idx < 0) {
                  this.newlyRejectedSupervisor.push(supervisorDetail);
                }
              } else {
                this.newlyRejectedSupervisor = [supervisorDetail];
              }
            }
          }
        });

        this.statusCheck();
        setTimeout(() => {
          this.isLoading = false;
          this.getHeaderHeight();
        }, 1000);
        this.isLoading = false;
        for (const property in this.certificatePreviewForm.data) {
          if (this.certificatePreviewForm.data["History"]) {
            this.certificatePreviewForm.data["History"].map(
              (historyData: any) => {
                let certificatePreviewData;
                if (
                  property === "certificationPartTwoRefererenceInformation" ||
                  property === "certificationPartTwoSupervisorInformation"
                ) {
                  certificatePreviewData =
                    this.certificatePreviewForm.data[property][this.editIndex];
                } else {
                  certificatePreviewData =
                    this.certificatePreviewForm.data[property];
                }
                for (const innerProperty in certificatePreviewData) {
                  if (innerProperty === "handbook") {
                    for (const SOAproperty in this.certificatePreviewForm.data[
                      property
                    ][innerProperty]) {
                      for (const SOAinnerProperty in this.certificatePreviewForm
                        .data[property][innerProperty][SOAproperty]) {
                        if (
                          historyData[SOAinnerProperty] &&
                          historyData[SOAinnerProperty].length
                        ) {
                          temp = JSON.parse(
                            JSON.stringify(historyData[SOAinnerProperty])
                          );
                          temp.forEach((element: any) => {
                            if (this.filesEdit.includes(SOAinnerProperty)) {
                              element.oldValue = element.oldValue.map(
                                (data: any) => {
                                  return data.originalFileName;
                                }
                              );
                              element.newValue = element.newValue.map(
                                (data: any) => {
                                  return data.originalFileName;
                                }
                              );
                            }
                            if (
                              typeof element.oldValue === "object" &&
                              typeof element.newValue === "object"
                            ) {
                              element.oldValue = element.oldValue.length
                                ? element.oldValue.join(", ")
                                : element.oldValue;
                              element.newValue = element.newValue.length
                                ? element.newValue.join(", ")
                                : element.newValue;
                            }
                            temp = element;
                            switch (property) {
                              case "certificationDetails":
                                temp.mainLabel = "Certification Questions";
                                break;
                              default:
                                break;
                            }
                            if (getAllEditedData.length) {
                              getAllEditedData.push(temp);
                            } else {
                              getAllEditedData = [temp];
                            }
                          });
                        }
                      }
                    }
                  } else {
                    if (
                      historyData[innerProperty] &&
                      historyData[innerProperty].length
                    ) {
                      temp = JSON.parse(
                        JSON.stringify(historyData[innerProperty])
                      );
                      temp.forEach((element: any) => {
                        if (
                          this.filesEdit.length &&
                          this.filesEdit.includes(innerProperty)
                        ) {
                          element.oldValue = element.oldValue.map(
                            (data: any) => {
                              return data.originalFileName;
                            }
                          );
                          element.newValue = element.newValue.map(
                            (data: any) => {
                              return data.originalFileName;
                            }
                          );
                        }
                        if (
                          typeof element.oldValue === "object" &&
                          typeof element.newValue === "object"
                        ) {
                          element.oldValue = element.oldValue.length
                            ? element.oldValue.join(", ")
                            : element.oldValue;
                          element.newValue = element.newValue.length
                            ? element.newValue.join(", ")
                            : element.newValue;
                        }
                        temp = element;
                        switch (property) {
                          case "certificationPartTwoApplicationForm":
                            temp.mainLabel = "Basic Details";
                            break;
                          case "certificationDetails":
                            temp.mainLabel = "Certification Questions";
                            break;
                          case "certificationPartTwoRefererenceInformation":
                            temp.mainLabel = "Reference Details";
                            break;
                          case "certificationPartTwoSupervisorInformation":
                            temp.mainLabel = "Peer Support Experience";
                            break;
                          default:
                            break;
                        }
                        if (getAllEditedData.length) {
                          getAllEditedData.push(temp);
                        } else {
                          getAllEditedData = [temp];
                        }
                      });
                    }
                  }
                }
              }
            );
          }
        }
        getAllEditedData = getAllEditedData.filter(
          (currentValue, currentIndex) => {
            if (
              (currentValue.mainLabel === "Basic Details" &&
                currentValue.parentName ===
                  "certificationPartTwoApplicationForm") ||
              (currentValue.mainLabel === "Reference Details" &&
                currentValue.parentName ===
                  "certificationPartTwoRefererenceInformation") ||
              ((currentValue.mainLabel === "Peer Support Experience" ||
                currentValue.mainLabel === "Supervisor Details") &&
                currentValue.parentName ===
                  "certificationPartTwoSupervisorInformation")
            ) {
              return currentValue;
            }
          }
        );
        const unique = [
          ...new Set(
            getAllEditedData.map((a) => {
              return a.editDataTime + "/" + a.editorDetails.userId;
            })
          ),
        ];
        unique.forEach((splitUnique: string) => {
          const dateTime = splitUnique.split("/")[0];
          const userId = splitUnique.split("/")[1];
          getAllEditedData.forEach((data: any) => {
            if (
              data.editDataTime === dateTime &&
              data.editorDetails.userId === userId
            ) {
              if (this.editedUserDetails.length) {
                const editedIdx = this.editedUserDetails.findIndex(
                  (editDetail) => {
                    return (
                      editDetail.editorUserId === data.editorDetails.userId &&
                      editDetail.dateTime === data.editDataTime
                    );
                  }
                );
                if (editedIdx > -1) {
                  this.editedUserDetails[editedIdx].changesCount =
                    this.editedUserDetails[editedIdx].changesCount + 1;
                  if (this.editedUserDetails[editedIdx].data.length) {
                    const idx = this.editedUserDetails[
                      editedIdx
                    ].data.findIndex((a: any) => {
                      return a.mainLabel === data.mainLabel;
                    });

                    if (idx > -1) {
                      this.editedUserDetails[editedIdx].data[
                        idx
                      ].changesData.push(data);
                    } else {
                      this.editedUserDetails[editedIdx].data.push({
                        mainLabel: data.mainLabel,
                        changesData: [data],
                      });
                    }
                  }
                } else {
                  this.editedUserDetails.push({
                    editorUserId: data.editorDetails.userId,
                    data: [
                      {
                        mainLabel: data.mainLabel,
                        changesData: [data],
                      },
                    ],
                    dateTime: data.editDataTime,
                    editorName: data.editorDetails.name,
                    changesCount: 1,
                    comment: this.getComment(
                      this.certificatePreviewForm.data,
                      data.editDataTime,
                      data.editorDetails.userId
                    ),
                  });
                }
              } else {
                this.editedUserDetails = [
                  {
                    editorUserId: data.editorDetails.userId,
                    data: [
                      {
                        mainLabel: data.mainLabel,
                        changesData: [data],
                      },
                    ],
                    dateTime: data.editDataTime,
                    editorName: data.editorDetails.name,
                    changesCount: 1,
                    comment: this.getComment(
                      this.certificatePreviewForm.data,
                      data.editDataTime,
                      data.editorDetails.userId
                    ),
                  },
                ];
              }
            }
          });
        });
        this.editedUserDetails = this.editedUserDetails.sort(
          (a: any, b: any) => {
            a.dateTime = moment(a.dateTime).format("MM-DD-YYYY HH:mm:ss:SSS");
            b.dateTime = moment(b.dateTime).format("MM-DD-YYYY HH:mm:ss:SSS");
            return (
              new Date(a.dateTime).getTime() - new Date(b.dateTime).getTime()
            );
          }
        );
        this.editedUserDetails.map((data: any) => {
          data.dateTime = moment(data.dateTime).format(
            "MM-DD-YYYY [|] HH:mm A"
          );
        });
        this.editedUserDetails.reverse();
      })
      .catch((error) => {
        this.errorMessage = utils.errorMessage(error);
        this.isLoading = false;
      });
  }

  public getComment(data: any, dateTime: string, editorId: string) {
    let comment = "";
    for (const property in data) {
      if (property === "editedComments") {
        data[property].filter((data: any) => {
          if (
            data.dateTime === dateTime &&
            data.editorDetails.userId === editorId
          ) {
            comment = data.comment;
          }
        });
      }
    }
    return comment;
  }
  // for mobile preview

  public tabChange(tabSelect: string) {
    this.isFormValid = !this.isFormValid;
    setTimeout(() => {
      if (this.tabSelected !== tabSelect && !this.isFormEdit) {
        this.tabSelected = tabSelect;
        this.getHeaderHeight();
      }
    }, 100);
  }

  public isValidForm(event: any) {
    this.validForm = event;
  }

  public refrenceClickable(refrenceEmail: any, idx: number) {
    this.referenceIndex = idx;
    this.activeReferral = this.certificatePreviewForm.referral.filter(
      (filter) => {
        if (filter.email === refrenceEmail) {
          return filter;
        }
      }
    );
    if (this.activeReferral[0].data) {
      this.isReferenceData = true;
    } else {
      this.isReferenceData = false;
    }
  }

  public applicationsTabChange(
    tabSelect: string,
    formName = "",
    initialLoad = false
  ) {
    this.isFormValid = !this.isFormValid;
    setTimeout(() => {
      if (this.applicationsTabSelect !== tabSelect && this.validForm) {
        this.applicationsTabSelect = tabSelect;
        if (this.formType === "offline") {
          this.isUpdatePageNumber = true;
          switch (formName) {
            case "application":
              this.source =
                "https://dno579h3gzw0n.cloudfront.net/files_upload/ee770a79-a1db-4fee-b1af-ef49c0261c9e.pdf";
              break;
            case "employment":
              this.source =
                "https://dno579h3gzw0n.cloudfront.net/files_upload/c4fdb506-75d5-4143-83c4-9b707736eb75.pdf";
              break;
            default:
              break;
          }
        }
        if (!initialLoad) {
          this.getHeaderHeight();
        }
      }
    }, 50);
  }

  public formsTabChange(tabSelect: string, formName = "") {
    this.tabScrollMove = {};
    const conent = document.getElementsByClassName("ref-lists")[0];
    if (conent) {
      conent.scrollLeft = 0;
    }
    if (!this.isFormEdit) {
      switch (formName) {
        case "application":
          this.applicationsTabChange(
            "certification-basic-details",
            "application"
          );
          break;
        case "employment":
          this.supervisorClickable(
            this.certificatePreviewForm.data
              .certificationPartTwoSupervisorInformation[0].supervisorEmail,
            0
          );
          break;
        case "reference":
          this.refrenceClickable(
            this.certificatePreviewForm.data
              .certificationPartTwoRefererenceInformation[0]
              .referenceEmailAddress,
            0
          );
          break;
        default:
          break;
      }
      this.formsSelect = tabSelect;
      setTimeout(() => {
        this.getHeaderHeight();
      }, 50);
    }
  }

  public getHeaderHeight() {
    const getHeaderElement = document.querySelector(".header");
    const getSectionTabElement = document.querySelector(".form-tab");
    this.headerHeight = getHeaderElement?.clientHeight as number;
    if (this.isMobileView) {
      const getMainHeaderElementHeight = document.querySelector(
        ".mobile-nav-container"
      )?.clientHeight as number;
      this.headerHeight = this.headerHeight + getMainHeaderElementHeight;
      const getMobileElement = document.querySelector(".mobile-section-tab");
      this.mobileSectionTabHeight = getMobileElement?.clientHeight as number;
    }
    this.sectionTabHeight = getSectionTabElement?.clientHeight as number;
    if (this.formsSelect === "employment-summary") {
      const getSummaryTabHeight = document.querySelector(
        ".supervisor-count-tab"
      )?.clientHeight as number;
      this.headerHeight = this.headerHeight + getSummaryTabHeight;
    }
    if (this.formsSelect === "letter-references") {
      const getReferenceTabHeight = document.querySelector(
        ".reference-count-tab"
      )?.clientHeight as number;
      this.headerHeight = this.headerHeight + getReferenceTabHeight;
    }
  }

  public reloadPage(event: any) {
    if (event) {
      this.getCertificationApplicationData();
      if (
        this.formsSelect === "application-form" &&
        this.tabSelected === "scoring-rubric" &&
        this.isMobileView
      ) {
        this.tabSelected = "";
        this.formsSelect = "approve-section";
      }
    }
  }

  public statusUpdate(status: string) {
    this.approveStatus = status;
    this.toogleStatus = !this.toogleStatus;
  }

  public approveSubmit(emitedData: any) {
    this.isSubmit = true;
    if (emitedData || this.approveStatus) {
      if (
        this.approveStatus === "accepted" ||
        (emitedData && emitedData.status === "ACCEPTED") ||
        this.approveStatus === "not accepted" ||
        (emitedData && emitedData.status === "NOT ACCEPTED")
      ) {
        this.isLoading = true;
        this.isApproveStatus = true;
        const data: any = {
          id: this.certificateApplicationId,
          status: emitedData
            ? emitedData.status
            : this.approveStatus.toLocaleUpperCase(),
          comment: emitedData ? emitedData.comment : this.approveComment,
        };
        if (emitedData && emitedData.rubric) {
          data.rubric = emitedData.rubric;
        }
        commonService
          .approveApplication(data, "certificate")
          .then(() => {
            this.isSubmit = false;
            this.getCertificationApplicationData();
          })
          .catch((error) => {
            this.errorMessage = utils.errorMessage(error);
            this.isLoading = false;
          });
      }
    } else {
      this.isApproveStatus = false;
    }
  }

  public isEditForm(event: any) {
    this.isFormEdit = event;
  }

  public referencePageReferesh(event: any) {
    if (event) {
      if (this.formsSelect === "letter-references") {
        this.refereshReference = event;
      }
      this.getCertificationApplicationData();
    }
  }

  public referenceAndSupervisorPageReferesh(event: any) {
    if (event) {
      this.refereshReferenceAndSupervisor = event;
      this.getCertificationApplicationData();
    }
  }

  public statusCheck() {
    const status = utils.scoringSectionHideShow(
      this.certificatePreviewForm.progressStatus,
      this.mostLastRecord.status,
      "certification",
      this.userTaskAllStatus
    );
    this.showCommentBox = status["showCommentBox"];
    this.showRubricTab = status["showRubricTab"];
    this.showCommentBoxWithDisable = status["showCommentBoxWithDisable"];
    this.showCertification = status["showCertification"];
  }

  public onCertificationSubmit() {
    this.checkCertificateValid = true;
    this.$validator.validate().then((validate) => {
      if (validate) {
        this.checkCertificateValid = false;
        this.isEditCertificate = false;
        const certificatePayload: certificationDetails = {
          applicationId: this.certificateApplicationId,
          certificateNumber: this.certification.certificateNumber,
          file: this.certification.certificateFile?.length
            ? this.certification.certificateFile
            : [],
        };
        if (this.certificatePreviewForm.certificateNumber) {
          delete certificatePayload["certificateNumber"];
        }
        this.isLoading = true;
        certificationFormApi
          .certificationUpdate(certificatePayload)
          .then(() => {
            this.getCertificationApplicationData();
          })
          .catch((error) => {
            this.errorMessage = utils.errorMessage(error);
            this.isLoading = false;
          });
      }
    });
  }

  public editCertificate() {
    this.isEditCertificate = true;
  }

  public cancelCertificateEdit() {
    this.isEditCertificate = false;
    this.checkCertificateValid = false;
  }

  public informApplicant(form: string) {
    this.isLoading = true;
    if (form === "reference") {
      const rejectedEmails = this.newlyRejectedReference.map(
        (data) => data.email
      );
      certificationFormApi
        .informRejectedReference(rejectedEmails, this.certificatePreviewForm.id)
        .then(() => {
          this.isInformedApplicant = true;
          this.newlyRejectedReference = [];
          this.getCertificationApplicationData();
          this.$bvModal.hide("rejected-msg-sent-model");
        })
        .catch((error) => {
          this.errorMessage = utils.errorMessage(error);
          this.isLoading = false;
        });
    } else {
      const rejectedEmails = this.newlyRejectedSupervisor.map(
        (data) => data.email
      );
      certificationFormApi
        .informRejectedSupervisor(
          rejectedEmails,
          this.certificatePreviewForm.id
        )
        .then(() => {
          this.isInformedApplicant = true;
          this.newlyRejectedSupervisor = [];
          this.getCertificationApplicationData();
          this.$bvModal.hide("rejected-msg-sent-model");
        })
        .catch((error) => {
          this.errorMessage = utils.errorMessage(error);
          this.isLoading = false;
        });
    }
  }

  public cancelInform() {
    this.$bvModal.hide("rejected-msg-sent-model");
    this.isInformedApplicant = false;
  }

  public rightMoveTab() {
    const conent = document.getElementsByClassName("ref-lists")[0];
    if (conent) {
      this.tabScrollMove = utils.rightMoveTab(conent, 200);
    }
  }
  public leftMoveTab() {
    const conent = document.getElementsByClassName("ref-lists")[0];
    if (conent) {
      this.tabScrollMove = utils.leftMoveTab(conent, 200);
    }
  }

  public async previewCertificate(file: any) {
    if (this.imgType.includes(file.uploadedFileName.split(".")[1])) {
      const img = new Image();
      img.src = `${this.getCertificatesFilesPathUrl}/${file.uploadedFileName}`;
      file.width = img.width;
      file.height = img.height;
    }
    this.previewData = [file];
    this.showPreviewPopup = true;
  }

  public hidePreviewPopup(event: boolean) {
    if (event === true) {
      this.showPreviewPopup = false;
      this.previewData = [];
    }
  }

  public supervisorClickable(supervisorEmail: any, idx: number) {
    this.supervisorIndex = idx;
    this.activeSupervisor = this.certificatePreviewForm.employeeSummary.filter(
      (filter: any) => {
        if (filter.email === supervisorEmail) {
          return filter;
        }
      }
    );
    if (this.activeSupervisor[0].data) {
      this.isSupervisorData = true;
    } else {
      this.isSupervisorData = false;
    }
  }

  public namesJoin(data: any[], joinKey: string) {
    return utils.joinArrObj(data, joinKey);
  }

  public closdedErrorPopup(event: any) {
    if (event) {
      this.errorMessage = "";
    }
  }

  public disableCommentandScoringSection(
    currentUserStatus: string,
    progressStatus: string,
    section: string
  ) {
    return utils.disableCommentandScoringSection(
      currentUserStatus,
      progressStatus,
      section
    );
  }
  public backUrl() {
    if (this.$route.query.isReport) {
      let filter = "";
      if (this.$route.query.trainingcity) {
        filter += `&trainingcity=${this.$route.query.trainingcity}`;
      }
      if (this.$route.query.gender) {
        filter += `&gender=${this.$route.query.gender}`;
      }
      if (this.$route.query.region) {
        filter += `&region=${this.$route.query.region}&regionId=${this.$route.query.regionId}`;
      }
      if (this.$route.query.age) {
        filter += `&age=${this.$route.query.age}&fromAge=${this.$route.query.fromAge}&toAge=${this.$route.query.toAge}`;
      }
      if (this.$route.query.education) {
        filter += `&education=${this.$route.query.education}`;
      }
      return `/reviewer-dashboard/peers-preview?id=${this.$route.query.id}&type=${this.$route.query.type}&isReport=${this.$route.query.isReport}&fromDate=${this.$route.query.fromDate}&toDate=${this.$route.query.toDate}${filter}`;
    } else {
      return this.isPeerPreview
        ? `/reviewer-dashboard/peers-preview?id=${this.$route.query.id}`
        : "/reviewer-dashboard/certification";
    }
  }
  public onResend(activeUser: any, isEmp: any) {
    this.isLoading = true;
    const payload = {
      email: activeUser.email,
      id: activeUser.applicationId,
    };
    if (isEmp) {
      certificationFormApi
        .employeeResendEmail(payload)
        .then(() => {
          this.isLoading = false;
          this.isEmpResend = true;
          this.isReferenceResend = false;
          this.$nextTick(() => {
            this.$bvModal.show("resend-email-center");
          });
        })
        .catch((error) => {
          this.isLoading = false;
        });
    } else {
      certificationFormApi
        .referenceEmail(payload)
        .then(() => {
          this.isLoading = false;
          this.isReferenceResend = true;
          this.isEmpResend = false;
          this.$nextTick(() => {
            this.$bvModal.show("resend-email-center");
          });
        })
        .catch((error) => {
          this.isLoading = false;
        });
    }
  }
  public getEditIndex(event: any) {
    if (event) {
      this.editIndex = event;
    }
  }
  public isUpdateForm(event: any) {
    this.updateReviewer = event;
    this.$nextTick(() => {
      this.$bvModal.show("update-email-center");
    });
  }
  public getPeerUpdatedData(event: boolean) {
    this.$emit("getPeerUpdatedData", true);
    if (event) {
      this.$emit("isUpdateForm", event);
    }
    this.statusChange();
  }
  public closeEmailPopup() {
    this.$bvModal.hide("update-email-center");
  }
  public statusChange() {
    if (
      this.certificatePreviewForm.data
        .certificationPartTwoRefererenceInformation?.length
    ) {
      this.certificatePreviewForm.data.certificationPartTwoRefererenceInformation.forEach(
        (item: any) => {
          const checkStatus = this.certificatePreviewForm.referral.find(
            (ref: any) => {
              return ref.email === item.referenceEmailAddress;
            }
          );
          item.status = checkStatus?.status;
        }
      );
    }
    if (
      this.certificatePreviewForm.data.certificationPartTwoSupervisorInformation
        ?.length
    ) {
      for (
        let idx = 0;
        idx <
        this.certificatePreviewForm.data
          .certificationPartTwoSupervisorInformation.length;
        idx++
      ) {
        const checkStatus = this.certificatePreviewForm.employeeSummary.find(
          (ref: any) => {
            return (
              ref.email ===
              this.certificatePreviewForm.data
                .certificationPartTwoSupervisorInformation[idx].supervisorEmail
            );
          }
        );
        this.certificatePreviewForm.data.certificationPartTwoSupervisorInformation[
          idx
        ].status = checkStatus?.status;
      }
    }
  }
  public emitApproveStatus(emitedData: any) {
    if (
      emitedData.status === "ACCEPTED" ||
      emitedData.status === "NOT ACCEPTED"
    ) {
      this.approveSubmit(emitedData);
    }
  }
}
