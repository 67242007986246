export const APPLICATION_PAGES_QUERY = `ApplicationPages {  
  applicationPages{
      data {
        id
        attributes {
          parent_slug
          program_title
          form_name
          eligibility_criteria
          buttons{
            label
            url
            slug
          }
          Other_forms {
            heading
            description
            buttons{
              label
              url
              slug
            }
          }
        }
      }
    }
  }`;
