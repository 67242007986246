import { Component, Prop, Vue } from "vue-property-decorator";
import { gql } from "graphql-tag";
import RadioButtonComponent from "../radio-button/radio-button.component.vue";
import CheckboxButtonComponent from "../checkbox-button/checkbox-button.component.vue";
import SignatureSubmissionComponent from "../signature-submission/signature-submission.component.vue";
import {
  EmploymentSummariesForm,
  FormFieldContents,
} from "@/models/employment-summary-form/employment-summary-form.interface";
import { EMPLOYMENT_SUMMARY_FORM_QUERY } from "@/graphql/employment-summary-form-query";
import InputTextAreaComponent from "../input-textarea/input-textarea.component.vue";
import { utils } from "@/utils/utils";
import { STATUS } from "@/models/common-interface/common.interface";
import { certificationFormApi } from "@/providers/apis/certification-form";
import FormInputDatepickerComponent from "../form-input-datepicker/form-input-datepicker.component.vue";

@Component({
  name: "employment-summaries-preview",
  components: {
    "b-form-radio-group": RadioButtonComponent,
    "b-form-checkbox": CheckboxButtonComponent,
    "b-form-signature-submission": SignatureSubmissionComponent,
    "b-form-textarea": InputTextAreaComponent,
    "b-form-input-datepicker": FormInputDatepickerComponent,
  },
})
export default class EmploymentSummariesPreviewComponent extends Vue {
  @Prop()
  public previewData: any;
  @Prop()
  public isReviewerPreviewForm!: boolean;
  @Prop()
  public supervisorSelectedIdx: any;
  @Prop()
  public applicationId!: string;
  @Prop()
  public activeSupervisorEmail!: string;
  @Prop()
  public progressStatus!: string;

  public applicationData: Array<EmploymentSummariesForm> = [];
  public formData: Array<FormFieldContents> = [];
  public currentRoute = "";
  public employmentPayLoadData: any;
  public isLoading = false;
  public isMobileView = false;
  public supervisorStatus = "";
  public activeSupervisor: any;
  public storeSupervisorStatus: any[] = [];
  public toogleStatus = false;
  public status: string[] = [STATUS.accepted, STATUS.notAccepted];
  public errorMessage = "";
  public isLargeLoading = false;

  public created() {
    this.isLoading = true;
    if (utils.checkIsMobileView() || utils.checkIsTabView()) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    this.currentRoute = this.$route.path;
    let payloadStorage: any = localStorage.getItem("employmentPayLoadData");
    if (payloadStorage && this.currentRoute.split("/")[1] === "form") {
      payloadStorage = JSON.parse(payloadStorage);
      this.employmentPayLoadData = { ...payloadStorage };
    } else {
      this.employmentPayLoadData = this.previewData;
    }
    this.$apollo
      .query({
        query: gql`query ${EMPLOYMENT_SUMMARY_FORM_QUERY}`,
      })
      .then((result) => {
        this.applicationData = result.data.employmentSummaries.data;
        this.formData = this.applicationData[0].attributes.form_fields;
        this.isLoading = false;
      });
    for (let index = 0; index < this.previewData.length; index++) {
      if (this.storeSupervisorStatus.length) {
        this.storeSupervisorStatus.push({
          index: index,
          email: this.previewData[index].email,
          status: this.previewData[index].status,
        });
      } else {
        this.storeSupervisorStatus = [
          {
            index: index,
            email: this.previewData[index].email,
            status: this.previewData[index].status,
          },
        ];
      }
    }
    this.activeSupervisor = this.previewData.find((data: any) => {
      return data.email === this.activeSupervisorEmail;
    });
    this.fullNameAndAddressBuild();
  }

  fieldUpdate(inputName: string) {
    const nameNotVisiable = [
      "applicantFirstName",
      "applicantLastName",
      "firstName",
      "lastName",
      "addressLine1",
      "addressLine2",
      "state",
      "city",
      "zip",
    ];
    return nameNotVisiable.includes(inputName);
  }

  public fullNameAndAddressBuild() {
    this.activeSupervisor.data.employmentApplicantInformation.applicantFullName = `${this.activeSupervisor.data.employmentApplicantInformation.applicantFirstName} ${this.activeSupervisor.data.employmentApplicantInformation.applicantLastName}`;
    this.activeSupervisor.data.supervisorContactInformation.fullName = `${this.activeSupervisor.data.supervisorContactInformation.firstName} ${this.activeSupervisor.data.supervisorContactInformation.lastName}`;

    const fulladdress = utils.addressBuilder(
      this.activeSupervisor.data,
      "supervisorContactInformation",
      "default"
    );
    this.activeSupervisor.data.supervisorContactInformation.fullAddress =
      fulladdress;
  }

  public async selectStatus(status: string, supervisorEmail: string) {
    this.supervisorStatus = status;
    this.isLargeLoading = true;
    const supervisorPayload = {
      id: this.applicationId,
      email: supervisorEmail,
      status: status,
    };

    this.toogleStatus = !this.toogleStatus;
    await certificationFormApi
      .updateSupervisorStatus(supervisorPayload)
      .then((data) => {
        this.$emit("supervisorUpdated", {
          email: supervisorEmail,
          idx: this.supervisorSelectedIdx,
        });
        this.isLargeLoading = false;
      })
      .catch((error: any) => {
        this.errorMessage = utils.errorMessage(error);
        this.isLargeLoading = false;
      });
  }

  // This method for change status word for class

  public statusWordChange(status: string) {
    return utils.statusCaseChange(status);
  }

  public classNameChooser(inputClass: string) {
    return utils.classNameChooser(inputClass);
  }
}
