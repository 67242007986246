import { Component, Vue } from "vue-property-decorator";
import { gql } from "graphql-tag";
import { TestimonialsData } from "./cyapss-testimonials.interface";
import { CYAPSS_TESTIMONIALS_QUERY } from "@/graphql/cyapss-testimonials-query";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";

@Component({
  components: {
    AppLoaderComponent,
  },
})
export default class CyapssTestimonialsComponent extends Vue {
  public testimonialsData: TestimonialsData[] = [];
  public slide = 0;
  public showModal = false;
  public activetestimonial: any = {};
  public slideIndex = 0;
  public totalLength = 0;
  public isLoading = false;

  public async created() {
    await this.$apollo
      .query({
        query: gql`query ${CYAPSS_TESTIMONIALS_QUERY}`,
      })
      .then((result) => {
        this.testimonialsData = result.data.cyapssTestimonials.data;
        this.totalLength =
          this.testimonialsData[0].attributes.testimonials.length;
      });
  }
  public showPerformancePopUp(index: any) {
    this.isLoading = true;
    this.showModal = true;
    this.slideIndex = index;
    this.$bvModal.show("testimonial-modal-center");
  }

  public carousalCardChange(index: number) {
    this.slide = index;
  }
  public prev() {
    if (
      this.slideIndex <= this.totalLength - 1 &&
      this.slideIndex != 0 &&
      !this.isLoading
    ) {
      this.slideIndex--;
      this.isLoading = true;
    }
  }
  public next() {
    if (this.slideIndex < this.totalLength - 1 && !this.isLoading) {
      this.slideIndex++;
      this.isLoading = true;
    }
  }
  public load(event: any) {
    if (event) {
      this.isLoading = false;
    }
  }

  public closePopup() {
    this.$bvModal.hide("testimonial-modal-center");
    this.slideIndex = 0;
  }
}
