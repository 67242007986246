import { Component, Vue } from "vue-property-decorator";
import { gql } from "graphql-tag";
import { CYAPSS_APPLICATIONS_QUERY } from "@/graphql/cyapss-applications-query";
import { ApplicationsData } from "@/models/cyapss-applications/cyapss-applications.interface";
import { utils } from "@/utils/utils";
import { docLibraryData } from "@/models/common-interface/common.interface";
import PreviewPopupComponent from "../preview-popup/preview-popup.component.vue";

@Component({
  components: {
    PreviewPopupComponent,
  },
})
export default class CyapssApplicationsComponent extends Vue {
  public certificationData: ApplicationsData[] = [];
  public certificationIcon = {
    name: "",
    url: "",
  };
  public getAllDoc: docLibraryData[] = [];
  public showPreviewPopup = false;
  public previewFile: any[] = [];

  public async created() {
    await utils.getAllDoc().then((data) => {
      this.getAllDoc = data;
    });
    await this.$apollo
      .query({
        query: gql`query ${CYAPSS_APPLICATIONS_QUERY}`,
      })
      .then((result) => {
        this.certificationData = result.data.cyapssApplications.data;
      });
  }

  public updated() {
    window.document.onclick = (e) => {
      if (e.target !== null) {
        const getElement: HTMLElement = e.target as HTMLElement;
        const tagId = getElement.id;
        const getDocElement: HTMLAnchorElement = document.getElementById(
          tagId
        ) as HTMLAnchorElement;
        if (getDocElement) {
          const docDetials = utils.filterDoc(this.getAllDoc, tagId);
          if (docDetials) {
            this.showPreviewPopup = true;
            this.previewFile = [
              {
                fileName: "",
                width: 0,
                height: 0,
                type: docDetials.mime,
                originalFileName: docDetials.name,
                url: docDetials.url,
              },
            ];
          }
        }
      }
    };
  }

  public hidePreviewPopup(event: boolean) {
    if (event === true) {
      this.showPreviewPopup = false;
      this.previewFile = [];
    }
  }

  public navigateForm(data: ApplicationsData) {
    if (data.attributes.docKeyName) {
      const docDetials = utils.filterDoc(
        this.getAllDoc,
        data.attributes.docKeyName
      );
      if (docDetials) {
        this.showPreviewPopup = true;
        this.previewFile = [
          {
            fileName: "",
            width: 0,
            height: 0,
            type: docDetials.mime,
            originalFileName: docDetials.name,
            url: docDetials.url,
          },
        ];
      }
    } else {
      this.$router.push(data.attributes.url);
    }
  }
}
