import { utils } from "@/utils/utils";
import gql from "graphql-tag";
import Vue from "vue";
import Component from "vue-class-component";
import { STATUS, User } from "@/models/common-interface/common.interface";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import RadioButtonComponent from "../radio-button/radio-button.component.vue";
import { ADDITIONAL_RENEWAL_SUPERVISOR_FORM_QUERY } from "@/graphql/additional-renewal-supervisor-form-query";
import { renewalFormApi } from "@/providers/apis/renewal-application";
import { additionalSupervisor } from "@/models/renewal-form/renewal-form.interface";
import ErrorPopupComponent from "../error-popup/error-popup.component.vue";

@Component({
  name: "additional-renewal-supervisor",
  components: {
    AppLoaderComponent,
    "b-form-radio-group": RadioButtonComponent,
    ErrorPopupComponent,
  },
})
export default class AdditionalRenewalSupervisorFormComponent extends Vue {
  public isScroll = false;
  public formTitle = {
    program_title: "",
    application_name: "",
  };
  public isLoading = true;
  public eventBind: any = {
    acceptNumberOnly: this.numberValidation,
    avoidNumbersAndSpecialChar: this.avoidNumbersAndSpecialCharacter,
    telCharacter: this.telCharacterValidate,
    onlyAlphabets: this.onlyAlphabets,
  };
  public mainId = "app";
  public applicationData: any = {};
  public supervisorPayLoadData: any[] = [];
  public supervisorEmailDuplicateIndexs: number[] = [];
  public checkValidForm = false;
  public supervisorFormFields: additionalSupervisor[] = [];
  public userDetails: User = {} as User;
  public rejectedEmails: string[] = [];
  public routeQuery: any;
  public token = "";
  public errorMessage = "";
  public alreadySubmittedEmails: string[] = [];
  public alreadySubmittedEmailIdx: number[] = [];
  public supervisorUIUpdate = false;
  public supervisorEndDateShowIdx: number[] = [];
  public supervisorStartDateShowIdx: number[] = [];

  public async created() {
    this.userDetails = utils.getUserDetails();
    this.routeQuery = this.$route.query;
    this.token = this.routeQuery?.token;
    await renewalFormApi
      .getSupervisorLists(this.token)
      .then(async (supervisorLists: any) => {
        supervisorLists.employeeSummaries.map((data: any) => {
          this.alreadySubmittedEmails.push(data.email.toLowerCase());
          if (
            data.status === STATUS.notAccepted &&
            data.notificationStatus === "NOTIFICATION_SENT"
          ) {
            this.rejectedEmails.push(data.email.toLowerCase());
          }
        });
        await this.$apollo
          .query({
            query: gql`query ${ADDITIONAL_RENEWAL_SUPERVISOR_FORM_QUERY}`,
          })
          .then((result) => {
            this.applicationData =
              result.data.additionalRenewalSupervisorForms.data;
            this.formTitle = {
              program_title: this.applicationData[0].attributes.program_title,
              application_name:
                this.applicationData[0].attributes.application_name,
            };
            for (let index = 0; index < this.rejectedEmails.length; index++) {
              if (this.supervisorPayLoadData.length) {
                this.supervisorFormFields.push(
                  this.applicationData[0].attributes.form_fields.formInputs[0]
                );
                this.supervisorPayLoadData.push({
                  supervisorFirstName: "",
                  supervisorLastName: "",
                  supervisorCredentials: "",
                  supervisorTitle: "",
                  supervisorAgencyOrganization: "",
                  supervisorPhone: "",
                  supervisorEmail: "",
                });
              } else {
                this.supervisorPayLoadData = [
                  {
                    supervisorFirstName: "",
                    supervisorLastName: "",
                    supervisorCredentials: "",
                    supervisorTitle: "",
                    supervisorAgencyOrganization: "",
                    supervisorPhone: "",
                    supervisorEmail: "",
                  },
                ];
                this.supervisorFormFields = [
                  this.applicationData[0].attributes.form_fields.formInputs[0],
                ];
              }
            }
            this.isLoading = false;
          });
      })
      .catch((error: any) => {
        this.isLoading = false;
        this.errorMessage = utils.errorMessage(error, true);
      });
  }

  public addAnother() {
    this.supervisorPayLoadData.push({
      supervisorFirstName: "",
      supervisorLastName: "",
      supervisorCredentials: "",
      supervisorTitle: "",
      supervisorAgencyOrganization: "",
      supervisorPhone: "",
      supervisorEmail: "",
    });
    this.supervisorFormFields.push(
      this.applicationData[0].attributes.form_fields.formInputs[0]
    );
  }

  public removeSupervisor(indexForm: number) {
    this.supervisorPayLoadData.splice(indexForm, 1);
    this.supervisorFormFields.splice(indexForm, 1);
  }

  public onSubmit() {
    this.checkValidForm = true;
    this.alreadySubmittedEmailIdx = [];
    this.$validator.validate().then(async (validate) => {
      // Email duplicate validation
      this.supervisorEmailDuplicateIndexs = utils.duplicateValueIdx(
        this.supervisorPayLoadData,
        "",
        true,
        "supervisorEmail"
      );
      if (this.supervisorEmailDuplicateIndexs.length) {
        setTimeout(() => {
          const el = document.getElementsByClassName("is-invalid")[0];
          if (el) {
            el.scrollIntoView({ behavior: "smooth", block: "end" });
          }
        }, 50);
        return;
      }

      // check already submitted email validation
      this.supervisorPayLoadData.map((data, idx) => {
        if (
          this.alreadySubmittedEmails.includes(
            data.supervisorEmail.toLowerCase()
          )
        ) {
          if (!this.alreadySubmittedEmailIdx.includes(idx)) {
            this.alreadySubmittedEmailIdx.push(idx);
          }
        } else {
          if (this.alreadySubmittedEmailIdx.includes(idx)) {
            const position = this.alreadySubmittedEmailIdx.findIndex(
              (data) => data === idx
            );
            this.alreadySubmittedEmailIdx.splice(position);
          }
        }
      });

      const decodeToken = JSON.parse(atob(this.token.split(".")[1]));
      const duplicateEmail = this.supervisorPayLoadData.filter(
        (supervisor: any) => {
          if (
            supervisor.supervisorEmail.toLowerCase() ===
            decodeToken.email.toLowerCase()
          ) {
            return supervisor;
          }
        }
      );
      if (duplicateEmail.length) {
        this.errorMessage =
          "Applicant email id and supervisor email id must be different *";
        return;
      }

      if (
        this.supervisorEmailDuplicateIndexs.length === 0 &&
        validate &&
        this.alreadySubmittedEmailIdx.length === 0
      ) {
        this.isLoading = true;
        for (
          let index = 0;
          index < this.supervisorPayLoadData.length;
          index++
        ) {
          if (this.supervisorPayLoadData[index].isNewAdded) {
            delete this.supervisorPayLoadData[index].isNewAdded;
          }
        }
        renewalFormApi
          .addAdditionalSupervisor(this.supervisorPayLoadData, this.token)
          .then((data: any) => {
            const someData = {
              title: "Certified Young Adult Peer Support Specialist",
              SubTitle: "Additional Renewal Supervisor",
            };
            this.$router.push({
              path: "/form/submitted",
              query: {
                subTitle: someData.SubTitle,
              },
            });
            window.scrollTo(0, 0);
            this.isLoading = false;
          })
          .catch((error: any) => {
            this.errorMessage = utils.errorMessage(error);
            this.isLoading = false;
          });
      } else {
        setTimeout(() => {
          const el = document.getElementsByClassName("is-invalid")[0];
          if (el) {
            el.scrollIntoView({ behavior: "smooth", block: "end" });
          }
        }, 50);
      }
    });
  }

  public numberValidation(evt: any, length?: number) {
    utils.onlyNumberKey(evt, length);
  }

  public avoidNumbersAndSpecialCharacter(evt: any) {
    utils.avoidNumbersAndSpecialCharacters(evt);
  }

  public telCharacterValidate(evt: any, length?: number) {
    utils.telCharacterValidate(evt, length);
  }

  public onlyAlphabets(evt: any, fieldName = "") {
    utils.onlyAlphabets(evt, fieldName);
  }

  public fieldValidation(
    value: any,
    eventName: string,
    fieldType: string,
    maxLength = 0,
    fieldName = ""
  ) {
    if (fieldType === "number") {
      this.eventBind[eventName](value, maxLength);
    } else if (fieldName === "state") {
      this.eventBind[eventName](value, fieldName);
    } else {
      this.eventBind[eventName](value);
    }
  }

  public emptyEvent() {
    return;
  }

  public classNameChooser(inputClass: string) {
    return utils.classNameChooser(inputClass);
  }

  public closdedErrorPopup(event: any) {
    if (event) {
      this.errorMessage = "";
    }
  }
}
