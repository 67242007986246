import { getApplicationDetails } from "@/providers/apis/getApplictionDetails";
import moment from "moment";
import { Component, Vue } from "vue-property-decorator";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import DateRangePicker from "vue2-daterange-picker";
import EmailModalPopupComponent from "../email-modal-popup/email-modal-popup.component.vue";
import {
  OfflineFormData,
  reviewerDataTable,
} from "@/models/common-interface/common.interface";
import { utils } from "@/utils/utils";
import { OFFLINE_FORMS_UPLOAD } from "@/graphql/offline-form-upload-query";
import gql from "graphql-tag";
import FileUploadComponent from "../file-upload/file-upload.component.vue";
import {
  ReviewerRenewalApplicationInterface,
  filters,
} from "@/models/reviewer-renewal-applications/reviewer-renewal-applications.interface";
import ErrorPopupComponent from "../error-popup/error-popup.component.vue";
import {
  FilterStateStorageNames,
  SortingKeyName,
  SortingOrderKey,
  TableSortEvent,
} from "@/models/constants/filters.interface";

@Component({
  name: "reviewer-renewal-lists",
  components: {
    AppLoaderComponent,
    DateRangePicker,
    EmailModalPopupComponent,
    "b-form-upload-file": FileUploadComponent,
    ErrorPopupComponent,
  },
})
export default class ReviewerRenewalLists extends Vue {
  public renewalData: ReviewerRenewalApplicationInterface[] = [];
  public limit = 10;
  public offset = 0;
  public currentPage = 1;
  public totalItem = 0;
  public page = 1;
  public filterDetails: filters = {} as filters;
  public isLoading = true;
  public isDataEmpty = false;
  public toogleStatus = false;
  public sort = SortingKeyName.updatedAt;
  public sortBy = SortingOrderKey.DESC;
  public tableFields = [
    {
      key: "submittedAt",
      label: "Submission Date",
      sortable: false,
      thClass: "application_date pointer-events",
    },
    {
      key: "firstName",
      label: "Applicant First Name",
      sortable: true,
    },
    { key: "lastName", label: "Applicant Last Name", sortable: true },
    {
      key: "email",
      label: "Email Address",
      sortable: true,
      thClass: "email",
      tdClass: "email",
    },
    {
      key: "completionStatus",
      label: "Completion Status",
      sortable: false,
      thClass: "completion-status pointer-events",
      tdClass: "completion-status-td",
    },
    {
      key: "progressStatus",
      label: "Approval Status",
      sortable: false,
      thClass: "application_status pointer-events",
      tdClass: "progress-status",
    },
    {
      key: "action",
      label: "Action",
      sortable: false,
      thClass: "application_action pointer-events",
      tdClass: "actions",
    },
  ];

  public completionStatusSymbol = [
    {
      label: "A",
      key: "isApplicationFormReviewed",
    },
    {
      label: "E",
      key: "isEmploymentSummaryReviewed",
    },
  ];

  public selected: any = [];
  public options = [
    { text: "Received", value: "RECEIVED" },
    { text: "Need More Info", value: "NEED MORE INFO" },
    { text: "Processing", value: "PROCESSING" },
    { text: "Accepted", value: "ACCEPTED" },
    { text: "Not Accepted", value: "NOT ACCEPTED" },
  ];
  public localeDate = {
    format: "yyyy-m-dd",
    applyLabel: "Apply",
    cancelLabel: "Cancel",
    firstDay: 0,
  };
  public dateRange = {
    startDate: "",
    endDate: "",
  };
  public applyStatusFilter: any = [];
  public opens = "right";
  public timePicker = false;
  public startDate: any = "";
  public endDate: any = "";
  public statusFilterData = "";
  public file: any = null;
  public openModal = false;
  public emailData: any = null;
  public dateFilter = "";
  public addApplication = false;
  public addRenewalApplication: any = {
    applicationId: "",
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    applicationDocument: [],
    summaryDocument: [],
  };
  public checkValidation = false;
  public eventBind: any = {
    acceptNumberOnly: this.numberValidation,
    telCharacter: this.telCharacterValidate,
    onlyAlphabets: this.onlyAlphabets,
  };
  public isSubmitted = false;
  public offlineFormData: OfflineFormData[] = [];
  public vaildFile = false;
  public isFileValid = false;
  public notFilesUploadedKeyNames: string[] = [];
  public errorMessage = "";

  public async created() {
    if (this.$route.query.status) {
      const status: string = this.$route.query.status as string;
      this.toogleStatus = !this.toogleStatus;
      this.selected = [status];
      this.onApplyFilter();
    } else {
      const renewalFilter = localStorage.getItem(
        FilterStateStorageNames.RENEWAL
      )
        ? JSON.parse(
            localStorage.getItem(FilterStateStorageNames.RENEWAL) || ""
          )
        : null;
      if (renewalFilter) {
        this.filterDetails = { ...this.filterDetails, ...renewalFilter };
        this.selected = this.filterDetails.status
          ? typeof this.filterDetails.status == "string"
            ? this.filterDetails.status?.split(",")
            : this.filterDetails.status
          : [];
        this.toogleStatus = !this.toogleStatus;
        localStorage.removeItem(FilterStateStorageNames.RENEWAL);
      } else {
        this.selected = this.options
          ?.map((status) => {
            if (!["Not Accepted", "Accepted"]?.includes(status?.text)) {
              return status?.value;
            }
          })
          ?.filter((value) => value !== undefined);
        this.toogleStatus = !this.toogleStatus;
      }
      this.applyExistingFilters();
    }

    this.$apollo
      .query({
        query: gql`query ${OFFLINE_FORMS_UPLOAD}`,
      })
      .then((result) => {
        this.offlineFormData = result.data.offlineFormsUploads.data;
        this.offlineFormData = this.offlineFormData.filter(
          (data) => data.attributes.form_name === "renewal"
        );
      });
  }

  public applyExistingFilters() {
    this.toogleStatus = !this.toogleStatus;
    this.applyStatusFilter = this.selected;
    this.statusFilterData = this.applyStatusFilter.join(",");
    this.limit = this.filterDetails.limit || this.limit;
    this.offset = this.filterDetails.offset || this.offset;
    this.currentPage = (this.offset + this.limit) / 10;
    if (this.filterDetails?.from && this.filterDetails?.to) {
      this.dateRange.startDate = this.filterDetails?.from.toString();
      this.dateRange.endDate = this.filterDetails.to.toString();
      this.startDate = moment(new Date(this.dateRange.startDate)).format(
        "MM-DD-YYYY"
      );
      this.endDate = moment(new Date(this.dateRange.endDate)).format(
        "MM-DD-YYYY"
      );
      this.dateFilter = this.startDate + " - " + this.endDate;
    }
    this.getReviewerRenewalLists();
  }

  public loadContent(page: number) {
    this.page = page;
    this.offset = (this.page - 1) * this.limit;
    this.getReviewerRenewalLists();
  }

  public sortChanged(event: TableSortEvent) {
    if (event) {
      this.offset = 0;
      this.currentPage = 1;
    }
    if (
      event.sortBy &&
      SortingKeyName[event.sortBy as keyof typeof SortingKeyName] !== undefined
    ) {
      this.sort = SortingKeyName[event.sortBy as keyof typeof SortingKeyName];
      this.sortBy = event?.sortDesc
        ? SortingOrderKey.DESC
        : SortingOrderKey.ASC;
    }
    this.getReviewerRenewalLists();
  }

  public onClearFilter() {
    if (this.$route.query.status) {
      this.$router.replace("/reviewer-dashboard/renewal");
    }
    this.offset = 0;
    this.currentPage = 1;
    this.toogleStatus = !this.toogleStatus;
    this.selected = [];
    this.applyStatusFilter = [];
    this.isLoading = true;
    this.statusFilterData = "";
    this.getReviewerRenewalLists();
  }
  public onApplyFilter() {
    this.offset = 0;
    this.currentPage = 1;
    this.toogleStatus = !this.toogleStatus;
    this.applyStatusFilter = this.selected;
    this.statusFilterData = this.applyStatusFilter.join(",");
    this.getReviewerRenewalLists();
  }

  public onClearStatusFilter(isDateFilter = false, index: any) {
    if (this.$route.query.status) {
      this.$router.replace("/reviewer-dashboard/renewal");
    }
    if (isDateFilter) {
      this.dateFilter = "";
      this.startDate = "";
      this.endDate = "";
      this.dateRange.startDate = "";
      this.dateRange.endDate = "";
    } else {
      this.applyStatusFilter.splice(index, 1);
      this.selected = this.applyStatusFilter;
      this.statusFilterData = this.applyStatusFilter;
    }
    this.offset = 0;
    this.currentPage = 1;
    this.getReviewerRenewalLists();
  }

  public onClearAllFilterStatus() {
    if (this.$route.query.status) {
      this.$router.replace("/reviewer-dashboard/renewal");
    }
    this.dateRange.startDate = "";
    this.dateRange.endDate = "";
    this.startDate = "";
    this.endDate = "";
    this.dateFilter = "";
    this.selected = [];
    this.applyStatusFilter = [];
    this.statusFilterData = "";
    this.offset = 0;
    this.currentPage = 1;
    this.filterDetails.firstName = "";
    this.filterDetails.lastName = "";
    this.filterDetails.email = "";
    this.sort = SortingKeyName.updatedAt;
    this.sortBy = SortingOrderKey.DESC;
    this.getReviewerRenewalLists();
  }

  public onOpenModal(row: any) {
    this.emailData = row;
    this.$bvModal.show("email-modal");
  }

  public emailSentStatus(event: boolean) {
    if (event) {
      this.$bvModal.hide("email-modal");
    }
  }

  public mailPopup() {
    this.openModal = true;
  }

  public handleFileSelect(evt: any) {
    const files = evt.target.files; // FileList object
    this.file = files[0];
  }
  public getType(type: string) {
    if (type.includes("pdf" || "doc" || "docx")) {
      return "application";
    } else {
      return "image";
    }
  }

  public updated() {
    window.document.onclick = (event) => {
      if (this.toogleStatus) {
        const getFilterElement =
          document.getElementsByClassName("filter-status")[0];
        if (
          getFilterElement &&
          !getFilterElement.contains(event.target as Node)
        ) {
          this.toogleStatus = !this.toogleStatus;
        }
      }
    };
  }

  public dateChip() {
    if (this.dateRange) {
      this.startDate = moment(new Date(this.dateRange.startDate)).format(
        "MM-DD-YYYY"
      );
      this.endDate = moment(new Date(this.dateRange.endDate)).format(
        "MM-DD-YYYY"
      );
    }
    this.offset = 0;
    this.currentPage = 1;
    this.dateFilter = this.startDate + " - " + this.endDate;
  }

  public getReviewerRenewalLists() {
    this.isLoading = true;
    this.filterDetails.limit = this.limit;
    this.filterDetails.offset = this.offset;
    this.filterDetails.status = this.statusFilterData;
    this.filterDetails.from = this.startDate;
    this.filterDetails.to = this.endDate;
    this.filterDetails.sort = this.sort;
    this.filterDetails.sortBy = this.sortBy;
    getApplicationDetails
      .getRenewalApplicationList(this.filterDetails)
      .then((data) => {
        this.isLoading = false;
        this.renewalData = data.list;
        this.totalItem = data.totalCount;
        if (this.renewalData.length > 0) {
          this.isDataEmpty = false;
        } else {
          this.isDataEmpty = true;
        }
        this.renewalData.forEach(
          (element: ReviewerRenewalApplicationInterface) => {
            element.submittedAt = moment(element.submittedAt).format(
              "MM-DD-YYYY"
            );
            element.completionStatus = {
              isApplicationFormReviewed:
                element.fillStatus === "COMPLETED" ? true : false,
              isEmploymentSummaryReviewed:
                element.summaryStatus === "COMPLETED" ? true : false,
            };
          }
        );
      })
      .catch((error) => {
        this.errorMessage = utils.errorMessage(error);
        this.isLoading = false;
        this.isDataEmpty = true;
      });
  }

  public previewForm(row: any) {
    localStorage.setItem(
      FilterStateStorageNames.RENEWAL,
      JSON.stringify(this.filterDetails)
    );
    this.$router.push({
      path: `renewal/${row.item.id}`,
    });
  }

  public addTrainingForm() {
    this.addApplication = true;
  }

  public numberValidation(evt: Event, length?: number) {
    utils.onlyNumberKey(evt, length);
  }

  public telCharacterValidate(evt: any, length?: number) {
    utils.telCharacterValidate(evt, length);
  }

  public onlyAlphabets(evt: any, fieldName = "") {
    utils.onlyAlphabets(evt, fieldName);
  }

  public emptyEvent() {
    return;
  }

  public fieldValidation(
    value: any,
    eventName: string,
    fieldType: string,
    maxLength = 0,
    fieldName = ""
  ) {
    if (fieldType === "number") {
      this.eventBind[eventName](value, maxLength);
    } else if (fieldName === "state") {
      this.eventBind[eventName](value, fieldName);
    } else {
      this.eventBind[eventName](value);
    }
  }

  public applicationSubmit() {
    this.isFileValid = !this.isFileValid;
    this.checkValidation = true;
    this.$validator.validateAll().then((validate) => {
      if (validate && this.vaildFile && !this.notFilesUploadedKeyNames.length) {
        this.checkValidation = false;
        //need to add API call
        this.addRenewalApplication = {};
        this.isSubmitted = true;
      }
    });
  }

  public fileValid(event: any) {
    this.vaildFile = event;
  }

  public allFilesUploaded(event: any) {
    if (this.notFilesUploadedKeyNames.includes(event)) {
      this.notFilesUploadedKeyNames.splice(event, 1);
    }
  }

  public notFileUploaded(event: string) {
    if (!this.notFilesUploadedKeyNames.includes(event)) {
      this.notFilesUploadedKeyNames.push(event);
    }
  }

  public resetFileKey(event: boolean) {
    if (event) {
      this.notFilesUploadedKeyNames = [];
    }
  }

  public viewApplication() {
    this.$router.push({
      path: `#`,
      query: {
        formType: "offline",
      },
    });
  }

  public formTypeTableRowClass(item: reviewerDataTable, type: string) {
    return utils.formTypeTableRowClass(item, type);
  }

  public closdedErrorPopup(event: any) {
    if (event) {
      this.errorMessage = "";
    }
  }
}
