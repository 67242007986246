import { TRAINING_APPLICATION_FORM_QUERY } from "@/graphql/training-application-form-query";
import { gql } from "graphql-tag";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CheckboxButtonComponent from "../checkbox-button/checkbox-button.component.vue";
import {
  FormFieldContents,
  FormNavbar,
  TrainingForm,
} from "@/models/training-form/training-form.interface";
import InputOtherComponent from "../input-other/input-other.component.vue";
import RadioButtonComponent from "../radio-button/radio-button.component.vue";
import SignatureSubmissionComponent from "../signature-submission/signature-submission.component.vue";
import SingleCheckboxButton from "../single-checkbox-button/single-checkbox-button.component.vue";
import InputTextAreaComponent from "../input-textarea/input-textarea.component.vue";
import { utils } from "@/utils/utils";
import DemographicDataComponent from "../demographic-data/demographic-data.component.vue";
import { trainingFormApi } from "@/providers/apis/training-form";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import DropdownListComponent from "../dropdown-list/dropdown-list.component.vue";
import FileUploadComponent from "../file-upload/file-upload.component.vue";
import InputTagComponent from "../input-tags/input-tags.component.vue";
import { User } from "@/models/common-interface/common.interface";
import EditHistoryComponent from "../edit-history/edit-history.component.vue";
import ErrorPopupComponent from "../error-popup/error-popup.component.vue";
import FormInputDatepickerComponent from "../form-input-datepicker/form-input-datepicker.component.vue";

@Component({
  name: "b-preview-form",
  components: {
    "b-form-radio-group": RadioButtonComponent,
    "b-form-checkbox": CheckboxButtonComponent,
    "b-form-dropdown": DropdownListComponent,
    "b-form-single-checkbox": SingleCheckboxButton,
    "b-form-checkbox-input": InputOtherComponent,
    "b-form-signature-submission": SignatureSubmissionComponent,
    "b-demographic-form": DemographicDataComponent,
    "b-form-textarea": InputTextAreaComponent,
    "b-form-upload-file": FileUploadComponent,
    "b-form-tag-input": InputTagComponent,
    "b-form-input-datepicker": FormInputDatepickerComponent,
    AppLoaderComponent,
    EditHistoryComponent,
    ErrorPopupComponent,
  },
})
export default class TrainingPreviewFormComponent extends Vue {
  @Prop()
  public payloadDataAll: any;
  @Prop()
  public previewData: any;
  @Prop()
  public tabSelect: any;
  @Prop()
  public isReviewerPreviewForm!: boolean;
  @Prop()
  public progressStatus: any;
  @Prop()
  public checkFormValidation!: boolean;
  @Prop()
  public isUserApplication!: boolean;
  @Prop()
  public isMigrationForm!: boolean;
  @Prop()
  public applicantPreviewForm: any;

  public formData: FormFieldContents[] = [];
  public applicationData: Array<TrainingForm> = [];
  public applicationNavData: Array<FormNavbar> = [];
  public limitationList: any;
  public previewPayload: any = {};
  public currentRoute = "";
  public isMobileView = false;
  public isEditReviewerForm = false;
  public checkValidation = false;
  public editIndex!: number;
  public isLargeLoading = false;
  public isLoading = false;
  public eventBind: any = {
    acceptNumberOnly: this.numberValidation,
    avoidNumbersAndSpecialChar: this.avoidNumbersAndSpecialCharacter,
    telCharacter: this.telCharacterValidate,
    onlyAlphabets: this.onlyAlphabets,
  };
  public hideDiv: string[] = [
    "allVersionNames",
    "otherTrainingCity",
    "highSchoolNotListed",
    "cprsCertificate",
    "cfssCertificate",
    "otherCertificate",
  ];
  public checkField: string[] = [
    "trainingCity",
    "travelForThisTraining",
    "highSchool",
    "isOtherName",
    "otherCertifications",
  ];
  public fileData: File[] = [];
  public vaildFile = true;
  public isFileValid = false;
  public notFilesUploadedKeyNames: string[] = [];
  public originalData: any = {};
  public editApplication = false;
  public userDetails: User = {} as User;
  public isClickSavebtn = "false";
  public isConfirm = false;
  public jobVolunteerDuplicateField: string[] = [];
  public errorMessage = "";

  public async created() {
    this.userDetails = utils.getUserDetails();
    this.$validator.extend(
      "tagsMaxLength",
      utils.customValidator.tagsValidation
    );
    this.isLoading = true;
    if (utils.checkIsMobileView() || utils.checkIsTabView()) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    this.currentRoute = this.$route.path;
    let payloadStorage: any = localStorage.getItem("trainingFormPayload");
    if (payloadStorage && this.currentRoute.split("/")[1] === "form") {
      payloadStorage = JSON.parse(payloadStorage);
      this.previewPayload = { ...payloadStorage };
      this.previewPayload.certificationPartOneBasicDetails.fullName = `${payloadStorage.certificationPartOneBasicDetails.firstName} ${payloadStorage.certificationPartOneBasicDetails.lastName}`;
      this.previewPayload.certificationPartOneBasicDetails.preferredFullName = `${payloadStorage.certificationPartOneBasicDetails.preferredFirstName} ${payloadStorage.certificationPartOneBasicDetails.preferredLastName}`;
      this.previewPayload.certificationPartOneBasicDetails.fullAddress =
        utils.addressBuilder(
          this.previewPayload,
          "certificationPartOneBasicDetails",
          "default"
        );
    } else {
      this.previewPayload = this.previewData;
    }
    this.$apollo
      .query({
        query: gql`query ${TRAINING_APPLICATION_FORM_QUERY}`,
      })
      .then((result) => {
        this.applicationData = result.data.trainingApplicationForms.data;
        this.applicationNavData = this.applicationData[0].attributes.navbar;
        this.formData = this.applicationData[0].attributes.form_fields;
        this.limitationList = this.formData.length - 1;
        if (!this.isMigrationForm) {
          for (let index = 0; index < this.checkField.length; index++) {
            this.fieldHideAndShow(this.checkField[index], true);
          }
        }
        this.isLoading = false;
      });
    this.originalData = JSON.parse(JSON.stringify(this.previewPayload));
  }
  editForm(slug: string) {
    this.$emit("applicationSlug", slug);
  }
  onChangeInput() {
    if (!this.isReviewerPreviewForm) {
      localStorage.setItem(
        "trainingFormPayload",
        JSON.stringify(this.previewPayload)
      );
    }
  }
  updatePreview() {
    this.$emit("payloadData", this.previewPayload);
  }

  public fileValid(event: any) {
    this.vaildFile = event;
  }

  public allFilesUploaded(event: any) {
    if (this.notFilesUploadedKeyNames.includes(event)) {
      this.notFilesUploadedKeyNames.splice(event, 1);
    }
  }

  public notFileUploaded(event: string) {
    if (!this.notFilesUploadedKeyNames.includes(event)) {
      this.notFilesUploadedKeyNames.push(event);
    }
  }

  public resetFileKey(event: boolean) {
    if (event) {
      this.notFilesUploadedKeyNames = [];
    }
  }

  public jobVolunteerDuplicate(field: string, isSplice = false) {
    if (!isSplice) {
      if (this.jobVolunteerDuplicateField.length) {
        if (!this.jobVolunteerDuplicateField.includes(field)) {
          this.jobVolunteerDuplicateField.push(field);
        }
      } else {
        this.jobVolunteerDuplicateField = [field];
      }
    } else {
      if (this.jobVolunteerDuplicateField.length) {
        const idx = this.jobVolunteerDuplicateField.findIndex(
          (data: string) => {
            return data === field;
          }
        );
        if (idx > -1) {
          this.jobVolunteerDuplicateField.splice(idx, 1);
        }
      }
    }
  }

  public onCilckSave() {
    this.checkValidation = true;
    this.isFileValid = !this.isFileValid;
    if (
      this.previewPayload.trainingEducationExperience
        .job_or_volunteer_title_1 &&
      this.previewPayload.trainingEducationExperience
        .job_or_volunteer_title_2 &&
      this.previewPayload.trainingEducationExperience
        .job_or_volunteer_title_1 ===
        this.previewPayload.trainingEducationExperience.job_or_volunteer_title_2
    ) {
      this.jobVolunteerDuplicate("job_or_volunteer_title");
    } else {
      this.jobVolunteerDuplicate("job_or_volunteer_title", true);
    }
    if (
      this.previewPayload.trainingEducationExperience
        .job_or_volunteer_agency_1 &&
      this.previewPayload.trainingEducationExperience
        .job_or_volunteer_agency_2 &&
      this.previewPayload.trainingEducationExperience
        .job_or_volunteer_agency_1 ===
        this.previewPayload.trainingEducationExperience
          .job_or_volunteer_agency_2
    ) {
      this.jobVolunteerDuplicate("job_or_volunteer_agency");
    } else {
      this.jobVolunteerDuplicate("job_or_volunteer_agency", true);
    }
    if (
      this.previewPayload.trainingEducationExperience
        .job_or_volunteer_startDate_1 &&
      this.previewPayload.trainingEducationExperience
        .job_or_volunteer_startDate_2 &&
      this.previewPayload.trainingEducationExperience
        .job_or_volunteer_startDate_1 ===
        this.previewPayload.trainingEducationExperience
          .job_or_volunteer_startDate_2
    ) {
      this.jobVolunteerDuplicate("job_or_volunteer_startDate");
    } else {
      this.jobVolunteerDuplicate("job_or_volunteer_startDate", true);
    }
    if (
      this.previewPayload.trainingEducationExperience
        .job_or_volunteer_endDate_1 &&
      this.previewPayload.trainingEducationExperience
        .job_or_volunteer_endDate_2 &&
      this.previewPayload.trainingEducationExperience
        .job_or_volunteer_endDate_1 ===
        this.previewPayload.trainingEducationExperience
          .job_or_volunteer_endDate_2
    ) {
      this.jobVolunteerDuplicate("job_or_volunteer_endDate");
    } else {
      this.jobVolunteerDuplicate("job_or_volunteer_endDate", true);
    }
    this.$validator.validate().then(async (validate) => {
      if (
        validate &&
        this.vaildFile &&
        !this.notFilesUploadedKeyNames.length &&
        !this.jobVolunteerDuplicateField.length
      ) {
        if (
          this.previewPayload.certificationPartOneBasicDetails
            .secondaryEmail === ""
        ) {
          this.previewPayload.certificationPartOneBasicDetails.secondaryEmail =
            null;
        }
        if (this.currentRoute.split("/")[2] === "my-application") {
          const id = this.applicantPreviewForm.id;
          this.isLargeLoading = true;
          trainingFormApi
            .updatePeerReviewerTrainingForm(this.previewPayload, id)
            .then(() => {
              this.editApplication = false;
              this.isLargeLoading = false;
              this.isEditReviewerForm = false;
              const data = {
                isUpdateData: true,
                isUpdateEmail: false,
              };
              this.$emit("getPeerUpdatedData", data);
              this.$emit("isEditForm", false);
            })
            .catch((error: any) => {
              this.errorMessage = utils.errorMessage(error);
              this.isLargeLoading = false;
            });
        } else {
          this.editApplication = true;
          this.isClickSavebtn = this.changeStateForFieldUpdate(
            this.isClickSavebtn
          );
        }
      } else {
        setTimeout(() => {
          const el = document.getElementsByClassName("is-invalid")[0];
          if (el) {
            el.scrollIntoView({ behavior: "smooth", block: "end" });
          }
        }, 10);
      }
    });
  }

  public changeStateForFieldUpdate(value: string) {
    if (value === "false") {
      return "true";
    } else {
      return "false";
    }
  }

  onCilckEdit(index: number) {
    this.editIndex = index;
    this.isEditReviewerForm = true;
    this.$emit("isEditForm", true);
  }

  onCancelEdit() {
    this.notFilesUploadedKeyNames = [];
    this.isEditReviewerForm = false;
    this.editApplication = false;
    if (this.currentRoute.split("/")[2] === "my-application") {
      this.$emit("isEditForm", false);
      this.$emit("getPeerUpdatedData", false);
    } else {
      this.$emit("getUpdatedData");
      this.$emit("isEditForm", false);
    }
  }

  public closeSidebar() {
    this.editApplication = false;
  }

  fieldUpdate(inputName: string) {
    if (!this.isEditReviewerForm && !this.isReviewerPreviewForm) {
      const nameNotVisiable = [
        "firstName",
        "lastName",
        "preferredFirstName",
        "preferredLastName",
        "addressLine1",
        "addressLine2",
        "state",
        "city",
        "zip",
      ];
      return nameNotVisiable.includes(inputName);
    } else {
      const nameNotVisiable = !this.isEditReviewerForm
        ? [
            "firstName",
            "lastName",
            "preferredFirstName",
            "preferredLastName",
            "addressLine1",
            "addressLine2",
            "state",
            "city",
            "zip",
          ]
        : ["fullName", "fullAddress", "preferredFullName"];
      return nameNotVisiable.includes(inputName);
    }
  }

  public numberValidation(evt: any, length?: number) {
    utils.onlyNumberKey(evt, length);
  }

  public avoidNumbersAndSpecialCharacter(evt: any) {
    utils.avoidNumbersAndSpecialCharacters(evt);
  }

  public telCharacterValidate(evt: any, length?: number) {
    utils.telCharacterValidate(evt, length);
  }

  public onlyAlphabets(evt: any, fieldName = "") {
    utils.onlyAlphabets(evt, fieldName);
  }

  public emptyEvent() {
    return;
  }

  public fieldValidation(
    value: any,
    eventName: string,
    fieldType: string,
    maxLength = 0,
    fieldName = ""
  ) {
    if (fieldType === "number") {
      this.eventBind[eventName](value, maxLength);
    } else if (fieldName === "state") {
      this.eventBind[eventName](value, fieldName);
    } else {
      this.eventBind[eventName](value);
    }
  }

  public fieldHideAndShow(groupName = "", initial = false) {
    switch (groupName) {
      case "isOtherName":
        if (
          !this.previewPayload.certificationPartOneBasicDetails.isOtherName ||
          this.previewPayload.certificationPartOneBasicDetails.isOtherName ===
            "No"
        ) {
          if (!this.hideDiv.includes("allVersionNames") && !initial) {
            this.hideDiv.push("allVersionNames");
            this.previewPayload.certificationPartOneBasicDetails.allVersionNames =
              [];
          } else {
            return;
          }
        } else {
          if (this.hideDiv.indexOf("allVersionNames") >= 0) {
            this.hideDiv.splice(this.hideDiv.indexOf("allVersionNames"), 1);
          }
        }
        break;
      case "highSchool":
        if (
          !this.previewPayload.trainingEducationExperience.highSchool.includes(
            "Other"
          )
        ) {
          if (!this.hideDiv.includes("highSchoolNotListed") && !initial) {
            this.hideDiv.push("highSchoolNotListed");
            this.previewPayload.trainingEducationExperience.highSchoolNotListed =
              "";
          } else {
            return;
          }
        } else {
          if (this.hideDiv.indexOf("highSchoolNotListed") >= 0) {
            this.hideDiv.splice(this.hideDiv.indexOf("highSchoolNotListed"), 1);
          }
        }
        break;
      case "otherCertifications":
        if (
          !this.previewPayload.trainingEducationExperience.otherCertifications.includes(
            "CPRS"
          )
        ) {
          if (!this.hideDiv.includes("cprsCertificate") && !initial) {
            this.hideDiv.push("cprsCertificate");
            this.previewPayload.trainingEducationExperience.cprsCertificate =
              [];
          }
        } else {
          if (this.hideDiv.indexOf("cprsCertificate") >= 0) {
            this.hideDiv.splice(this.hideDiv.indexOf("cprsCertificate"), 1);
          }
        }
        if (
          !this.previewPayload.trainingEducationExperience.otherCertifications.includes(
            "CFSS"
          )
        ) {
          if (!this.hideDiv.includes("cfssCertificate") && !initial) {
            this.hideDiv.push("cfssCertificate");
            this.previewPayload.trainingEducationExperience.cfssCertificate =
              [];
          }
        } else {
          if (this.hideDiv.indexOf("cfssCertificate") >= 0) {
            this.hideDiv.splice(this.hideDiv.indexOf("cfssCertificate"), 1);
          }
        }
        if (
          !this.previewPayload.trainingEducationExperience.otherCertifications.includes(
            "Other"
          )
        ) {
          if (!this.hideDiv.includes("otherCertificate") && !initial) {
            this.hideDiv.push("otherCertificate");
            this.previewPayload.trainingEducationExperience.otherCertificate =
              [];
          }
        } else {
          if (this.hideDiv.indexOf("otherCertificate") >= 0) {
            this.hideDiv.splice(this.hideDiv.indexOf("otherCertificate"), 1);
          }
        }
        break;
      case "trainingCity":
        if (
          !this.previewPayload.certificationPartOneBasicDetails.trainingCity.includes(
            "Other"
          )
        ) {
          if (!this.hideDiv.includes("otherTrainingCity") && !initial) {
            this.hideDiv.push("otherTrainingCity");
            this.previewPayload.certificationPartOneBasicDetails.otherTrainingCity =
              "";
          } else {
            return;
          }
        } else {
          if (this.hideDiv.indexOf("otherTrainingCity") >= 0) {
            this.hideDiv.splice(this.hideDiv.indexOf("otherTrainingCity"), 1);
          }
        }
        break;
      default:
        break;
    }
  }

  @Watch("checkFormValidation")
  public validation() {
    this.checkValidation = true;
    this.isFileValid = !this.isFileValid;
    if (
      this.previewPayload.trainingEducationExperience
        .job_or_volunteer_title_1 &&
      this.previewPayload.trainingEducationExperience
        .job_or_volunteer_title_2 &&
      this.previewPayload.trainingEducationExperience
        .job_or_volunteer_title_1 ===
        this.previewPayload.trainingEducationExperience.job_or_volunteer_title_2
    ) {
      this.jobVolunteerDuplicate("job_or_volunteer_title");
    } else {
      this.jobVolunteerDuplicate("job_or_volunteer_title", true);
    }
    if (
      this.previewPayload.trainingEducationExperience
        .job_or_volunteer_agency_1 &&
      this.previewPayload.trainingEducationExperience
        .job_or_volunteer_agency_2 &&
      this.previewPayload.trainingEducationExperience
        .job_or_volunteer_agency_1 ===
        this.previewPayload.trainingEducationExperience
          .job_or_volunteer_agency_2
    ) {
      this.jobVolunteerDuplicate("job_or_volunteer_agency");
    } else {
      this.jobVolunteerDuplicate("job_or_volunteer_agency", true);
    }
    if (
      this.previewPayload.trainingEducationExperience
        .job_or_volunteer_startDate_1 &&
      this.previewPayload.trainingEducationExperience
        .job_or_volunteer_startDate_2 &&
      this.previewPayload.trainingEducationExperience
        .job_or_volunteer_startDate_1 ===
        this.previewPayload.trainingEducationExperience
          .job_or_volunteer_startDate_2
    ) {
      this.jobVolunteerDuplicate("job_or_volunteer_startDate");
    } else {
      this.jobVolunteerDuplicate("job_or_volunteer_startDate", true);
    }
    if (
      this.previewPayload.trainingEducationExperience
        .job_or_volunteer_endDate_1 &&
      this.previewPayload.trainingEducationExperience
        .job_or_volunteer_endDate_2 &&
      this.previewPayload.trainingEducationExperience
        .job_or_volunteer_endDate_1 ===
        this.previewPayload.trainingEducationExperience
          .job_or_volunteer_endDate_2
    ) {
      this.jobVolunteerDuplicate("job_or_volunteer_endDate");
    } else {
      this.jobVolunteerDuplicate("job_or_volunteer_endDate", true);
    }
    this.$validator.validateAll().then((validate: any) => {
      if (
        validate &&
        this.vaildFile &&
        !this.notFilesUploadedKeyNames.length &&
        !this.jobVolunteerDuplicateField.length
      ) {
        this.$emit("isValidForm", true);
      } else {
        const el = document.getElementsByClassName("is-invalid")[0];
        if (el) {
          el.scrollIntoView({ behavior: "smooth", block: "end" });
        }
        this.$emit("isValidForm", false);
      }
    });
  }

  public isRevertedChanges(event: boolean) {
    if (event === true) {
      this.$emit("getUpdatedData");
      this.$emit("isEditForm", false);
    }
  }

  public editedPreviewPayloadData(event: any) {
    if (event) {
      this.previewPayload = event;
    }
  }

  public isConfirmChanges(event: boolean) {
    this.isConfirm = event;
    if (this.isConfirm) {
      const id = this.$route.path.split("/")[3];
      this.isLargeLoading = true;
      this.checkValidation = false;
      this.previewPayload.certificationPartOneBasicDetails.fullName = "";
      this.previewPayload.certificationPartOneBasicDetails.preferredFullName =
        "";
      this.previewPayload.certificationPartOneBasicDetails.fullAddress = "";
      trainingFormApi
        .updateReviewerTrainingForm(this.previewPayload, id)
        .then(() => {
          this.editApplication = false;
          this.isLargeLoading = false;
          this.isEditReviewerForm = false;
          this.$emit("getUpdatedData");
          this.$emit("isEditForm", false);
          this.$emit("isUpdateForm", true);
        })
        .catch((error) => {
          this.errorMessage = utils.errorMessage(error);
          this.isLargeLoading = false;
          this.isLoading = false;
        });
    }
  }

  public closdedErrorPopup(event: any) {
    if (event) {
      this.errorMessage = "";
    }
  }

  public classNameChooser(inputClass: string) {
    return utils.classNameChooser(inputClass);
  }
}
