import { certificationDetails } from "@/models/common-interface/common.interface";
import { http } from "@/providers/http";
import { utils } from "@/utils/utils";
export class CertificationFormApi {
  private static INSTANCE = new CertificationFormApi();

  static get instance() {
    return this.INSTANCE;
  }

  // POST for Certificate form application
  public CertificationApplicationForm(
    fillStatus: string,
    certificatePayLoadData: any
  ) {
    const userDetails = utils.getUserDetails();
    let adminuserDetail: any = localStorage.getItem("admin_user_details");
    if (adminuserDetail) {
      adminuserDetail = JSON.parse(adminuserDetail);
    }
    const data = {
      fillStatus: certificatePayLoadData.fillStatus,
      data: {
        certificationPartTwoApplicationForm:
          certificatePayLoadData.certificationPartTwoApplicationForm,
        certificationDetails: certificatePayLoadData.certificationDetails,
        certificationPartTwoSupervisorInformation:
          certificatePayLoadData.certificationPartTwoSupervisorInformation,
        certificationPartTwoRefererenceInformation:
          certificatePayLoadData.certificationPartTwoRefererenceInformation,
        certificationSignature: certificatePayLoadData.certificationSignature,
      },
      submittedBy:
        userDetails &&
        (!userDetails.roles || userDetails.roles === null) &&
        !localStorage.getItem("impersonateId")
          ? userDetails.userId || userDetails.user_id
          : adminuserDetail && adminuserDetail.userId
          ? adminuserDetail.userId
          : null,
    };
    const endpoint = "cyapss/certificate";
    const headers = http.getTokenHeaders();
    return http.post(endpoint, headers, data).then((response) => {
      return response;
    });
  }

  // GET for Certificate Part Two application using ID

  public getCertificationApplicationForm(applicationID?: string) {
    const endpoint = `cyapss/certificate`;
    const params = {
      id: applicationID,
    };
    const headers = http.getTokenHeaders();
    return http.get(endpoint, headers, params).then((response) => {
      return response;
    });
  }

  // GET for Certificate Part Two application Detials using email

  public getCertificateDetials(email: string) {
    const endpoint = `cyapss/training/details`;
    const params = {
      email: email,
    };
    const headers = http.getTokenHeaders();
    return http.get(endpoint, headers, params).then((response) => {
      return response;
    });
  }

  // PUT for Certificate Part Two application

  public updateCertificationForm(certificatePayLoadData: any, id: string) {
    const userDetails = utils.getUserDetails();
    let adminuserDetail: any = localStorage.getItem("admin_user_details");
    if (adminuserDetail) {
      adminuserDetail = JSON.parse(adminuserDetail);
    }
    const endpoint = `cyapss/certificate?id=${id}`;
    const data = {
      id: id,
      fillStatus: certificatePayLoadData.fillStatus,
      data: {
        certificationPartTwoApplicationForm:
          certificatePayLoadData.certificationPartTwoApplicationForm,
        certificationDetails: certificatePayLoadData.certificationDetails,
        certificationPartTwoSupervisorInformation:
          certificatePayLoadData.certificationPartTwoSupervisorInformation,
        certificationPartTwoRefererenceInformation:
          certificatePayLoadData.certificationPartTwoRefererenceInformation,
        certificationSignature: certificatePayLoadData.certificationSignature,
      },
      submittedBy:
        userDetails &&
        (!userDetails.roles || userDetails.roles === null) &&
        !localStorage.getItem("impersonateId")
          ? userDetails.userId || userDetails.user_id
          : adminuserDetail && adminuserDetail.userId
          ? adminuserDetail.userId
          : null,
    };
    const headers = http.getTokenHeaders();
    return http.put(endpoint, headers, data).then((response) => {
      return response;
    });
  }

  public updateReviewerCertificationForm(
    certificatePayLoadData: any,
    id: string
  ) {
    const endpoint = `cyapss/certificate/application`;

    const payload = {
      id: id,
      fillStatus: certificatePayLoadData.fillStatus,
      data: {
        certificationPartTwoApplicationForm:
          certificatePayLoadData.certificationPartTwoApplicationForm,
        certificationDetails: certificatePayLoadData.certificationDetails,
        certificationPartTwoSupervisorInformation:
          certificatePayLoadData.certificationPartTwoSupervisorInformation,
        certificationPartTwoRefererenceInformation:
          certificatePayLoadData.certificationPartTwoRefererenceInformation,
        certificationSignature: certificatePayLoadData.certificationSignature,
        History: {},
        editedComments: {},
      },
      supervisorEmails: certificatePayLoadData?.supervisorEmails,
      referralEmails: certificatePayLoadData?.referralEmails,
    };
    if (
      certificatePayLoadData.History &&
      certificatePayLoadData.editedComments
    ) {
      payload.data.History = certificatePayLoadData.History;
      payload.data.editedComments = certificatePayLoadData.editedComments;
    }
    const headers = http.getTokenHeaders();
    return http.put(endpoint, headers, payload).then((response) => {
      return response;
    });
  }
  public updatePeerReviewerCertificationForm(
    certificatePayLoadData: any,
    id: string
  ) {
    const endpoint = `cyapss/certificate/applicant/edit`;

    const payload = {
      id: id,
      fillStatus: certificatePayLoadData.fillStatus,
      data: {
        certificationPartTwoApplicationForm:
          certificatePayLoadData.certificationPartTwoApplicationForm,
        certificationDetails: certificatePayLoadData.certificationDetails,
        certificationPartTwoSupervisorInformation:
          certificatePayLoadData.certificationPartTwoSupervisorInformation,
        certificationPartTwoRefererenceInformation:
          certificatePayLoadData.certificationPartTwoRefererenceInformation,
        certificationSignature: certificatePayLoadData.certificationSignature,
        History: {},
        editedComments: {},
      },
      supervisorEmails: certificatePayLoadData?.supervisorEmails,
      referralEmails: certificatePayLoadData?.referralEmails,
    };
    if (
      certificatePayLoadData.History &&
      certificatePayLoadData.editedComments
    ) {
      payload.data.History = certificatePayLoadData.History;
      payload.data.editedComments = certificatePayLoadData.editedComments;
    }
    const headers = http.getTokenHeaders();
    return http.put(endpoint, headers, payload).then((response) => {
      return response;
    });
  }

  public updateReferenceStatus(referencePayLoadData: any) {
    const endpoint = `cyapss/certificate/referral/mail`;
    const data = {
      id: referencePayLoadData.id,
      email: referencePayLoadData.email,
      status: referencePayLoadData.status,
    };
    const headers = http.getTokenHeaders();
    return http.post(endpoint, headers, data).then((response) => {
      return response;
    });
  }

  public informRejectedReference(
    rejectedEmails: string[],
    applicantionId: string
  ) {
    const endpoint = `cyapss/certificate/referral/reject/intimate`;
    const data = {
      emailIds: rejectedEmails,
      applicationId: applicantionId,
    };
    const headers = http.getTokenHeaders();
    return http.put(endpoint, headers, data).then((response) => {
      return response.data;
    });
  }

  public getReferenceLists(token: string) {
    const endpoint = `cyapss/certificate/reference/list`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Basic ${token}`,
    };
    return http.get(endpoint, headers).then((response) => {
      return response.data;
    });
  }

  public addAdditionalReference(additionalReference: any[], token: string) {
    const endpoint = `cyapss/certificate/reference/new`;
    const payLoad = {
      data: additionalReference,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Basic ${token}`,
    };
    return http.post(endpoint, headers, payLoad).then((response) => {
      return response.data;
    });
  }

  public certificationUpdate(certificateDetails: certificationDetails) {
    const endpoint = `cyapss/certificate/number`;
    const formdata = new FormData();
    formdata.append("applicationId", certificateDetails.applicationId);
    if (certificateDetails.certificateNumber) {
      formdata.append(
        "certificateNumber",
        certificateDetails.certificateNumber
      );
    }
    if (certificateDetails.file.length) {
      for (let index = 0; index < certificateDetails.file.length; index++) {
        formdata.append("file", certificateDetails.file[index]);
      }
    } else {
      formdata.append("file", "");
    }
    const headers = http.getTokenHeaders();
    return http.put(endpoint, headers, formdata).then((response) => {
      return response.data;
    });
  }

  public updateSupervisorStatus(supervisorPayLoadData: any) {
    const endpoint = `cyapss/certificate/employee/summary/status`;
    const data = {
      id: supervisorPayLoadData.id,
      email: supervisorPayLoadData.email,
      status: supervisorPayLoadData.status,
    };
    const headers = http.getTokenHeaders();
    return http.post(endpoint, headers, data).then((response) => {
      return response;
    });
  }

  public informRejectedSupervisor(
    rejectedEmails: string[],
    applicantionId: string
  ) {
    const endpoint = `cyapss/certificate/employee/summary/reject/intimate`;
    const data = {
      emailIds: rejectedEmails,
      applicationId: applicantionId,
    };
    const headers = http.getTokenHeaders();
    return http.put(endpoint, headers, data).then(
      (response) => {
        return response.data;
      },
      () => Promise.resolve()
    );
  }

  public getSupervisorLists(token: string) {
    const endpoint = `cyapss/certificate/employee/summary/list`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Basic ${token}`,
    };
    return http.get(endpoint, headers).then((response) => {
      return response.data;
    });
  }

  public addAdditionalSupervisor(additionalSupervisor: any[], token: string) {
    const endpoint = `cyapss/certificate/supervisor`;
    const payLoad = {
      data: additionalSupervisor,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Basic ${token}`,
    };
    return http.put(endpoint, headers, payLoad).then((response) => {
      return response.data;
    });
  }
  public employeeResendEmail(payLoad: any) {
    const endpoint = `cyapss/certificate/employee/summary/email/resend`;
    const headers = http.getTokenHeaders();
    return http.put(endpoint, headers, payLoad).then((response) => {
      return response.data;
    });
  }
  public referenceEmail(payLoad: any) {
    const endpoint = `cyapss/certificate/referral/mail/resend`;
    const headers = http.getTokenHeaders();
    return http.put(endpoint, headers, payLoad).then((response) => {
      return response.data;
    });
  }
}

export const certificationFormApi = CertificationFormApi.instance;
