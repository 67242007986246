export interface User {
  addressLine1?: any;
  addressLine2?: any;
  city: string;
  state: string;
  zip?: any;
  displayName: string;
  email: string;
  firstName: string;
  lastName: string;
  tenantId: string;
  userId: string;
  roles: string[];
  phoneNumber?: any;
  dob?: any;
  thumbnail?: any;
  optIn?: any;
  optInSetting?: any;
}

export interface navList {
  // Array of object Interface
  [index: number]: {
    name: string;
    icon_class: string;
    url: string;
    visible: boolean;
  };
}

export interface attachment {
  files: any[];
  payloadName: string;
}

export interface fileValidation {
  name: string;
  veeValidate: string;
}

export interface scoredData {
  parentSlug: string;
  scored: [
    {
      key: string;
      points: number;
    }
  ];
  scoredPoints: number;
}

export enum STATUS {
  accepted = "ACCEPTED",
  notAccepted = "NOT ACCEPTED",
  completed = "COMPLETED",
  inProgress = "INPROGRESS",
  needMoreInfo = "NEED MORE INFO",
  received = "RECEIVED",
  processing = "PROCESSING",
  waitingForTraining = "WAITING FOR TRAINING",
  completedTraining = "COMPLETED TRAINING",
}

export interface OfflineFormData {
  id: string;
  attributes: formAttrubutes;
}

export interface formAttrubutes {
  parent_slug: string;
  form_fields: {
    formInupts: [formInputs[] | []];
  };
  form_name: string;
}

export interface formInputs {
  name: string;
  class: string;
  mainClass: string;
  field: string;
  label: string;
  propBind: {
    type: string;
    eventName: string;
    maxlength: number;
  };
  validate: {
    name: string;
    required: boolean;
    veeValidate: string;
    maxLength: number;
  };
  fileValid: {
    name: string;
    veeValidate: string;
  };
}

export interface filters {
  limit: number;
  offset: number;
}

export interface reviewerDataTable {
  [x: string]: any;
  certificateNumber?: string;
  certifiedAt?: string;
  submittedMode: string;
  createdAt: string;
  id: string;
  code: string;
  firstName: string;
  lastName: string;
  email: string;
  data: any;
  progressStatus: string;
  fillStatus: string;
  action?: boolean;
  rubric: string;
  comment: string;
  userTask: [
    {
      createdAt: string;
      status: string;
      userId: string;
    }
  ];
}

export interface certificationDetails {
  file: File[];
  certificateNumber?: string;
  applicationId: string;
}

export interface docLibraryData {
  attributes: {
    docKeyName: string;
    documentName: string;
    document: {
      data: {
        attributes: {
          hash: string;
          mime: string;
          name: string;
          provider: string;
          url: string;
        };
      };
    };
  };
}

export interface stateList {
  state_name: string;
  state_name_full_form: string;
}
export interface dateRange {
  startDate: any;
  endDate: any;
}
