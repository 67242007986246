import { http } from "@/providers/http";
import { utils } from "@/utils/utils";
export class TrainingFormApi {
  private static INSTANCE = new TrainingFormApi();

  static get instance() {
    return this.INSTANCE;
  }

  // POST for Training application
  public TrainingApplicationForm(
    fillStatus: string,
    payLoadData: any,
    form = ""
  ) {
    const userDetails = utils.getUserDetails();
    let adminuserDetail: any = localStorage.getItem("admin_user_details");
    if (adminuserDetail) {
      adminuserDetail = JSON.parse(adminuserDetail);
    }
    const data = {
      fillStatus: payLoadData.fillStatus,
      data: {
        certificationPartOneBasicDetails:
          payLoadData.certificationPartOneBasicDetails,
        trainingEducationExperience: payLoadData.trainingEducationExperience,
        trainingProgrammaticEligibilityQuestions:
          payLoadData.trainingProgrammaticEligibilityQuestions,
        trainingJourneyofHeadling: payLoadData.trainingJourneyofHeadling,
        trainingRecoveryNarrative: payLoadData.trainingRecoveryNarrative,
        trainingSignature: payLoadData.trainingSignature,
      },
      submittedBy:
        userDetails &&
        (!userDetails.roles || userDetails.roles === null) &&
        !localStorage.getItem("impersonateId")
          ? userDetails.userId || userDetails.user_id
          : adminuserDetail && adminuserDetail.userId
          ? adminuserDetail.userId
          : null,
    };
    const endpoint = "cyapss/training";
    let headers = {};
    if (form === "training-form") {
      const anonymousToken = localStorage.getItem("anonymousToken");
      headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${anonymousToken}`,
      };
    } else {
      headers = http.getTokenHeaders();
    }
    return http.post(endpoint, headers, data).then((response) => {
      return response;
    });
  }

  // GET for Part One application

  public getTrainingApplicationForm(applicationID?: string, form = "") {
    const endpoint = `cyapss/training`;
    const params = {
      id: applicationID,
    };
    let headers = {};
    if (form === "training-form") {
      const anonymousToken = localStorage.getItem("anonymousToken");
      headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${anonymousToken}`,
      };
    } else {
      headers = http.getTokenHeaders();
    }
    return http.get(endpoint, headers, params).then((response) => {
      return response;
    });
  }

  // PUT for Part One application

  public updateTrainingForm(payLoadData: any, id: string, form = "") {
    const userDetails = utils.getUserDetails();
    let adminuserDetail: any = localStorage.getItem("admin_user_details");
    if (adminuserDetail) {
      adminuserDetail = JSON.parse(adminuserDetail);
    }
    const endpoint = `cyapss/training?id=${id}`;
    const payload = {
      id: id,
      fillStatus: payLoadData.fillStatus,
      data: {
        certificationPartOneBasicDetails:
          payLoadData.certificationPartOneBasicDetails,
        trainingEducationExperience: payLoadData.trainingEducationExperience,
        trainingProgrammaticEligibilityQuestions:
          payLoadData.trainingProgrammaticEligibilityQuestions,
        trainingJourneyofHeadling: payLoadData.trainingJourneyofHeadling,
        trainingRecoveryNarrative: payLoadData.trainingRecoveryNarrative,
        trainingSignature: payLoadData.trainingSignature,
      },
      submittedBy:
        userDetails &&
        (!userDetails.roles || userDetails.roles === null) &&
        !localStorage.getItem("impersonateId")
          ? userDetails.userId || userDetails.user_id
          : adminuserDetail && adminuserDetail.userId
          ? adminuserDetail.userId
          : null,
    };

    let headers = {};
    if (form === "training-form") {
      const anonymousToken = localStorage.getItem("anonymousToken");
      headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${anonymousToken}`,
      };
    } else {
      headers = http.getTokenHeaders();
    }
    return http.put(endpoint, headers, payload).then((response) => {
      return response;
    });
  }

  public updateReviewerTrainingForm(payLoadData: any, id: string) {
    const endpoint = `cyapss/training/application`;

    const payload = {
      id: id,
      fillStatus: payLoadData.fillStatus,
      data: {
        certificationPartOneBasicDetails:
          payLoadData.certificationPartOneBasicDetails,
        trainingEducationExperience: payLoadData.trainingEducationExperience,
        trainingProgrammaticEligibilityQuestions:
          payLoadData.trainingProgrammaticEligibilityQuestions,
        trainingJourneyofHeadling: payLoadData.trainingJourneyofHeadling,
        trainingRecoveryNarrative: payLoadData.trainingRecoveryNarrative,
        trainingSignature: payLoadData.trainingSignature,
        History: {},
        editedComments: {},
      },
    };
    if (payLoadData.History && payLoadData.editedComments) {
      payload.data.History = payLoadData.History;
      payload.data.editedComments = payLoadData.editedComments;
    }
    const headers = http.getTokenHeaders();
    return http.put(endpoint, headers, payload).then((response) => {
      return response;
    });
  }
  public updatePeerReviewerTrainingForm(payLoadData: any, id: string) {
    const endpoint = `cyapss/training/applicant/edit`;

    const payload = {
      id: id,
      fillStatus: payLoadData.fillStatus,
      data: {
        certificationPartOneBasicDetails:
          payLoadData.certificationPartOneBasicDetails,
        trainingEducationExperience: payLoadData.trainingEducationExperience,
        trainingProgrammaticEligibilityQuestions:
          payLoadData.trainingProgrammaticEligibilityQuestions,
        trainingJourneyofHeadling: payLoadData.trainingJourneyofHeadling,
        trainingRecoveryNarrative: payLoadData.trainingRecoveryNarrative,
        trainingSignature: payLoadData.trainingSignature,
        History: {},
        editedComments: {},
      },
    };
    if (payLoadData.History && payLoadData.editedComments) {
      payload.data.History = payLoadData.History;
      payload.data.editedComments = payLoadData.editedComments;
    }
    const headers = http.getTokenHeaders();
    return http.put(endpoint, headers, payload).then((response) => {
      return response;
    });
  }
}

export const trainingFormApi = TrainingFormApi.instance;
