import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { gql } from "graphql-tag";
import RadioButtonComponent from "../radio-button/radio-button.component.vue";
import CheckboxButtonComponent from "../checkbox-button/checkbox-button.component.vue";
import SingleCheckboxButton from "../single-checkbox-button/single-checkbox-button.component.vue";
import InputOtherComponent from "../input-other/input-other.component.vue";
import SignatureSubmissionComponent from "../signature-submission/signature-submission.component.vue";
import { utils } from "@/utils/utils";
import InputTextAreaComponent from "../input-textarea/input-textarea.component.vue";
import {
  CertificationForm,
  FormNavbar,
  FormFieldContents,
  formtInputData,
  MultiFormData,
} from "@/models/certification-form/certification-form.interface";
import { CERTIFICATION_APPLICATION_FORM_QUERY } from "@/graphql/certification-application-form-query";
import { certificationFormApi } from "@/providers/apis/certification-form";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import ScopeOfActivity from "../scope-of-activity/scope-of-activity-component.vue";
import { User } from "@/models/common-interface/common.interface";
import EditHistoryComponent from "../edit-history/edit-history.component.vue";
import ErrorPopupComponent from "../error-popup/error-popup.component.vue";
import FormInputDatepickerComponent from "../form-input-datepicker/form-input-datepicker.component.vue";

@Component({
  name: "certification-preview-form",
  components: {
    "b-form-radio-group": RadioButtonComponent,
    "b-form-checkbox": CheckboxButtonComponent,
    "b-form-single-checkbox": SingleCheckboxButton,
    "b-form-checkbox-input": InputOtherComponent,
    "b-form-signature-submission": SignatureSubmissionComponent,
    "b-form-textarea": InputTextAreaComponent,
    "code-of-ethics-and-scope-of-activity": ScopeOfActivity,
    "b-form-input-datepicker": FormInputDatepickerComponent,
    AppLoaderComponent,
    EditHistoryComponent,
    ErrorPopupComponent,
  },
})
export default class CertificationPreviewForm extends Vue {
  @Prop()
  public previewData: any;
  @Prop()
  public tabSelect!: string;
  @Prop()
  public isReviewerPreviewForm!: string;
  @Prop()
  public referenceData!: any[];
  @Prop()
  public supervisorData!: any[];
  @Prop()
  public applicationId!: string;
  @Prop()
  public progressStatus: any;
  @Prop()
  public checkFormValidation!: boolean;
  @Prop()
  public isUserApplication!: boolean;
  @Prop()
  public isMigrationForm!: boolean;

  public applicationData: Array<CertificationForm> = [];
  public applicationNavData: Array<FormNavbar> = [];
  public formData: Array<FormFieldContents> = [];
  public limitationList: any;
  public previewPayload: any = {};
  public currentRoute = "";
  public referenceIndex = 0;
  public isMobileView = false;
  public statusName = "";
  public toogleStatus = false;
  public buildReferenceObjcet: any = {};
  public storeReferenceEmail: string[] = [];
  public isEditReviewerForm = false;
  public disabled = true;
  public isLoading = false;
  public referenceToogle = false;
  public checkValidation = false;
  public editIndex!: number;
  public isLargeLoading = false;
  public eventBind: any = {
    acceptNumberOnly: this.numberValidation,
    avoidNumbersAndSpecialChar: this.avoidNumbersAndSpecialCharacter,
    telCharacter: this.telCharacterValidate,
    onlyAlphabets: this.onlyAlphabets,
  };
  public hideDiv: string[] = ["endDate"];
  public checkField: string[] = ["stillEmployed"];
  public originalData: any = {};
  public editApplication = false;
  public filesEdit: string[] = [];
  public userDetails: User = {} as User;
  public isClickSavebtn = "false";
  public isConfirm = false;
  public referenceList: any[] = [];
  public errorMessage = "";
  public supervisorEndDateShowIdx: number[] = [];
  public supervisorStartDateShowIdx: number[] = [];
  public supervisorUIUpdate = false;
  public supervisorFormFields: formtInputData[] = [];
  public tempSupervisorFormFields: formtInputData = {} as formtInputData;
  public supervisorList: any[] = [];
  public addReferenceForm: MultiFormData[] = [];
  public emptyValue = utils.emptyValues;
  public isEditReference = false;

  public created() {
    this.userDetails = utils.getUserDetails();
    this.isLoading = true;
    if (utils.checkIsMobileView() || utils.checkIsTabView()) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    this.currentRoute = this.$route.path;
    let payloadStorage: any = localStorage.getItem("certificationFormPayload");
    if (payloadStorage && this.currentRoute.split("/")[1] === "form") {
      payloadStorage = JSON.parse(payloadStorage);
      this.previewPayload = { ...payloadStorage };
      this.fullNameAndAddressBuild(this.previewPayload);
    } else {
      this.previewPayload = this.previewData;
      this.fullNameAndAddressBuild(this.previewPayload);
    }
    this.$apollo
      .query({
        query: gql`query ${CERTIFICATION_APPLICATION_FORM_QUERY}`,
      })
      .then((result) => {
        this.applicationData = result.data.certificationApplicationForms.data;
        this.applicationNavData = this.applicationData[0].attributes.navbar;
        this.formData = this.applicationData[0].attributes.form_fields;
        if (this.previewPayload.certificationPartTwoSupervisorInformation) {
          this.showReferenceForm("certificationPartTwoSupervisorInformation");
          for (let index = 0; index < this.checkField.length; index++) {
            for (
              let i = 0;
              i <
              this.previewPayload.certificationPartTwoSupervisorInformation
                .length;
              i++
            ) {
              this.fieldUpdate(this.checkField[index], i);
            }
          }
          const supervisorEmail: any[] = [];
          this.supervisorData &&
            this.supervisorData.forEach((data: any) => {
              supervisorEmail.push(data.email);
            });
          if (
            Array.isArray(
              this.previewPayload.certificationPartTwoSupervisorInformation
            )
          ) {
            this.previewPayload.certificationPartTwoSupervisorInformation.map(
              (supervisor: any) => {
                const refIdx = supervisorEmail.findIndex(
                  (supervisorEmail: string) => {
                    return supervisorEmail === supervisor.supervisorEmail;
                  }
                );
                if (refIdx > -1) {
                  this.supervisorList.push(this.supervisorData[refIdx]);
                }
              }
            );
          }
        }
        if (this.previewPayload.certificationPartTwoRefererenceInformation) {
          this.showReferenceForm("certificationPartTwoRefererenceInformation");
          const referenceEmail: any[] = [];
          this.referenceData &&
            this.referenceData.forEach((data: any) => {
              referenceEmail.push(data.email);
            });
          this.previewPayload.certificationPartTwoRefererenceInformation.map(
            (reference: any) => {
              const refIdx = referenceEmail.findIndex((refEmail: string) => {
                return refEmail === reference.referenceEmailAddress;
              });
              if (refIdx > -1) {
                this.referenceList.push(this.referenceData[refIdx]);
              }
            }
          );
        }
        this.limitationList = this.formData.length - 1;
        this.isLoading = false;
      });
    this.originalData = JSON.parse(JSON.stringify(this.previewPayload));
  }

  public showReferenceForm(formName: string) {
    const formData = this.previewPayload[formName];
    if (formData.length) {
      if (formName === "certificationPartTwoRefererenceInformation") {
        this.addReferenceForm = [];
      }
      if (formName === "certificationPartTwoSupervisorInformation") {
        this.supervisorFormFields = [];
      }
      formData.forEach(() => {
        if (formName === "certificationPartTwoRefererenceInformation") {
          this.addReferenceForm.push(this.formData[3].fields.multiForm[0]);
        }
        if (formName === "certificationPartTwoSupervisorInformation") {
          this.supervisorFormFields.push(
            this.applicationData[0].attributes.form_fields[2].fields
              .formInputs[0]
          );
        }
      });
    }
  }

  public editForm(slug: string) {
    this.$emit("applicationSlug", slug);
  }

  public references(index: number) {
    this.referenceIndex = index;
  }

  public referenceStatusUpdate(referenceEmail: string, status: string) {
    this.buildReferenceObjcet = Object.assign(
      { [referenceEmail]: status },
      this.buildReferenceObjcet
    );
    this.storeReferenceEmail = Object.keys(this.buildReferenceObjcet);
  }

  public onCilckSave() {
    this.checkValidation = true;
    const id = this.$route.path.split("/")[3];
    if (
      this.previewPayload.certificationPartTwoRefererenceInformation &&
      this.previewPayload.certificationPartTwoRefererenceInformation.length
    ) {
      const referenceData: { oldEmail: any; newEmail: any }[] = [];
      if (
        this.previewPayload.certificationPartTwoRefererenceInformation[
          this.editIndex
        ]?.referenceEmailAddress !==
        this.originalData.certificationPartTwoRefererenceInformation[
          this.editIndex
        ]?.referenceEmailAddress
      ) {
        const data = {
          oldEmail:
            this.originalData.certificationPartTwoRefererenceInformation[
              this.editIndex
            ].referenceEmailAddress,
          newEmail:
            this.previewPayload.certificationPartTwoRefererenceInformation[
              this.editIndex
            ].referenceEmailAddress,
        };
        referenceData.push(data);
      }
      if (referenceData && referenceData.length) {
        this.previewPayload.referralEmails = referenceData;
      }
    }
    if (
      this.previewPayload.certificationPartTwoSupervisorInformation &&
      this.previewPayload.certificationPartTwoSupervisorInformation.length
    ) {
      const supervisorEmails = [];
      if (
        this.previewPayload.certificationPartTwoSupervisorInformation[
          this.editIndex
        ]?.supervisorEmail !==
        this.originalData.certificationPartTwoSupervisorInformation[
          this.editIndex
        ]?.supervisorEmail
      ) {
        const data = {
          oldEmail:
            this.originalData.certificationPartTwoSupervisorInformation[
              this.editIndex
            ].supervisorEmail,
          newEmail:
            this.previewPayload.certificationPartTwoSupervisorInformation[
              this.editIndex
            ].supervisorEmail,
        };
        supervisorEmails.push(data);
      }
      if (supervisorEmails && supervisorEmails.length) {
        this.previewPayload.supervisorEmails = supervisorEmails;
      }
    }
    this.$validator.validateAll().then((validate) => {
      if (validate) {
        const duplicateSupervisorEmail =
          this.previewPayload.certificationPartTwoSupervisorInformation.filter(
            (supervisor: any) => {
              if (
                supervisor.supervisorEmail.toLowerCase() ===
                this.previewPayload.certificationPartTwoApplicationForm.email.toLowerCase()
              ) {
                return supervisor;
              }
            }
          );
        if (duplicateSupervisorEmail.length) {
          this.errorMessage =
            "Applicant email id and supervisor email id must be different *";
          return;
        }

        const duplicateEmail =
          this.previewPayload.certificationPartTwoRefererenceInformation.filter(
            (reference: any) => {
              if (
                reference.referenceEmailAddress ===
                this.previewPayload.certificationPartTwoApplicationForm.email
              ) {
                return reference;
              }
            }
          );
        if (duplicateEmail.length) {
          this.errorMessage =
            "Applicant email id and reference email id must be different *";
          return;
        }
        const referenceDuplicateEmail =
          this.previewPayload.certificationPartTwoRefererenceInformation.filter(
            (reference: any) => {
              return this.previewPayload.certificationPartTwoSupervisorInformation.find(
                (supervisor: any) => {
                  return (
                    supervisor.supervisorEmail.toLowerCase() ===
                    reference.referenceEmailAddress.toLowerCase()
                  );
                }
              );
            }
          );
        if (referenceDuplicateEmail.length) {
          this.errorMessage =
            "Supervisor email id and reference email id must be different *";
          return;
        }
        if (
          this.tabSelect === "certification-references" ||
          this.tabSelect === "certification-supervisor-details"
        ) {
          const originalData =
            this.tabSelect === "certification-references"
              ? this.originalData.certificationPartTwoRefererenceInformation
              : this.originalData.certificationPartTwoSupervisorInformation;

          const previewPayload =
            this.tabSelect === "certification-references"
              ? this.previewPayload.certificationPartTwoRefererenceInformation
              : this.previewPayload.certificationPartTwoSupervisorInformation;
          const duplicateReferenceEmail = originalData.filter(
            (reference: any) => {
              if (this.tabSelect === "certification-references") {
                if (
                  this.previewPayload &&
                  this.previewPayload.referralEmails &&
                  this.previewPayload.referralEmails[0] &&
                  reference.referenceEmailAddress.toLowerCase() ===
                    this.previewPayload.referralEmails[0].newEmail.toLowerCase()
                ) {
                  return reference;
                }
              } else {
                if (
                  this.previewPayload &&
                  this.previewPayload.supervisorEmails &&
                  this.previewPayload.supervisorEmails[0] &&
                  reference.supervisorEmail.toLowerCase() ===
                    this.previewPayload.supervisorEmails[0].newEmail.toLowerCase()
                ) {
                  return reference;
                }
              }
            }
          );
          if (duplicateReferenceEmail.length) {
            const text =
              this.tabSelect === "certification-references"
                ? "Reference"
                : "Supervisor";
            this.errorMessage = `${text} email id must be different *`;
            return;
          }
        }
        if (this.currentRoute.split("/")[2] === "my-application") {
          const id = this.applicationId;
          this.isLargeLoading = true;
          certificationFormApi
            .updatePeerReviewerCertificationForm(this.previewPayload, id)
            .then(() => {
              this.isLargeLoading = false;
              this.isEditReviewerForm = false;
              this.disabled = true;
              if (
                (this.previewPayload.referralEmails &&
                  this.previewPayload.referralEmails.length) ||
                (this.previewPayload.supervisorEmails &&
                  this.previewPayload.supervisorEmails.length)
              ) {
                let type;
                let email;
                if (
                  this.previewPayload.referralEmails &&
                  this.previewPayload.referralEmails.length
                ) {
                  type = "Reference";
                  email = this.previewPayload.referralEmails[0].newEmail;
                }
                if (
                  this.previewPayload.supervisorEmails &&
                  this.previewPayload.supervisorEmails.length
                ) {
                  type = "Supervisor";
                  email = this.previewPayload.supervisorEmails[0].newEmail;
                }
                const data = {
                  isUpdateData: false,
                  isUpdateEmail: true,
                  type: type,
                  email: email,
                };
                this.$emit("getPeerUpdatedData", data);
              } else {
                const data = {
                  isUpdateData: true,
                  isUpdateEmail: false,
                };
                this.$emit("getPeerUpdatedData", data);
              }
              this.$emit("isEditForm", false);
              this.$emit("editIndex", this.editIndex);
              this.previewPayload.referralEmails = [];
              this.previewPayload.supervisorEmails = [];
            })
            .catch((error: any) => {
              this.errorMessage = utils.errorMessage(error);
              this.isLoading = false;
            });
        } else {
          this.editApplication = true;
          this.isClickSavebtn = this.changeStateForFieldUpdate(
            this.isClickSavebtn
          );
        }
      } else {
        const el = document.getElementsByClassName("is-invalid")[0];
        if (el) {
          el.scrollIntoView({ behavior: "smooth" });
        }
      }
    });
  }

  public changeStateForFieldUpdate(value: string) {
    if (value === "false") {
      return "true";
    } else {
      return "false";
    }
  }
  onCancelEdit() {
    this.isEditReviewerForm = false;
    this.disabled = true;
    this.editApplication = false;
    if (this.currentRoute.split("/")[2] === "my-application") {
      this.$emit("getPeerUpdatedData", false);
      this.$emit("isEditForm", false);
    } else {
      this.$emit("getUpdatedData");
      this.$emit("isEditForm", false);
    }
  }

  public fieldShowAndHide(inputName: string, parentSlug = "") {
    if (!this.isEditReviewerForm) {
      const nameNotVisiable = [
        "firstName",
        "lastName",
        "addressLine1",
        "addressLine2",
        "state",
        "city",
        "zip",
        "supervisorFirstName",
        "supervisorLastName",
      ];
      return nameNotVisiable.includes(inputName);
    } else {
      const nameNotVisiable = ["fullName", "fullAddress", "supervisorFullName"];
      return nameNotVisiable.includes(inputName);
    }
  }

  public fullNameAndAddressBuild(data: any) {
    this.previewPayload.certificationPartTwoApplicationForm.fullName = `${
      data.certificationPartTwoApplicationForm.firstName
    } ${
      data.certificationPartTwoApplicationForm.lastName
        ? data.certificationPartTwoApplicationForm.lastName
        : ""
    }`;
    const fullAddress = utils.addressBuilder(
      this.previewPayload,
      "certificationPartTwoApplicationForm",
      "default"
    );
    this.previewPayload.certificationPartTwoApplicationForm.fullAddress =
      fullAddress;
    if (this.previewPayload.certificationPartTwoSupervisorInformation) {
      for (
        let i = 0;
        i <
        this.previewPayload.certificationPartTwoSupervisorInformation.length;
        i++
      ) {
        this.previewPayload.certificationPartTwoSupervisorInformation[
          i
        ].supervisorFullName = `${data.certificationPartTwoSupervisorInformation[i].supervisorFirstName} ${data.certificationPartTwoSupervisorInformation[i].supervisorLastName}`;
      }
    }
    if (this.previewPayload.certificationPartTwoRefererenceInformation) {
      for (
        let i = 0;
        i <
        this.previewPayload.certificationPartTwoRefererenceInformation.length;
        i++
      ) {
        this.previewPayload.certificationPartTwoRefererenceInformation[
          i
        ].fullName = `${this.previewPayload.certificationPartTwoRefererenceInformation[i].firstName} ${this.previewPayload.certificationPartTwoRefererenceInformation[i].lastName}`;
      }
    }
  }

  public numberValidation(evt: any, length?: number) {
    utils.onlyNumberKey(evt, length);
  }

  public avoidNumbersAndSpecialCharacter(evt: any) {
    utils.avoidNumbersAndSpecialCharacters(evt);
  }

  public telCharacterValidate(evt: any, length?: number) {
    utils.telCharacterValidate(evt, length);
  }

  public onlyAlphabets(evt: any, fieldName = "") {
    utils.onlyAlphabets(evt, fieldName);
  }

  public emptyEvent() {
    return;
  }

  public fieldValidation(
    value: any,
    eventName: string,
    fieldType: string,
    maxLength = 0,
    fieldName = ""
  ) {
    if (fieldType === "number") {
      this.eventBind[eventName](value, maxLength);
    } else if (fieldName === "state") {
      this.eventBind[eventName](value, fieldName);
    } else {
      this.eventBind[eventName](value);
    }
  }

  public fieldUpdate(groupName = "", supervisorIdx: number) {
    switch (groupName) {
      case "stillEmployed":
        if (
          this.previewPayload.certificationPartTwoSupervisorInformation[
            supervisorIdx
          ].stillEmployed === "No"
        ) {
          if (supervisorIdx !== undefined) {
            if (this.supervisorEndDateShowIdx.length) {
              this.supervisorEndDateShowIdx.push(supervisorIdx);
            } else {
              this.supervisorEndDateShowIdx = [supervisorIdx];
            }
            if (!this.supervisorStartDateShowIdx.includes(supervisorIdx)) {
              if (this.supervisorStartDateShowIdx.length) {
                this.supervisorStartDateShowIdx.push(supervisorIdx);
              } else {
                this.supervisorStartDateShowIdx = [supervisorIdx];
              }
            }
          }
          this.supervisorUIUpdate = utils.UIUpdateUsingKey(
            this.supervisorUIUpdate
          );
        } else {
          if (!this.supervisorStartDateShowIdx.includes(supervisorIdx)) {
            if (this.supervisorStartDateShowIdx.length) {
              this.supervisorStartDateShowIdx.push(supervisorIdx);
            } else {
              this.supervisorStartDateShowIdx = [supervisorIdx];
            }
          }
          const idx = this.supervisorEndDateShowIdx.findIndex(
            (element) => element === supervisorIdx
          );
          if (idx > -1) {
            this.supervisorEndDateShowIdx.splice(idx);
          }
          this.supervisorUIUpdate = utils.UIUpdateUsingKey(
            this.supervisorUIUpdate
          );
        }
        break;
      default:
        break;
    }
  }

  @Watch("checkFormValidation")
  public validation() {
    this.checkValidation = true;
    this.$validator.validateAll().then((validate: any) => {
      if (validate) {
        this.$emit("isValidForm", true);
      } else {
        const el = document.getElementsByClassName("is-invalid")[0];
        if (el) {
          el.scrollIntoView({ behavior: "smooth", block: "end" });
        }
        this.$emit("isValidForm", false);
      }
    });
  }

  public isRevertedChanges(event: boolean) {
    if (event === true) {
      this.$emit("getUpdatedData");
      this.$emit("isEditForm", false);
    }
  }

  public editedPreviewPayloadData(event: any) {
    if (event) {
      this.previewPayload = event;
    }
  }

  public isConfirmChanges(event: boolean) {
    this.isConfirm = event;
    if (this.isConfirm) {
      const id = this.$route.path.split("/")[3];
      this.isLargeLoading = true;
      this.checkValidation = false;
      certificationFormApi
        .updateReviewerCertificationForm(this.previewPayload, id)
        .then(() => {
          this.editApplication = false;
          this.isLargeLoading = false;
          this.isEditReviewerForm = false;
          if (
            (this.previewPayload.referralEmails &&
              this.previewPayload.referralEmails.length) ||
            (this.previewPayload.supervisorEmails &&
              this.previewPayload.supervisorEmails.length)
          ) {
            let type;
            let email;
            if (
              this.previewPayload.referralEmails &&
              this.previewPayload.referralEmails.length
            ) {
              type = "Reference";
              email = this.previewPayload.referralEmails[0].newEmail;
            }
            if (
              this.previewPayload.supervisorEmails &&
              this.previewPayload.supervisorEmails.length
            ) {
              type = "Supervisor";
              email = this.previewPayload.supervisorEmails[0].newEmail;
            }
            const data = {
              isUpdateData: false,
              isUpdateEmail: true,
              type: type,
              email: email,
            };
            this.$emit("isUpdateForm", data);
          } else {
            const data = {
              isUpdateData: true,
              isUpdateEmail: false,
            };
            this.$emit("isUpdateForm", data);
          }
          this.$emit("editIndex", this.editIndex);
          this.previewPayload.referralEmails = [];
          this.previewPayload.supervisorEmails = [];
          this.$emit("getUpdatedData");
          this.$emit("isEditForm", false);
        })
        .catch((error) => {
          this.errorMessage = utils.errorMessage(error);
          this.isLoading = false;
          this.errorMessage = utils.errorMessage(error);
        });
    }
  }

  // This method for change status word for class

  public statusWordChange(status: string) {
    return utils.statusCaseChange(status);
  }

  public classNameChooser(inputClass: string) {
    return utils.classNameChooser(inputClass);
  }

  public closdedErrorPopup(event: any) {
    if (event) {
      this.errorMessage = "";
    }
  }
  public onEditReference(currentIndex: any) {
    this.isEditReference = true;
    this.editIndex = currentIndex ? currentIndex : 0;
    this.isEditReviewerForm = true;
    this.disabled = false;
    this.$emit("isEditForm", true);
  }
}
