import { Component, Prop, Vue } from "vue-property-decorator";
import { gql } from "graphql-tag";
import RadioButtonComponent from "../radio-button/radio-button.component.vue";
import CheckboxButtonComponent from "../checkbox-button/checkbox-button.component.vue";
import SingleCheckboxButton from "@/components/single-checkbox-button/single-checkbox-button.component.vue";
import InputOtherComponent from "../input-other/input-other.component.vue";
import SignatureSubmissionComponent from "../signature-submission/signature-submission.component.vue";
import { trainingFormApi } from "@/providers/apis/training-form";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import { TRAINING_APPLICATION_FORM_QUERY } from "@/graphql/training-application-form-query";
import {
  FormFieldContents,
  FormNavbar,
  TrainingForm,
} from "@/models/training-form/training-form.interface";
import TrainingPreviewFormComponent from "../training-preview-form/training-preview-form.component.vue";
import DemographicDataComponent from "../demographic-data/demographic-data.component.vue";
import { utils } from "@/utils/utils";
import moment from "moment";
import DropdownListComponent from "../dropdown-list/dropdown-list.component.vue";
import InputTagComponent from "../input-tags/input-tags.component.vue";
import FileUploadComponent from "../file-upload/file-upload.component.vue";
import { loginApi } from "@/providers/apis/login";
import { appConfigService } from "@/providers/services/app/app-config";
import ErrorPopupComponent from "../error-popup/error-popup.component.vue";
import FormInputDatepickerComponent from "../form-input-datepicker/form-input-datepicker.component.vue";

@Component({
  name: "training-form",
  components: {
    "b-form-radio-group": RadioButtonComponent,
    "b-form-checkbox": CheckboxButtonComponent,
    "b-form-dropdown": DropdownListComponent,
    "b-form-single-checkbox": SingleCheckboxButton,
    "b-form-checkbox-input": InputOtherComponent,
    "b-form-signature-submission": SignatureSubmissionComponent,
    "b-demographic-form": DemographicDataComponent,
    "b-preview-form": TrainingPreviewFormComponent,
    "b-form-tag-input": InputTagComponent,
    "b-form-upload-file": FileUploadComponent,
    "b-form-input-datepicker": FormInputDatepickerComponent,
    AppLoaderComponent,
    ErrorPopupComponent,
  },
})
export default class TrainingFormComponent extends Vue {
  public formPagination = 0;
  public formName = "certificationPartOneBasicDetails";
  public applicationData: Array<TrainingForm> = [];
  public applicationNavData: FormNavbar[] = [];
  public formData: FormFieldContents[] = [];
  public isNavLoad = false;
  public isScroll = false;
  public isValid = false;
  public formDataResult: any;
  public formApplicationId = "";
  public getTrainingApplicationBasicData: any;
  public currentForm: any;
  public signatureInvalid = false;
  public saveDataDraft = false;
  public payLoadData: any = {
    fillStatus: "DRAFT",
    id: "",
    certificationPartOneBasicDetails: {
      firstName: "",
      lastName: "",
      isOtherName: "",
      allVersionNames: [],
      preferredFirstName: "",
      preferredLastName: "",
      trainingCity: [],
      otherTrainingCity: "",
      travelForThisTraining: "",
      socialSecurityNumber: "",
      gender: "",
      addressLine1: "",
      addressLine2: "",
      state: "",
      city: "",
      zip: "",
      doYouPrefer: [],
      primaryPhone: "",
      email: "",
      secondaryPhone: "",
      secondaryEmail: null,
    },
    trainingEducationExperience: {
      job_or_volunteer_title_1: "",
      job_or_volunteer_agency_1: "",
      job_or_volunteer_startDate_1: "",
      job_or_volunteer_endDate_1: "",
      job_or_volunteer_title_2: "",
      job_or_volunteer_agency_2: "",
      job_or_volunteer_startDate_2: "",
      job_or_volunteer_endDate_2: "",
      highSchool: [],
      highSchoolNotListed: "",
      certificateFiles: [],
      otherCertifications: [],
      cprsCertificate: [],
      cfssCertificate: [],
      otherCertificate: [],
    },
    trainingProgrammaticEligibilityQuestions: {
      isAgeValid: "",
      mentalHealthChallenge: "",
      consecutiveMonths: "",
      substanceAbuse: "",
      wellnessYourself: "",
      weeklongTraining: "",
      comfortableParticipation: "",
    },
    trainingJourneyofHeadling: {
      shapeofYourLife: "",
      wellnessRecovery: "",
      supportSystem: "",
      selfCare: "",
      backgroundViewpoint: "",
      yourLivedExperience: "",
      advocatedForYourself: "",
      yourStrengths: "",
      taughtaGroup: "",
      greatestChallenge: "",
      anythingElse: "",
    },
    trainingSignature: {
      isSignature: "",
      submissionDate: moment().format("MM-DD-YYYY"),
      demographic: {
        hispanicorLatino: {
          hispanicorLatino: "",
        },
        ethnicGroupYourself: {
          centralAmerican: "",
          cuban: "",
          dominican: "",
          mexican: "",
          southAmerican: "",
          others: "",
        },
        raceYourself: {
          blackAmerican: "",
          asian: "",
          nativeHawaiian: "",
          pacificIslander: "",
          white: "",
          americanIndian: "",
          dob: "",
        },
      },
    },
  };
  public formTitle = {
    main_title: "",
    application_name: "",
  };
  public isLoading = true;
  public errorMessage = "";
  public formCompletedIndex: number[] = [];
  public checkValidForm = false;
  public isEdit = false;
  public eventBind: any = {
    acceptNumberOnly: this.numberValidation,
    avoidNumbersAndSpecialChar: this.avoidNumbersAndSpecialCharacter,
    telCharacter: this.telCharacterValidate,
    onlyAlphabets: this.onlyAlphabets,
  };
  public hideDiv: string[] = [
    "allVersionNames",
    "otherTrainingCity",
    "highSchoolNotListed",
    "cprsCertificate",
    "cfssCertificate",
    "otherCertificate",
  ];
  public checkField: string[] = [
    "isOtherName",
    "trainingCity",
    "highSchool",
    "otherCertifications",
  ];
  public additionalNames = [];
  public additionalNamesCount = 0;
  public fileData: any[] = [];
  public mainId = "app";
  public currentRoute = "";
  public isFileValid = false;
  public notFilesUploadedKeyNames: string[] = [];
  public jobVolunteerDuplicateField: string[] = [];

  constructor() {
    super();
  }

  public async created() {
    this.currentRoute = this.$route.path;
    loginApi
      .logInWithCredential(
        appConfigService.getClientId(),
        appConfigService.getClientKey(),
        "anonymous"
      )
      .then((data) => {
        this.isLoading = false;
        localStorage.setItem("anonymousToken", data.access_token);
      })
      .catch((error) => {
        this.errorMessage = utils.errorMessage(error);
        this.isLoading = false;
      });
    this.$validator.extend(
      "tagsMaxLength",
      utils.customValidator.tagsValidation
    );
    let payloadStorage: any = localStorage.getItem("trainingFormPayload");
    const formFilledIndex: any = localStorage.getItem(
      "trainingFormFilledIndex"
    );
    if (formFilledIndex) {
      formFilledIndex.split(",").forEach((data: string) => {
        this.formCompletedIndex.push(Number(data));
      });
    } else {
      if (!this.formCompletedIndex.includes(this.formPagination)) {
        this.formCompletedIndex.push(this.formPagination);
      }
    }

    if (payloadStorage) {
      payloadStorage = JSON.parse(payloadStorage);
      this.payLoadData = { ...payloadStorage };
    }
    if (this.formPagination === 0) {
      this.isNavLoad = true;
    }
    this.$apollo
      .query({
        query: gql`query ${TRAINING_APPLICATION_FORM_QUERY}`,
      })
      .then((result) => {
        this.applicationData = result.data.trainingApplicationForms.data;
        this.formTitle = {
          main_title: this.applicationData[0].attributes.main_title,
          application_name: this.applicationData[0].attributes.application_name,
        };
        this.applicationNavData = this.applicationData[0].attributes.navbar;
        this.formData = JSON.parse(
          JSON.stringify(this.applicationData[0].attributes.form_fields)
        );
        for (let index = 0; index < this.checkField.length; index++) {
          this.fieldUpdate(this.checkField[index], true);
        }
      });
    const el = document.getElementById("header");
    if (el) {
      el.classList.add("remove-container");
    }
  }
  public mounted() {
    const mainEl: any = document.querySelector(".main-content");
    if (mainEl) {
      mainEl.addEventListener("keyup", (event: any) => {
        if (event.shiftKey && event.keyCode == 9) {
          const el = event.path[0];
          if (el) {
            el.scrollIntoView({ block: "center", behavior: "smooth" });
          }
        }
      });
    }
    const el = document.getElementById("header");
    if (el) {
      el.classList.add("remove-container");
    }
  }

  public formEdit(slug: any) {
    this.isEdit = true;
    this.formName = slug;
    if (slug == "certificationPartOneBasicDetails") {
      this.formPagination = 0;
    } else if (slug == "trainingEducationExperience") {
      this.formPagination = 1;
    } else if (slug == "trainingProgrammaticEligibilityQuestions") {
      this.formPagination = 2;
    } else if (slug == "trainingJourneyofHeadling") {
      this.formPagination = 3;
    } else if (slug == "trainingSignature") {
      this.payLoadData.trainingSignature.isSignature = "";
      localStorage.setItem(
        "trainingFormPayload",
        JSON.stringify(this.payLoadData)
      );
      this.formPagination = 4;
    }
    const el = document.getElementById(this.mainId);
    if (el) {
      el.scrollIntoView({ behavior: "smooth" });
    }
  }

  public jobVolunteerDuplicate(field: string, isSplice = false) {
    if (!isSplice) {
      if (this.jobVolunteerDuplicateField.length) {
        if (!this.jobVolunteerDuplicateField.includes(field)) {
          this.jobVolunteerDuplicateField.push(field);
        }
      } else {
        this.jobVolunteerDuplicateField = [field];
      }
    } else {
      if (this.jobVolunteerDuplicateField.length) {
        const idx = this.jobVolunteerDuplicateField.findIndex(
          (data: string) => {
            return data === field;
          }
        );
        if (idx > -1) {
          this.jobVolunteerDuplicateField.splice(idx, 1);
        }
      }
    }
  }

  public nextButton() {
    this.checkValidForm = true;
    this.isFileValid = !this.isFileValid;
    if (this.formPagination == 1) {
      if (
        this.payLoadData.trainingEducationExperience.job_or_volunteer_title_1 &&
        this.payLoadData.trainingEducationExperience.job_or_volunteer_title_2 &&
        this.payLoadData.trainingEducationExperience
          .job_or_volunteer_title_1 ===
          this.payLoadData.trainingEducationExperience.job_or_volunteer_title_2
      ) {
        this.jobVolunteerDuplicate("job_or_volunteer_title");
      } else {
        this.jobVolunteerDuplicate("job_or_volunteer_title", true);
      }
      if (
        this.payLoadData.trainingEducationExperience
          .job_or_volunteer_agency_1 &&
        this.payLoadData.trainingEducationExperience
          .job_or_volunteer_agency_2 &&
        this.payLoadData.trainingEducationExperience
          .job_or_volunteer_agency_1 ===
          this.payLoadData.trainingEducationExperience.job_or_volunteer_agency_2
      ) {
        this.jobVolunteerDuplicate("job_or_volunteer_agency");
      } else {
        this.jobVolunteerDuplicate("job_or_volunteer_agency", true);
      }
      if (
        this.payLoadData.trainingEducationExperience
          .job_or_volunteer_startDate_1 &&
        this.payLoadData.trainingEducationExperience
          .job_or_volunteer_startDate_2 &&
        this.payLoadData.trainingEducationExperience
          .job_or_volunteer_startDate_1 ===
          this.payLoadData.trainingEducationExperience
            .job_or_volunteer_startDate_2
      ) {
        this.jobVolunteerDuplicate("job_or_volunteer_startDate");
      } else {
        this.jobVolunteerDuplicate("job_or_volunteer_startDate", true);
      }
      if (
        this.payLoadData.trainingEducationExperience
          .job_or_volunteer_endDate_1 &&
        this.payLoadData.trainingEducationExperience
          .job_or_volunteer_endDate_2 &&
        this.payLoadData.trainingEducationExperience
          .job_or_volunteer_endDate_1 ===
          this.payLoadData.trainingEducationExperience
            .job_or_volunteer_endDate_2
      ) {
        this.jobVolunteerDuplicate("job_or_volunteer_endDate");
      } else {
        this.jobVolunteerDuplicate("job_or_volunteer_endDate", true);
      }
    }
    if (this.formPagination == 4) {
      this.payLoadData.trainingSignature.submissionDate =
        moment().format("MM-DD-YYYY");
      if (this.payLoadData.trainingSignature.isSignature) {
        this.signatureInvalid = false;
      } else {
        this.checkValidForm = true;
        this.signatureInvalid = true;
        setTimeout(() => {
          const el = document.getElementsByClassName("is-invalid")[0];
          if (el) {
            el.scrollIntoView({ behavior: "smooth", block: "end" });
          }
        }, 10);
        return;
      }
    }
    this.$validator.validateAll().then(async (validate) => {
      if (
        validate &&
        !this.notFilesUploadedKeyNames.length &&
        !this.jobVolunteerDuplicateField.length
      ) {
        if (
          this.payLoadData.certificationPartOneBasicDetails.secondaryEmail ===
          ""
        ) {
          this.payLoadData.certificationPartOneBasicDetails.secondaryEmail =
            null;
        }
        this.isLoading = true;
        this.checkValidForm = false;
        this.isValid = true;
        if (this.payLoadData.id == "") {
          await trainingFormApi
            .TrainingApplicationForm(
              this.payLoadData.fillStatus,
              this.payLoadData,
              this.currentRoute.split("/")[2]
            )
            .then((result: any) => {
              this.isLoading = false;
              this.formApplicationId = result.headers.location;
              localStorage.setItem(
                "trainingApplicationId",
                this.formApplicationId
              );
              this.payLoadData.id = localStorage.getItem(
                "trainingApplicationId"
              );
              localStorage.setItem(
                "trainingFormPayload",
                JSON.stringify(this.payLoadData)
              );
              if (!this.isEdit) {
                this.nextPaginationUpdate();
              }
              const el = document.getElementById(this.mainId);
              if (el) {
                el.scrollIntoView({ behavior: "smooth" });
              }
            })
            .catch((error) => {
              this.isLoading = false;
              this.errorMessage = utils.errorMessage(error);
            });
        } else {
          const id = this.payLoadData.id;
          await trainingFormApi
            .updateTrainingForm(
              this.payLoadData,
              id,
              this.currentRoute.split("/")[2]
            )
            .then((data) => {
              this.isLoading = false;
              if (!this.isEdit) {
                this.nextPaginationUpdate();
              }
              const el = document.getElementById(this.mainId);
              if (el) {
                el.scrollIntoView({ behavior: "smooth" });
              }
            })
            .catch((error) => {
              this.errorMessage = utils.errorMessage(error);
              this.isLoading = false;
            });
          localStorage.setItem(
            "trainingFormPayload",
            JSON.stringify(this.payLoadData)
          );
          if (this.isEdit) {
            this.formPagination = 5;
          }
        }
        if (!this.formCompletedIndex.includes(this.formPagination)) {
          this.formCompletedIndex.push(this.formPagination);
        }
        this.currentForm = this.payLoadData[this.formName];
        localStorage.setItem(
          "trainingFormFilledIndex",
          this.formCompletedIndex.toString()
        );
      } else {
        setTimeout(() => {
          const el = document.getElementsByClassName("is-invalid")[0];
          if (el) {
            el.scrollIntoView({ behavior: "smooth", block: "end" });
          }
        }, 10);
      }
    });
  }

  public allFilesUploaded(event: any) {
    if (this.notFilesUploadedKeyNames.includes(event)) {
      this.notFilesUploadedKeyNames.splice(event, 1);
    }
  }

  public notFileUploaded(event: string) {
    if (!this.notFilesUploadedKeyNames.includes(event)) {
      this.notFilesUploadedKeyNames.push(event);
    }
  }

  public resetFileKey(event: boolean) {
    if (event) {
      this.notFilesUploadedKeyNames = [];
    }
  }

  public nextPaginationUpdate() {
    if (this.formPagination < this.formData.length - 1) {
      this.isNavLoad = true;
      const page = this.formPagination + 1;
      if (page == 0) {
        this.formName = "certificationPartOneBasicDetails";
      } else if (page == 1) {
        this.formName = "trainingEducationExperience";
      } else if (page == 2) {
        this.formName = "trainingProgrammaticEligibilityQuestions";
      } else if (page == 3) {
        this.formName = "trainingJourneyofHeadling";
      } else if (page == 4) {
        this.formName = "trainingSignature";
      }
      this.formPagination = page;
    }
  }

  public async saveDraft() {
    this.$bvToast.show("example-toast");
    this.isValid = true;
    localStorage.setItem(
      "trainingFormPayload",
      JSON.stringify(this.payLoadData)
    );
    this.saveDataDraft = true;
    setTimeout(() => {
      this.saveDataDraft = false;
    }, 1000);
  }

  public previousButton() {
    this.notFilesUploadedKeyNames = [];
    this.isEdit = false;
    if (this.formPagination !== 0) {
      const el = document.getElementById(this.mainId);
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
      this.formPagination = this.formPagination - 1;
      if (this.formPagination == 0) {
        this.formName = "certificationPartOneBasicDetails";
      } else if (this.formPagination == 1) {
        this.formName = "trainingEducationExperience";
      } else if (this.formPagination == 2) {
        this.formName = "trainingProgrammaticEligibilityQuestions";
      } else if (this.formPagination == 3) {
        this.formName = "trainingJourneyofHeadling";
      } else if (this.formPagination == 4) {
        this.formName = "trainingSignature";
      }
    }
  }

  public onSubmit() {
    this.$validator.validate().then((validate) => {
      if (validate) {
        this.payLoadData.trainingSignature.submissionDate =
          moment().format("MM-DD-YYYY");
        let payloadStorage: any = localStorage.getItem("trainingFormPayload");
        if (payloadStorage) {
          payloadStorage = JSON.parse(payloadStorage);
          this.payLoadData = { ...payloadStorage };
        }
        this.isLoading = true;
        this.payLoadData.fillStatus = "COMPLETED";
        if (
          this.payLoadData.certificationPartOneBasicDetails.secondaryEmail ===
          ""
        ) {
          this.payLoadData.certificationPartOneBasicDetails.secondaryEmail =
            null;
        }
        if (localStorage.getItem("trainingApplicationId")) {
          this.payLoadData.id = localStorage.getItem("trainingApplicationId");
        }
        const id = this.payLoadData.id;
        trainingFormApi
          .updateTrainingForm(
            this.payLoadData,
            id,
            this.currentRoute.split("/")[2]
          )
          .then(() => {
            trainingFormApi
              .getTrainingApplicationForm(id, this.currentRoute.split("/")[2])
              .then((result: any) => {
                localStorage.removeItem("anonymousToken");
                const applicationCode = result.data.code;
                const someData = {
                  title: "Certified Young Adult Peer Support Specialist",
                  SubTitle: "Training: Part One",
                };
                this.$router.push({
                  path: "/form/submitted",
                  query: {
                    subTitle: someData.SubTitle,
                    applicationId: applicationCode,
                  },
                });
                this.isLoading = false;
              })
              .catch((error) => {
                this.errorMessage = utils.errorMessage(error);
                this.isLoading = false;
              });
            const el = document.getElementById(this.mainId);
            if (el) {
              el.scrollIntoView({ behavior: "smooth" });
            }
            localStorage.removeItem("trainingFormPayload");
            localStorage.removeItem("trainingApplicationId");
            localStorage.removeItem("trainingFormFilledIndex");
          })
          .catch((error) => {
            this.errorMessage = utils.errorMessage(error);
            this.isLoading = false;
          });
      }
    });
  }

  public async navigateTab(slug: string, index: number) {
    if (this.formCompletedIndex.includes(index)) {
      this.isEdit = false;
      if (index > this.formPagination) {
        this.isFileValid = !this.isFileValid;
        //signature validation
        this.checkValidForm = true;
        if (index > 1) {
          if (
            this.payLoadData.trainingEducationExperience
              .job_or_volunteer_title_1 &&
            this.payLoadData.trainingEducationExperience
              .job_or_volunteer_title_2 &&
            this.payLoadData.trainingEducationExperience
              .job_or_volunteer_title_1 ===
              this.payLoadData.trainingEducationExperience
                .job_or_volunteer_title_2
          ) {
            this.jobVolunteerDuplicate("job_or_volunteer_title");
          } else {
            this.jobVolunteerDuplicate("job_or_volunteer_title", true);
          }
          if (
            this.payLoadData.trainingEducationExperience
              .job_or_volunteer_agency_1 &&
            this.payLoadData.trainingEducationExperience
              .job_or_volunteer_agency_2 &&
            this.payLoadData.trainingEducationExperience
              .job_or_volunteer_agency_1 ===
              this.payLoadData.trainingEducationExperience
                .job_or_volunteer_agency_2
          ) {
            this.jobVolunteerDuplicate("job_or_volunteer_agency");
          } else {
            this.jobVolunteerDuplicate("job_or_volunteer_agency", true);
          }
          if (
            this.payLoadData.trainingEducationExperience
              .job_or_volunteer_startDate_1 &&
            this.payLoadData.trainingEducationExperience
              .job_or_volunteer_startDate_2 &&
            this.payLoadData.trainingEducationExperience
              .job_or_volunteer_startDate_1 ===
              this.payLoadData.trainingEducationExperience
                .job_or_volunteer_startDate_2
          ) {
            this.jobVolunteerDuplicate("job_or_volunteer_startDate");
          } else {
            this.jobVolunteerDuplicate("job_or_volunteer_startDate", true);
          }
          if (
            this.payLoadData.trainingEducationExperience
              .job_or_volunteer_endDate_1 &&
            this.payLoadData.trainingEducationExperience
              .job_or_volunteer_endDate_2 &&
            this.payLoadData.trainingEducationExperience
              .job_or_volunteer_endDate_1 ===
              this.payLoadData.trainingEducationExperience
                .job_or_volunteer_endDate_2
          ) {
            this.jobVolunteerDuplicate("job_or_volunteer_endDate");
          } else {
            this.jobVolunteerDuplicate("job_or_volunteer_endDate", true);
          }
          if (this.jobVolunteerDuplicateField.length) {
            this.formPagination = 1;
            this.formName = "trainingEducationExperience";
          }
        }

        if (index > 4) {
          this.payLoadData.trainingSignature.submissionDate =
            moment().format("MM-DD-YYYY");
          if (this.payLoadData.trainingSignature.isSignature) {
            this.signatureInvalid = false;
          } else {
            this.checkValidForm = true;
            this.signatureInvalid = true;
            this.formPagination = 4;
            this.formName = "trainingSignature";
            setTimeout(() => {
              const el = document.getElementsByClassName("is-invalid")[0];
              if (el) {
                el.scrollIntoView({ behavior: "smooth", block: "end" });
              }
            }, 10);
            return;
          }
        }

        this.$validator.validateAll().then((validate: any) => {
          if (
            validate &&
            !this.notFilesUploadedKeyNames.length &&
            !this.jobVolunteerDuplicateField.length
          ) {
            if (
              this.payLoadData.certificationPartOneBasicDetails
                .secondaryEmail === ""
            ) {
              this.payLoadData.certificationPartOneBasicDetails.secondaryEmail =
                null;
            }
            const el = document.getElementById(this.mainId);
            if (el) {
              el.scrollIntoView({ behavior: "smooth" });
            }
            localStorage.setItem(
              "trainingFormPayload",
              JSON.stringify(this.payLoadData)
            );
            if (index === 5) {
              this.formPagination = index;
            } else {
              this.formName = slug;
              this.formPagination = index;
            }
          } else {
            const el = document.getElementsByClassName("is-invalid")[0];
            if (el) {
              el.scrollIntoView({ behavior: "smooth", block: "end" });
            }
          }
        });
      } else {
        this.notFilesUploadedKeyNames = [];
        this.jobVolunteerDuplicateField = [];
        if (index === 5) {
          this.formPagination = index;
        } else {
          this.formName = slug;
          this.formPagination = index;
        }
        const el = document.getElementById(this.mainId);
        if (el) {
          el.scrollIntoView({ behavior: "smooth" });
        }
      }
    }
  }

  public closeToast() {
    this.$bvToast.hide("example-toast");
  }

  public numberValidation(evt: any, length?: number) {
    utils.onlyNumberKey(evt, length);
  }

  public avoidNumbersAndSpecialCharacter(evt: any) {
    utils.avoidNumbersAndSpecialCharacters(evt);
  }

  public telCharacterValidate(evt: any, length?: number) {
    utils.telCharacterValidate(evt, length);
  }

  public onlyAlphabets(evt: any, fieldName = "") {
    utils.onlyAlphabets(evt, fieldName);
  }

  public emptyEvent() {
    return;
  }

  public fieldValidation(
    value: any,
    eventName: string,
    fieldType: string,
    maxLength = 0,
    fieldName = ""
  ) {
    if (fieldType === "number") {
      this.eventBind[eventName](value, maxLength);
    } else if (fieldName === "state") {
      this.eventBind[eventName](value, fieldName);
    } else {
      this.eventBind[eventName](value);
    }
  }

  public addNameBox(formName: string) {
    this.additionalNamesCount++;
    if (this.payLoadData[formName].names.length) {
      this.payLoadData[formName].names.push("");
    } else {
      this.payLoadData[formName].names = [""];
    }
  }

  public fieldUpdate(groupName = "", initial = false) {
    switch (groupName) {
      case "isOtherName":
        if (
          !this.payLoadData.certificationPartOneBasicDetails.isOtherName ||
          this.payLoadData.certificationPartOneBasicDetails.isOtherName === "No"
        ) {
          if (!this.hideDiv.includes("allVersionNames") && !initial) {
            this.hideDiv.push("allVersionNames");
            this.payLoadData.certificationPartOneBasicDetails.allVersionNames =
              [];
          } else {
            return;
          }
        } else {
          if (this.hideDiv.indexOf("allVersionNames") >= 0) {
            this.hideDiv.splice(this.hideDiv.indexOf("allVersionNames"), 1);
          }
        }
        break;
      case "highSchool":
        if (
          !this.payLoadData.trainingEducationExperience.highSchool.includes(
            "Other"
          )
        ) {
          if (!this.hideDiv.includes("highSchoolNotListed") && !initial) {
            this.hideDiv.push("highSchoolNotListed");
            this.payLoadData.trainingEducationExperience.highSchoolNotListed =
              "";
          } else {
            return;
          }
        } else {
          if (this.hideDiv.indexOf("highSchoolNotListed") >= 0) {
            this.hideDiv.splice(this.hideDiv.indexOf("highSchoolNotListed"), 1);
          }
        }
        break;
      case "otherCertifications":
        if (
          !this.payLoadData.trainingEducationExperience.otherCertifications.includes(
            "CPRS"
          )
        ) {
          if (!this.hideDiv.includes("cprsCertificate") && !initial) {
            this.hideDiv.push("cprsCertificate");
            this.payLoadData.trainingEducationExperience.cprsCertificate = [];
          }
        } else {
          if (this.hideDiv.indexOf("cprsCertificate") >= 0) {
            this.hideDiv.splice(this.hideDiv.indexOf("cprsCertificate"), 1);
          }
        }
        if (
          !this.payLoadData.trainingEducationExperience.otherCertifications.includes(
            "CFSS"
          )
        ) {
          if (!this.hideDiv.includes("cfssCertificate") && !initial) {
            this.hideDiv.push("cfssCertificate");
            this.payLoadData.trainingEducationExperience.cfssCertificate = [];
          }
        } else {
          if (this.hideDiv.indexOf("cfssCertificate") >= 0) {
            this.hideDiv.splice(this.hideDiv.indexOf("cfssCertificate"), 1);
          }
        }
        if (
          !this.payLoadData.trainingEducationExperience.otherCertifications.includes(
            "Other"
          )
        ) {
          if (!this.hideDiv.includes("otherCertificate") && !initial) {
            this.hideDiv.push("otherCertificate");
            this.payLoadData.trainingEducationExperience.otherCertificate = [];
          }
        } else {
          if (this.hideDiv.indexOf("otherCertificate") >= 0) {
            this.hideDiv.splice(this.hideDiv.indexOf("otherCertificate"), 1);
          }
        }
        break;
      case "trainingCity":
        if (
          !this.payLoadData.certificationPartOneBasicDetails.trainingCity.includes(
            "Other"
          )
        ) {
          if (!this.hideDiv.includes("otherTrainingCity") && !initial) {
            this.hideDiv.push("otherTrainingCity");
            this.payLoadData.certificationPartOneBasicDetails.otherTrainingCity =
              "";
          } else {
            return;
          }
        } else {
          if (this.hideDiv.indexOf("otherTrainingCity") >= 0) {
            this.hideDiv.splice(this.hideDiv.indexOf("otherTrainingCity"), 1);
          }
        }
        break;
      default:
        break;
    }
  }

  public classNameChooser(inputClass: string) {
    return utils.classNameChooser(inputClass);
  }

  public closdedErrorPopup(event: any) {
    if (event) {
      this.errorMessage = "";
    }
  }
  public goBack() {
    if (this.$route.query.isDraft) {
      localStorage.removeItem("trainingFormPayload");
      localStorage.removeItem("trainingFormFilledIndex");
    }
    this.$router.push({
      path: `/application`,
      query: {
        form: "training",
      },
    });
    const el = document.getElementById("header");
    if (el) {
      el.classList.add("active");
      el.classList.remove("remove-container");
    }
  }
}
