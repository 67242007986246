import { ELIGIBILITY_CRITERIA_QUERY } from "@/graphql/eligibility-criteria-query";
import { EligibilityCriteria } from "@/models/Eligibility Criteria/eligibility-criteria.interface";
import gql from "graphql-tag";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "eligibility-criteria",
})
export default class EligibilityCriteriaComponent extends Vue {
  public eligibilityCriteria: EligibilityCriteria[] = [];
  public isEnableValidation = false;
  public isChecked: boolean[] = [];
  public saveDataDraft = false;

  @Prop()
  public typeOfCriteria!: string;

  @Prop()
  public eligibilityData!: boolean;

  @Prop()
  public formName!: string;

  public async created() {
    await this.$apollo
      .query({
        query: gql`query ${ELIGIBILITY_CRITERIA_QUERY}`,
      })
      .then((result) => {
        this.eligibilityCriteria = result.data.eligibilityCriterias.data;
      });
    if (this.eligibilityData) {
      this.isChecked = [this.eligibilityData];
    }
  }

  public nextButton() {
    this.$validator.validate().then((validate) => {
      if (validate) {
        window.scroll(0, 0);
        this.$emit("eligibilityChecked", this.isChecked);
      }
    });
  }

  public saveDraft() {
    this.$validator.validate().then((validate) => {
      if (validate) {
        this.$emit("eligibilityChecked", this.isChecked);
        this.saveDataDraft = true;
      }
    });
    setTimeout(() => {
      this.saveDataDraft = false;
    }, 1000);
  }
}
