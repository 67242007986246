import { Component, Prop, Vue } from "vue-property-decorator";
import { gql } from "graphql-tag";
import RadioButtonComponent from "../radio-button/radio-button.component.vue";
import SignatureSubmissionComponent from "../signature-submission/signature-submission.component.vue";
import { LETTER_OF_REFERENCE_QUERY } from "@/graphql/letter-of-reference-form-query";
import {
  FormFieldContents,
  LetterOfReference,
} from "@/models/letter-of-reference-form/letter-of-reference-from.interface";
import InputTextAreaComponent from "../input-textarea/input-textarea.component.vue";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import { certificationFormApi } from "@/providers/apis/certification-form";
import { STATUS } from "@/models/common-interface/common.interface";
import { utils } from "@/utils/utils";
import ErrorPopupComponent from "../error-popup/error-popup.component.vue";

@Component({
  name: "letter-of-reference-preview",
  components: {
    "b-form-radio-group": RadioButtonComponent,
    "b-form-signature-submission": SignatureSubmissionComponent,
    "b-form-textarea": InputTextAreaComponent,
    AppLoaderComponent,
    ErrorPopupComponent,
  },
})
export default class LetterOfReferencePreviewComponent extends Vue {
  @Prop()
  public previewData!: any[];
  @Prop()
  public isReviewerPreviewForm: any;
  @Prop()
  public referenceSelectedIdx: any;
  @Prop()
  public applicationId!: string;
  @Prop()
  public activeReferralEmail!: string;
  @Prop()
  public progressStatus!: string;

  public applicationData: Array<LetterOfReference> = [];
  public formData: FormFieldContents[] = [];
  public isLoading = false;
  public toogleStatus = false;
  public referenceStatus = "";
  public storeReferenceStatus: any[] = [];
  public activeReferral: any;
  public isLargeLoading = false;
  public status: string[] = [STATUS.accepted, STATUS.notAccepted];
  public errorMessage = "";

  public created() {
    this.isLoading = true;
    this.$apollo
      .query({
        query: gql`query ${LETTER_OF_REFERENCE_QUERY}`,
      })
      .then((result) => {
        this.applicationData = result.data.letterOfReferences.data;
        this.formData = this.applicationData[0].attributes.form_fields;
        this.isLoading = false;
        this.validationDisable("applicantType");
      });

    for (let index = 0; index < this.previewData.length; index++) {
      if (this.storeReferenceStatus.length) {
        this.storeReferenceStatus.push({
          index: index,
          email: this.previewData[index].email,
          status: this.previewData[index].status,
        });
      } else {
        this.storeReferenceStatus = [
          {
            index: index,
            email: this.previewData[index].email,
            status: this.previewData[index].status,
          },
        ];
      }
    }
    this.activeReferral = this.previewData.find((data: any) => {
      return data.email === this.activeReferralEmail;
    });
    this.referenceNameAndAddressBuild();
  }
  public referenceNameAndAddressBuild() {
    //for Reference Details
    this.activeReferral.data.letterOfReferenceReferenceDetails.referenceFullName = `${
      this.activeReferral.data.letterOfReferenceReferenceDetails
        .referenceFirstName
    } ${
      this.activeReferral.data.letterOfReferenceReferenceDetails
        .referenceLastName
        ? this.activeReferral.data.letterOfReferenceReferenceDetails
            .referenceLastName
        : ""
    }`;
    const fullAddress = utils.addressBuilder(
      this.activeReferral.data,
      "letterOfReferenceReferenceDetails",
      "default"
    );
    this.activeReferral.data.letterOfReferenceReferenceDetails.fullAddress =
      fullAddress;

    //Applicant Details
    this.activeReferral.data.letterOfReferenceApplicantInformation.applicantFullName = `${
      this.activeReferral.data.letterOfReferenceApplicantInformation
        .applicantFirstName
    } ${
      this.activeReferral.data.letterOfReferenceApplicantInformation
        ?.applicantLastName
        ? this.activeReferral.data.letterOfReferenceApplicantInformation
            ?.applicantLastName
        : ""
    }`;
  }

  public fieldUpdate(inputName: string) {
    const nameNotVisiable = [
      "referenceFirstName",
      "referenceLastName",
      "addressLine1",
      "addressLine2",
      "state",
      "city",
      "zip",
      "applicantFirstName",
      "applicantLastName",
    ];
    return nameNotVisiable.includes(inputName);
  }

  public async selectStatus(status: string, referenceEmail: string) {
    this.referenceStatus = status;
    this.isLargeLoading = true;
    const referencePayload = {
      id: this.applicationId,
      email: referenceEmail,
      status: status,
    };

    this.toogleStatus = !this.toogleStatus;
    await certificationFormApi
      .updateReferenceStatus(referencePayload)
      .then((data) => {
        this.$emit("referenceUpdated", {
          email: referenceEmail,
          idx: this.referenceSelectedIdx,
        });
        this.isLargeLoading = false;
      })
      .catch((error) => {
        this.errorMessage = utils.errorMessage(error);
        this.isLargeLoading = false;
      });
  }

  // This method for change status word for class

  public statusWordChange(status: string) {
    return utils.statusCaseChange(status);
  }

  public validationDisable(groupName = "") {
    const tempPickList = JSON.parse(JSON.stringify(this.formData)); // It uses of fix the "Cannot assign to read only property 'attributes' of object";
    switch (groupName) {
      case "applicantType":
        if (
          this.activeReferral.data.letterOfReferenceApplicantInformation
            .applicantType === "Professional"
        ) {
          tempPickList.map((data: any) => {
            if (data !== null && data) {
              if (data.parent_slug === "letterOfReferenceReferenceDetails") {
                delete data.fields.formInputs[2][1].validate;
                return tempPickList;
              }
            }
          });
          this.formData = tempPickList;
        } else if (
          this.activeReferral.data.letterOfReferenceApplicantInformation
            .applicantType === "Personal"
        ) {
          tempPickList.map((data: any) => {
            if (data !== null && data) {
              if (data.parent_slug === "letterOfReferenceReferenceDetails") {
                delete data.fields.formInputs[1][0].validate;
                delete data.fields.formInputs[2][0].validate;
                delete data.fields.formInputs[2][1].validate;
                return tempPickList;
              }
            }
          });
          this.formData = tempPickList;
        }
        break;
    }
  }

  public classNameChooser(inputClass: string) {
    return utils.classNameChooser(inputClass);
  }

  public closdedErrorPopup(event: any) {
    if (event) {
      this.errorMessage = "";
    }
  }
}
