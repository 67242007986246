import { STATUS, User } from "@/models/common-interface/common.interface";
import { ReviewerTrainingPreviewFormInterface } from "@/models/reviewer-training-preview-form/reviewer-training-preview-form.interface";
import { trainingFormApi } from "@/providers/apis/training-form";
import { commonService } from "@/providers/services/common-service";
import { utils } from "@/utils/utils";
import moment from "moment";
import { Component, Prop, Vue } from "vue-property-decorator";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import ScoreRubicFormComponent from "../score-rubic-form/score-rubic-form.component.vue";
import TrainingPreviewFormComponent from "../training-preview-form/training-preview-form.component.vue";
import PDFViewerComponent from "../pdf-viewer/pdf-viewer.component.vue";
import ErrorPopupComponent from "../error-popup/error-popup.component.vue";

@Component({
  name: "reviewer-training-preview",
  components: {
    AppLoaderComponent,
    ScoreRubicFormComponent,
    TrainingPreviewFormComponent,
    PDFViewerComponent,
    ErrorPopupComponent,
  },
})
export default class ReviewerTrainingPreview extends Vue {
  @Prop()
  public applicationPreviewForm!: any;

  public applicantId = "";
  public applicantPreviewForm: ReviewerTrainingPreviewFormInterface =
    {} as ReviewerTrainingPreviewFormInterface;
  public isLoading = true;
  public isMobileView = false;
  public formSelected = "";
  public headerHeight = 0;
  public sectionTabHeight = 0;
  public mobileSectionTabHeight = 0;
  public tabSelected = "training-basic-details";
  public applicantFullName = "";
  public toogleStatus = false;
  public approveStatus = "";
  public historys: any = [];
  public approveComment = "";
  public commentEventNone = false;
  public userDetails!: User;
  public mostLastRecord: any = null;
  public isFormEdit = false;
  public isApproveStatus = true;
  public isSubmit = false;
  public showCommentBox = false;
  public showCommentBoxWithDisable = false;
  public showRubricTab = false;
  public formType = "";
  public PDFApplicationPrintName = "";
  public source = "";
  public isUpdatePageNumber = false;
  public editedUserDetails: any[] = [];
  public tooglebutton = false;
  public collapseIdx = "";
  public filesEdit = [
    "certificateFiles",
    "cprsCertificate",
    "cfssCertificate",
    "otherCertificate",
  ];
  public isFormValid = false;
  public validForm = true;
  public isUserApplication = false;
  public isMigrationForm = false;
  public applicationReceivedDate = "";
  public errorMessage = "";
  public userTaskAllStatus: string[] = [];

  @Prop()
  public isPeerPreview!: any;
  public updateReviewer: any;
  public isProcessingAvailable: any;

  public async created() {
    this.formType = this.$route.query.formType?.toString();
    this.userDetails = utils.getUserDetails();
    if (utils.checkIsMobileView() || utils.checkIsTabView()) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    this.applicantId = this.$route.path.split("/")[3];
    if (this.$route.path.split("/")[1] === "reviewer-dashboard") {
      this.isUserApplication = false;
      await this.getTrainingApplicationData();
      this.formSelection("application-form", true);
    } else {
      this.isUserApplication = true;
      await this.getUserApplicationData();
      this.formSelection("application-form", true);
    }
  }

  public getUserApplicationData() {
    if (this.applicationPreviewForm.submittedMode === "migration") {
      this.isMigrationForm = true;
    }
    this.applicantPreviewForm = this.applicationPreviewForm;
    this.applicantFullName = `${this.applicantPreviewForm.data.certificationPartOneBasicDetails.firstName} ${this.applicantPreviewForm.data.certificationPartOneBasicDetails.lastName}`;
    this.applicantPreviewForm.data.certificationPartOneBasicDetails.fullName =
      this.applicantFullName;
    this.applicantPreviewForm.data.certificationPartOneBasicDetails.preferredFullName = `${this.applicantPreviewForm.data.certificationPartOneBasicDetails?.preferredFirstName} ${this.applicantPreviewForm.data.certificationPartOneBasicDetails?.preferredLastName}`;
    const fullAddress = utils.addressBuilder(
      this.applicantPreviewForm.data,
      "certificationPartOneBasicDetails",
      "default"
    );
    this.applicantPreviewForm.data.certificationPartOneBasicDetails.fullAddress =
      fullAddress;
    this.applicationReceivedDate = this.applicantPreviewForm.data
      .trainingSignature.submissionDate
      ? moment(
          this.applicantPreviewForm.data.trainingSignature.submissionDate
        ).format("MM-DD-YYYY")
      : "-";
    this.isLoading = false;
    const previewClas = document.getElementById(
      "training-preview-form-container"
    );
    if (previewClas) {
      previewClas.focus();
    }
    setTimeout(() => {
      this.getHeaderHeight();
    }, 1000);
  }

  public getUpdatedData() {
    this.getTrainingApplicationData();
  }

  public getTrainingApplicationData() {
    this.editedUserDetails = [];
    this.isLoading = true;
    let getAllEditedData: any[] = [];
    let temp: any;
    this.collapseIdx = "";
    trainingFormApi
      .getTrainingApplicationForm(this.applicantId)
      .then((result) => {
        const userId = this.userDetails.userId;
        const userTask = result.data.userTask;
        this.isProcessingAvailable = userTask
          .map((task: any) => {
            return task.status;
          })
          .includes("PROCESSING");
        this.historys = userTask.sort((a: any, b: any) => {
          a.createdAt = moment(a.createdAt).format("MM-DD-YYYY HH:mm:ss:SSS");
          b.createdAt = moment(b.createdAt).format("MM-DD-YYYY HH:mm:ss:SSS");
          return (
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
          );
        });

        //Store all userTask status

        this.userTaskAllStatus = userTask.map((task: any) => {
          return task.status;
        });

        // current userTask and findStatus
        const currentUserTask = userTask.filter(
          (task: any) => task.userId === userId
        );
        const mostRecentHistory = currentUserTask.sort((a: any, b: any) => {
          return moment(a.createdAt, "MM-DD-YYYY HH:mm:ss").diff(
            moment(b.createdAt, "MM-DD-YYYY HH:mm:ss")
          );
        });
        this.mostLastRecord = mostRecentHistory[mostRecentHistory.length - 1];
        result.data.createdAt = moment(result.data.createdAt).format(
          "MM-DD-YYYY"
        );
        this.historys.map((data: any) => {
          data.createdAt = moment
            .utc(data.createdAt)
            .local()
            .format("MM-DD-YYYY HH:mm A");
        });
        this.historys.reverse();
        this.applicantPreviewForm = result.data;
        if (this.applicantPreviewForm.submittedMode === "migration") {
          this.isMigrationForm = true;
        }
        this.applicantFullName = `${this.applicantPreviewForm.data.certificationPartOneBasicDetails.firstName} ${this.applicantPreviewForm.data.certificationPartOneBasicDetails.lastName}`;
        this.applicantPreviewForm.data.certificationPartOneBasicDetails.fullName =
          this.applicantFullName;
        if (!this.isMigrationForm) {
          this.applicantPreviewForm.data.certificationPartOneBasicDetails.preferredFullName = `${this.applicantPreviewForm.data.certificationPartOneBasicDetails.preferredFirstName} ${this.applicantPreviewForm.data.certificationPartOneBasicDetails.preferredLastName}`;
        }
        const fullAddress = utils.addressBuilder(
          this.applicantPreviewForm.data,
          "certificationPartOneBasicDetails",
          "default"
        );
        this.applicantPreviewForm.data.certificationPartOneBasicDetails.fullAddress =
          fullAddress;
        this.applicationReceivedDate = this.applicantPreviewForm.data
          .trainingSignature.submissionDate
          ? moment(
              this.applicantPreviewForm.data.trainingSignature.submissionDate
            ).format("MM-DD-YYYY")
          : "-";
        if (
          this.applicantPreviewForm.progressStatus === STATUS.accepted ||
          this.applicantPreviewForm.progressStatus === STATUS.notAccepted ||
          this.applicantPreviewForm.progressStatus === STATUS.needMoreInfo ||
          this.applicantPreviewForm.progressStatus === STATUS.processing ||
          this.applicantPreviewForm.progressStatus ===
            STATUS.waitingForTraining ||
          this.applicantPreviewForm.progressStatus === STATUS.completedTraining
        ) {
          const mostRecentHistory = this.applicantPreviewForm.userTask.sort(
            (a, b) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          )[0];
          this.approveComment = this.applicantPreviewForm.comment;
          this.approveStatus = this.applicantPreviewForm.progressStatus;
        }
        this.statusCheck();
        this.isLoading = false;
        setTimeout(() => {
          this.getHeaderHeight();
        }, 1000);
        for (const property in this.applicantPreviewForm.data) {
          if (this.applicantPreviewForm.data["History"]) {
            this.applicantPreviewForm.data["History"].map(
              (historyData: any) => {
                for (const innerProperty in this.applicantPreviewForm.data[
                  property
                ]) {
                  if (
                    historyData[innerProperty] &&
                    historyData[innerProperty].length
                  ) {
                    temp = JSON.parse(
                      JSON.stringify(historyData[innerProperty])
                    );
                    temp.forEach((element: any) => {
                      if (this.filesEdit.includes(innerProperty)) {
                        element.oldValue = element.oldValue.map((data: any) => {
                          return data.originalFileName;
                        });
                        element.newValue = element.newValue.map((data: any) => {
                          return data.originalFileName;
                        });
                      }
                      if (
                        typeof element.oldValue === "object" &&
                        typeof element.newValue === "object"
                      ) {
                        element.oldValue = element.oldValue.length
                          ? element.oldValue.join(", ")
                          : element.oldValue;
                        element.newValue = element.newValue.length
                          ? element.newValue.join(", ")
                          : element.newValue;
                      }
                      temp = element;
                      switch (property) {
                        case "certificationPartOneBasicDetails":
                          temp.mainLabel = "Basic Details";
                          break;
                        case "trainingEducationExperience":
                          temp.mainLabel = "Education and Experience";
                          break;
                        case "trainingProgrammaticEligibilityQuestions":
                          temp.mainLabel = "Eligibility Questions";
                          break;
                        default:
                          break;
                      }
                      if (getAllEditedData.length) {
                        getAllEditedData.push(temp);
                      } else {
                        getAllEditedData = [temp];
                      }
                    });
                  }
                }
              }
            );
          }
        }

        getAllEditedData.filter((currentValue, currentIndex) => {
          return getAllEditedData.indexOf(currentValue) !== currentIndex;
        });
        const unique = [
          ...new Set(
            getAllEditedData.map((a) => {
              return a.editDataTime + "/" + a.editorDetails.userId;
            })
          ),
        ];

        unique.forEach((splitUnique: string) => {
          const dateTime = splitUnique.split("/")[0];
          const userId = splitUnique.split("/")[1];
          getAllEditedData.forEach((data: any) => {
            if (
              data.editDataTime === dateTime &&
              data.editorDetails.userId === userId
            ) {
              if (this.editedUserDetails.length) {
                const editedIdx = this.editedUserDetails.findIndex(
                  (editDetail) => {
                    return (
                      editDetail.editorUserId === data.editorDetails.userId &&
                      editDetail.dateTime === data.editDataTime
                    );
                  }
                );
                if (editedIdx > -1) {
                  this.editedUserDetails[editedIdx].changesCount =
                    this.editedUserDetails[editedIdx].changesCount + 1;
                  if (this.editedUserDetails[editedIdx].data.length) {
                    const idx = this.editedUserDetails[
                      editedIdx
                    ].data.findIndex((a: any) => {
                      return a.mainLabel === data.mainLabel;
                    });

                    if (idx > -1) {
                      this.editedUserDetails[editedIdx].data[
                        idx
                      ].changesData.push(data);
                    } else {
                      this.editedUserDetails[editedIdx].data.push({
                        mainLabel: data.mainLabel,
                        changesData: [data],
                      });
                    }
                  }
                } else {
                  this.editedUserDetails.push({
                    editorUserId: data.editorDetails.userId,
                    data: [
                      {
                        mainLabel: data.mainLabel,
                        changesData: [data],
                      },
                    ],
                    dateTime: data.editDataTime,
                    editorName: data.editorDetails.name,
                    changesCount: 1,
                    comment: this.getComment(
                      this.applicantPreviewForm.data,
                      data.editDataTime,
                      data.editorDetails.userId
                    ),
                  });
                }
              } else {
                this.editedUserDetails = [
                  {
                    editorUserId: data.editorDetails.userId,
                    data: [
                      {
                        mainLabel: data.mainLabel,
                        changesData: [data],
                      },
                    ],
                    dateTime: data.editDataTime,
                    editorName: data.editorDetails.name,
                    changesCount: 1,
                    comment: this.getComment(
                      this.applicantPreviewForm.data,
                      data.editDataTime,
                      data.editorDetails.userId
                    ),
                  },
                ];
              }
            }
          });
        });
        this.editedUserDetails = this.editedUserDetails.sort(
          (a: any, b: any) => {
            a.dateTime = moment(a.dateTime).format("MM-DD-YYYY HH:mm:ss:SSS");
            b.dateTime = moment(b.dateTime).format("MM-DD-YYYY HH:mm:ss:SSS");
            return (
              new Date(a.dateTime).getTime() - new Date(b.dateTime).getTime()
            );
          }
        );
        this.editedUserDetails.map((data: any) => {
          data.dateTime = moment(data.dateTime).format(
            "MM-DD-YYYY [|] HH:mm A"
          );
        });
        this.editedUserDetails.reverse();
      })
      .catch((error) => {
        this.errorMessage = utils.errorMessage(error);
        this.isLoading = false;
      });
  }

  public getComment(data: any, dateTime: string, editorId: string) {
    let comment = "";
    for (const property in data) {
      if (property === "editedComments") {
        data[property].filter((data: any) => {
          if (
            data.dateTime === dateTime &&
            data.editorDetails.userId === editorId
          ) {
            comment = data.comment;
          }
        });
      }
    }
    return comment;
  }

  public formSelection(formSelect: string, initialLoad = false) {
    this.isFormValid = !this.isFormValid;
    setTimeout(() => {
      if (
        this.formSelected !== formSelect &&
        !this.isFormEdit &&
        this.validForm
      ) {
        this.formSelected = formSelect;
        if (this.formType === "offline") {
          this.isUpdatePageNumber = true;
          if (formSelect === "application-form") {
            this.source =
              "https://dno579h3gzw0n.cloudfront.net/files_upload/21830943-7170-424a-b571-302e51d49cae.pdf";
          }
        }
      }
    }, 50);
  }

  public getApplicantId() {
    this.isUpdatePageNumber = false;
    if (this.formSelected === "application-form") {
      this.PDFApplicationPrintName = `Training-application-${this.applicantPreviewForm.code}`;
    }
  }

  public tabSelection(tabSelect: string) {
    this.isFormValid = !this.isFormValid;
    setTimeout(() => {
      if (this.tabSelected !== tabSelect && this.validForm) {
        this.tabSelected = tabSelect;
        this.getHeaderHeight();
      }
    }, 50);
  }

  public isValidForm(event: any) {
    this.validForm = event;
  }

  public getHeaderHeight() {
    const getHeaderElement = document.querySelector(".header");
    const getSectionTabElement = document.querySelector(".form-tab");
    this.headerHeight = getHeaderElement?.clientHeight as number;
    if (this.isMobileView) {
      const getMainHeaderElementHeight = document.querySelector(
        ".mobile-nav-container"
      )?.clientHeight as number;
      this.headerHeight = this.headerHeight + getMainHeaderElementHeight;
      const getMobileElement = document.querySelector(".mobile-section-tab");
      this.mobileSectionTabHeight = getMobileElement?.clientHeight as number;
    }
    this.sectionTabHeight = getSectionTabElement?.clientHeight as number;
  }

  public reloadPage(event: any) {
    if (event) {
      this.getTrainingApplicationData();
    }
  }

  public statusUpdate(status: string) {
    this.approveStatus = status;
    this.toogleStatus = !this.toogleStatus;
  }

  public approveSubmit(
    emitedData: { status: any; comment: any; rubric: any } | undefined
  ) {
    this.isSubmit = true;
    if (emitedData || this.approveStatus) {
      if (
        this.approveStatus === "accepted" ||
        (emitedData && emitedData.status === "ACCEPTED") ||
        this.approveStatus === "not accepted" ||
        (emitedData && emitedData.status === "NOT ACCEPTED")
      ) {
        this.isLoading = true;
        this.isApproveStatus = true;
        const data = {
          id: this.applicantId,
          status: emitedData
            ? emitedData.status
            : this.approveStatus.toLocaleUpperCase(),
          comment: emitedData ? emitedData.comment : this.approveComment,
          rubric:
            emitedData && emitedData.rubric
              ? emitedData.rubric
              : this.applicantPreviewForm.rubric,
        };
        commonService
          .approveApplication(data, "training")
          .then(() => {
            this.getTrainingApplicationData();
            this.isSubmit = false;
          })
          .catch((error) => {
            this.errorMessage = utils.errorMessage(error);
            this.isLoading = false;
          });
      } else {
        const data = {
          id: this.applicantPreviewForm.id,
          rubric: this.applicantPreviewForm.rubric,
          status: this.approveStatus.toUpperCase(),
        };
        commonService
          .rubricApplication(data, "training")
          .then(() => {
            this.reloadPage(true);
          })
          .catch((error) => {
            this.errorMessage = utils.errorMessage(error);
          });
      }
    } else {
      this.isApproveStatus = false;
    }
  }

  public isEditForm(event: any) {
    this.isFormEdit = event;
  }

  public statusCheck() {
    const status = utils.scoringSectionHideShow(
      this.applicantPreviewForm.progressStatus,
      this.mostLastRecord.status,
      "",
      this.userTaskAllStatus
    );
    this.showCommentBox = status["showCommentBox"];
    this.showRubricTab = status["showRubricTab"];
    this.showCommentBoxWithDisable = status["showCommentBoxWithDisable"];
  }

  public closdedErrorPopup(event: any) {
    if (event) {
      this.errorMessage = "";
    }
  }

  public disableCommentandScoringSection(
    currentUserStatus: string,
    progressStatus: string,
    section: string
  ) {
    return utils.disableCommentandScoringSection(
      currentUserStatus,
      progressStatus,
      section
    );
  }
  public backUrl() {
    if (this.$route.query.isReport) {
      let filter = "";
      if (this.$route.query.trainingcity) {
        filter += `&trainingcity=${this.$route.query.trainingcity}`;
      }
      if (this.$route.query.gender) {
        filter += `&gender=${this.$route.query.gender}`;
      }
      if (this.$route.query.region) {
        filter += `&region=${this.$route.query.region}&regionId=${this.$route.query.regionId}`;
      }
      if (this.$route.query.age) {
        filter += `&age=${this.$route.query.age}&fromAge=${this.$route.query.fromAge}&toAge=${this.$route.query.toAge}`;
      }
      if (this.$route.query.education) {
        filter += `&education=${this.$route.query.education}`;
      }
      return `/reviewer-dashboard/peers-preview?id=${this.$route.query.id}&type=${this.$route.query.type}&isReport=${this.$route.query.isReport}&fromDate=${this.$route.query.fromDate}&toDate=${this.$route.query.toDate}${filter}`;
    } else {
      return this.isPeerPreview
        ? `/reviewer-dashboard/peers-preview?id=${this.$route.query.id}`
        : "/reviewer-dashboard/training";
    }
  }
  public isUpdateForm(event: any) {
    this.updateReviewer = event;
    this.$nextTick(() => {
      this.$bvModal.show("update-email-center");
    });
  }
  public getPeerUpdatedData(event: boolean) {
    this.$emit("getPeerUpdatedData", true);
    if (event) {
      this.$emit("isUpdateForm", event);
    }
  }
  public closeEmailPopup() {
    this.$bvModal.hide("update-email-center");
  }
  public emitApproveStatus(emitedData: any) {
    if (
      emitedData.status === "ACCEPTED" ||
      emitedData.status === "NOT ACCEPTED"
    ) {
      this.approveSubmit(emitedData);
    }
  }
}
