export enum SortingKeyName {
  firstName = "first_name",
  lastName = "last_name",
  email = "email",
  updatedAt = "updated_at",
}

export enum SortingOrderKey {
  ASC = "asc",
  DESC = "desc",
}

export enum FilterStateStorageNames {
  TRAINING = "training",
  CERTIFICATION = "certification",
  RENEWAL = "renewal",
  PEERS = "peers",
}

export interface TableSortEvent {
  apiUrl?: string | undefined;
  currentPage: number;
  filter?: string | undefined;
  perPage: number;
  sortBy: string;
  sortDesc: boolean;
}
