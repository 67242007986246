import { Component, Inject, Prop, Vue, Watch } from "vue-property-decorator";
import { gql } from "graphql-tag";
import RadioButtonComponent from "../radio-button/radio-button.component.vue";
import {
  FormFieldContents,
  scopeOfActivity,
} from "@/models/scope-of-activity/scope-of-activity.interface";
import { SCOPE_OF_ACTIVITY } from "@/graphql/scope-of-activity-query";
import SingleCheckboxComponent from "../single-checkbox-button/single-checkbox-button.component.vue";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import { User } from "@/models/common-interface/common.interface";
import { utils } from "@/utils/utils";

@Component({
  name: "scope-of-activity",
  components: {
    "b-form-radio-group": RadioButtonComponent,
    "b-form-single-checkbox": SingleCheckboxComponent,
    AppLoaderComponent,
  },
})
export default class ScopeOfActivity extends Vue {
  @Prop()
  public previewScopeOfActivityData: any;
  @Prop()
  public isPreviewForm!: boolean;
  @Prop()
  public isEditReviewerForm!: boolean;
  @Prop()
  public scopeofActivityValidation!: boolean;
  @Prop()
  public applicationName!: string;
  @Prop()
  public isChangedData!: boolean;
  @Prop()
  public editForm!: boolean;
  @Prop()
  public formType!: string;

  public applicationData: Array<scopeOfActivity> = [];
  public formData: FormFieldContents[] = [];
  public popupContentData: scopeOfActivity = {} as scopeOfActivity;
  public activeContentIndex: any = null;
  public formName = "";
  public softRefersh = "false";
  public SOAPayloadData: any = {};
  public currentRoute = "";
  public filledKeyName: string[] = [];
  public isLoading = true;
  public isShowErrorMsg = false;
  @Inject("$validator") public $validator: any;
  public originalData: any;
  public userDetails: User = {} as User;
  public editedChanges: any[] = [];
  public labels: any = {
    supervisionRequirementsFirstName: "Supervision First Name",
    supervisionRequirementsLastName: "Supervision Last Name",
    codeofEthicsFirstName: "Code of Ethics First Name",
    codeofEthicsLastName: "Code of Ethics Last Name",
    scopeofActivityFirstName: "Scope of Activity First Name",
    scopeofActivityLastName: "Scope of Activity Last Name",
  };
  public revertFieldNames: string[] = [];
  public isDisable = false;

  public created() {
    this.isDisable = utils.disableField(this.editForm, this.formType);
    this.userDetails = utils.getUserDetails();
    if (this.applicationName === "renewal") {
      this.SOAPayloadData = {
        codeOfEthics: {
          isCodeofEthicsChecked: [],
          codeofEthicsFirstName: "",
          codeofEthicsLastName: "",
        },
        scopeOfActivity: {
          isScopeofActivityChecked: [],
          scopeofActivityFirstName: "",
          scopeofActivityLastName: "",
        },
      };
    } else {
      this.SOAPayloadData = {
        supervisionRequirements: {
          issupervisionRequirementsChecked: [],
          supervisionRequirementsFirstName: "",
          supervisionRequirementsLastName: "",
        },
        codeOfEthics: {
          isCodeofEthicsChecked: [],
          codeofEthicsFirstName: "",
          codeofEthicsLastName: "",
        },
        scopeOfActivity: {
          isScopeofActivityChecked: [],
          scopeofActivityFirstName: "",
          scopeofActivityLastName: "",
        },
      };
    }
    this.currentRoute = this.$route.path;
    if (this.previewScopeOfActivityData.payloadName) {
      this.SOAPayloadData = this.previewScopeOfActivityData.data;
    } else {
      this.SOAPayloadData = this.previewScopeOfActivityData.data;
    }
    this.originalData = JSON.parse(JSON.stringify(this.SOAPayloadData));
    this.checkAllSectionValid();

    this.$apollo
      .query({
        query: gql`query ${SCOPE_OF_ACTIVITY}`,
      })
      .then((result) => {
        const temp: scopeOfActivity[] = JSON.parse(
          JSON.stringify(result.data.scopeOfActivities.data)
        );
        const supervisionIdx = temp.findIndex((data) => {
          return (
            this.applicationName === "renewal" &&
            data.attributes.requirement === "supervision"
          );
        });
        if (supervisionIdx > -1) {
          temp.splice(supervisionIdx, 1);
        }
        this.applicationData = temp;
        this.isLoading = false;
      });
  }

  public showModelPopup(
    popupContent: scopeOfActivity,
    index: number,
    formName: string
  ) {
    switch (formName) {
      case "supervision":
        this.formName = "supervisionRequirements";
        break;
      case "code_of_ethics":
        this.formName = "codeOfEthics";

        break;
      case "scope_of_activity":
        this.formName = "scopeOfActivity";
        break;
      default:
        break;
    }
    this.activeContentIndex = index;
    this.$bvModal.show("active-modal-center");
  }

  public updated() {
    window.document.onclick = (event) => {
      this.outSideClick(event);
    };
  }

  public outSideClick(event: any) {
    if (!this.$el.contains(event.target)) {
      this.checkAllSectionValid();
    }
  }

  @Watch("scopeofActivityValidation")
  public SOAValidCheck() {
    if (
      this.applicationName === "renewal"
        ? this.filledKeyName.length > 1
        : this.filledKeyName.length > 2
    ) {
      this.isShowErrorMsg = false;
      this.$emit("SOAValid", true);
    } else {
      this.$emit("SOAValid", false);
      this.isShowErrorMsg = true;
    }
  }

  public checkAllSectionValid() {
    for (const property in this.SOAPayloadData) {
      const isEmpty = Object.values(this.SOAPayloadData[property]).some(
        (data: any) => {
          return data.length === 0;
        }
      );
      const filledField =
        property === "supervisionRequirements"
          ? "supervision"
          : property === "codeOfEthics"
          ? "code_of_ethics"
          : "scope_of_activity";
      if (isEmpty) {
        const idx = this.filledKeyName.findIndex((key) => {
          return key === filledField;
        });
        if (idx > -1) {
          this.filledKeyName.splice(idx, 1);
        }
      } else {
        this.filledKeyName.push(filledField);
      }
      this.filledKeyName = [...new Set(this.filledKeyName)];
    }
  }

  public nextButton(formName: string) {
    const filledField =
      formName === "supervisionRequirements"
        ? "supervision"
        : formName === "codeOfEthics"
        ? "code_of_ethics"
        : "scope_of_activity";
    this.$validator.validate().then((validate: any) => {
      this.checkAllSectionValid();
      if (this.filledKeyName.length > 2) {
        this.isShowErrorMsg = false;
      }
      if (this.filledKeyName.includes(filledField) || validate) {
        this.softRefersh = this.changeStateForTraining(this.softRefersh);

        if (this.activeContentIndex === this.applicationData.length - 1) {
          this.$bvModal.hide("active-modal-center");
        }

        if (this.activeContentIndex < this.applicationData.length - 1) {
          const page = (this.activeContentIndex += 1);
          if (this.applicationName === "renewal") {
            if (page == 0) {
              this.formName = "codeOfEthics";
            } else if (page == 1) {
              this.formName = "scopeOfActivity";
            }
          } else {
            if (page == 0) {
              this.formName = "supervisionRequirements";
            } else if (page == 1) {
              this.formName = "codeOfEthics";
            } else if (page == 2) {
              this.formName = "scopeOfActivity";
            }
          }
          this.activeContentIndex = page;
        }
        if (this.applicationName === "renewal") {
          if (this.activeContentIndex == 0) {
            this.formName = "codeOfEthics";
          } else if (this.activeContentIndex == 1) {
            this.formName = "scopeOfActivity";
          }
        } else {
          if (this.activeContentIndex === 0) {
            this.formName = "supervisionRequirements";
          } else if (this.activeContentIndex == 1) {
            this.formName = "codeOfEthics";
          } else if (this.activeContentIndex == 2) {
            this.formName = "scopeOfActivity";
          }
        }
        const el = document.getElementsByClassName("content-div")[0];
        if (el) {
          el.scrollIntoView({ behavior: "smooth" });
        }
        this.$emit("input", this.SOAPayloadData);
      } else {
        const el = document.getElementsByClassName("is-invalid")[0];
        if (el) {
          el.scrollIntoView({ behavior: "smooth" });
        }
      }
    });
  }

  public changeStateForTraining(value: string) {
    if (value === "false") {
      return "true";
    } else {
      return "false";
    }
  }
}
