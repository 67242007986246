export const CYAPSS_INTRODUCTION = `CertifiedYoungAdults {
  certifiedYoungAdults(sort: "id:ASC") {
        data {
          id
          attributes {
            title
            description
          }
        }
    }
}
`;
