import { gql } from "graphql-tag";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CheckboxButtonComponent from "../checkbox-button/checkbox-button.component.vue";
import FileUploadComponent from "../file-upload/file-upload.component.vue";
import InputOtherComponent from "../input-other/input-other.component.vue";
import RadioButtonComponent from "../radio-button/radio-button.component.vue";
import SignatureSubmissionComponent from "../signature-submission/signature-submission.component.vue";
import SingleCheckboxButton from "../single-checkbox-button/single-checkbox-button.component.vue";
import InputTextAreaComponent from "../input-textarea/input-textarea.component.vue";
import {
  FormFieldContents,
  FormNavbar,
  RenewalApplicationsForm,
  SupervisorFieldContents,
} from "@/models/renewal-form/renewal-form.interface";
import { RENEWAL_APPLICATION_FORM_QUERY } from "@/graphql/renewal-application-form-query";
import { utils } from "@/utils/utils";
import { renewalFormApi } from "@/providers/apis/renewal-application";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import ScopeOfActivity from "../scope-of-activity/scope-of-activity-component.vue";
import { User } from "@/models/common-interface/common.interface";
import EditHistoryComponent from "../edit-history/edit-history.component.vue";
import ErrorPopupComponent from "../error-popup/error-popup.component.vue";
import FormInputDatepickerComponent from "../form-input-datepicker/form-input-datepicker.component.vue";
import { applicantDetialsApi } from "@/providers/apis/applicant-dashboard";
import PreviewPopupComponent from "../preview-popup/preview-popup.component.vue";
import moment from "moment";
@Component({
  name: "b-form-renewal-preview-form",
  components: {
    "b-form-radio-group": RadioButtonComponent,
    "b-form-checkbox": CheckboxButtonComponent,
    "b-form-upload-file": FileUploadComponent,
    "b-form-single-checkbox": SingleCheckboxButton,
    "b-form-checkbox-input": InputOtherComponent,
    "b-form-signature-submission": SignatureSubmissionComponent,
    "b-form-textarea": InputTextAreaComponent,
    "code-of-ethics-and-scope-of-activity": ScopeOfActivity,
    "b-form-input-datepicker": FormInputDatepickerComponent,
    AppLoaderComponent,
    EditHistoryComponent,
    ErrorPopupComponent,
    PreviewPopupComponent,
  },
})
export default class RenewalPreviewFormComponent extends Vue {
  @Prop()
  public previewData: any;
  @Prop()
  public tabSelect!: string;
  @Prop()
  public isReviewerPreviewForm!: string;
  @Prop()
  public applicationId!: string;
  @Prop()
  public progressStatus: any;
  @Prop()
  public checkFormValidation!: boolean;
  @Prop()
  public isUserApplication!: boolean;
  @Prop()
  public isMigrationForm!: boolean;
  @Prop()
  public supervisorData!: any[];

  public applicationData: Array<RenewalApplicationsForm> = [];
  public formData: Array<FormFieldContents> = [];
  public applicationNavData: Array<FormNavbar> = [];
  public limitationList: any;
  public previewPayload: any = {};
  public addTrainingForm: any[] = [];
  public isMobileView = false;
  public isLoading = false;
  public statusName = "";
  public vaildFile = true;
  public isFileValid = false;
  public isEditReviewerForm = false;
  public currentRoute = "";
  public eventBind: any = {
    acceptNumberOnly: this.numberValidation,
    telCharacter: this.telCharacterValidate,
    onlyAlphabets: this.onlyAlphabets,
  };
  public disabled = true;
  public checkValidation = false;
  public editIndex!: number;
  public isLargeLoading = false;
  public isTrainingReferesh = "false";
  public notFilesUploadedKeyNames: string[] = [];
  public checkSOAValid = false;
  public validSOA = true;
  public originalData: any = {};
  public editApplication = false;
  public filesEdit = ["proofOfAttendance"];
  public userDetails: User = {} as User;
  public isClickSavebtn = "false";
  public isConfirm = false;
  public removeMultiForm = {};
  public trainingDuplicateIndexs: number[] = [];
  public revomedTraningIndex: number[] = [];
  public errorMessage = "";
  public supervisorUIUpdate = false;
  public supervisorFormFields: SupervisorFieldContents[] = [];
  public tempSupervisorFormFields: SupervisorFieldContents =
    {} as SupervisorFieldContents;
  public supervisorList: any[] = [];
  @Prop()
  public userId: any;
  public trainingIndex = 1;
  public isAvailableTraining = false;
  public availableTraining: any[] = [];
  public imgType = ["jpg", "png", "jpeg", "tiff"];
  public S3Path = "";
  public previewFile: any[] = [];
  public showPreviewPopup = false;
  public educationLists: any;

  public async created() {
    this.userDetails = utils.getUserDetails();
    this.isLoading = true;
    if (utils.checkIsMobileView() || utils.checkIsTabView()) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    this.currentRoute = this.$route.path;
    let payloadStorage: any = localStorage.getItem("renewalPayLoadData");
    if (payloadStorage && this.currentRoute.split("/")[1] === "form") {
      payloadStorage = JSON.parse(payloadStorage);
      this.previewPayload = { ...payloadStorage };
    } else {
      this.previewPayload = this.previewData;
    }
    await this.$apollo
      .query({
        query: gql`query ${RENEWAL_APPLICATION_FORM_QUERY}`,
      })
      .then((result) => {
        this.applicationData = result.data.renewalApplicationForms.data;
        this.applicationNavData =
          this.applicationData[0].attributes.renewalNavbar;
        this.formData = this.applicationData[0].attributes.form_fields;
        this.tempSupervisorFormFields = JSON.parse(
          JSON.stringify(
            this.applicationData[0].attributes.form_fields[3].fields
              .formInputs[0]
          )
        );
        this.limitationList = this.formData.length - 1;
        this.showTrainingAndSummaryForm();
        this.isLoading = false;
        this.fullNameAndAddressBuild();
        if (this.isUserApplication) {
          const getSidebarElement = document.querySelector(".b-sidebar-body");
          if (getSidebarElement) {
            getSidebarElement.scrollTop = 0;
          }
        }
      });
    if (this.previewPayload.renewalSupervisorInformation) {
      const supervisorEmail: any[] = [];
      this.supervisorData &&
        this.supervisorData.forEach((data: any) => {
          supervisorEmail.push(data.email.toLowerCase());
        });

      this.previewPayload.renewalSupervisorInformation.length &&
        this.previewPayload.renewalSupervisorInformation.map(
          (supervisor: any) => {
            const refIdx = supervisorEmail.findIndex(
              (supervisorEmail: string) => {
                return (
                  supervisorEmail.toLowerCase() ===
                  supervisor.supervisorEmail.toLowerCase()
                );
              }
            );
            if (refIdx > -1) {
              this.supervisorList.push(this.supervisorData[refIdx]);
            }
          }
        );
    }
    if (
      !this.isMigrationForm &&
      this.previewPayload.renewalTrainingInformation
    ) {
      for (
        let i = 0;
        i < this.previewPayload.renewalTrainingInformation.length;
        i++
      ) {
        if (this.previewPayload.renewalTrainingInformation[i]) {
          this.previewPayload.renewalTrainingInformation[i].indexPosition = i;
          this.previewPayload.renewalTrainingInformation[i].trainingDuration =
            this.previewPayload.renewalTrainingInformation[i].trainingDuration
              ? this.previewPayload.renewalTrainingInformation[i]
                  .trainingDuration
              : 0;
          this.previewPayload.renewalTrainingInformation[i].isNew = false;
        }
      }
      if (
        this.previewPayload["History"] &&
        this.previewPayload["History"].length
      ) {
        this.previewPayload["History"].map((data: any) => {
          if (data["removedTrainingIdx"]) {
            data["removedTrainingIdx"].forEach((removedIdx: any) => {
              if (this.revomedTraningIndex.length) {
                if (!this.revomedTraningIndex.includes(removedIdx.index)) {
                  this.revomedTraningIndex.push(removedIdx.index);
                }
              } else {
                this.revomedTraningIndex = [removedIdx.index];
              }
            });
          }
        });
      }
    }
    this.originalData = JSON.parse(JSON.stringify(this.previewPayload));
    this.getTraningDetail();
  }

  public async onCilckSave() {
    this.checkValidation = true;
    this.isFileValid = !this.isFileValid;
    this.trainingDuplicateIndexs = utils.duplicateValueIdx(
      this.previewPayload,
      "renewalTrainingInformation",
      false,
      "trainingName"
    );
    if (this.trainingDuplicateIndexs.length) {
      this.$emit("isTrainingAlreadyExists", true);
    }
    if (
      this.previewPayload.renewalSupervisorInformation &&
      this.previewPayload.renewalSupervisorInformation.length
    ) {
      const supervisorEmails = [];
      if (
        this.previewPayload.renewalSupervisorInformation[this.editIndex]
          ?.supervisorEmail !==
        this.originalData.renewalSupervisorInformation[this.editIndex]
          ?.supervisorEmail
      ) {
        const data = {
          oldEmail:
            this.originalData.renewalSupervisorInformation[this.editIndex]
              .supervisorEmail,
          newEmail:
            this.previewPayload.renewalSupervisorInformation[this.editIndex]
              .supervisorEmail,
        };
        supervisorEmails.push(data);
      }
      if (supervisorEmails && supervisorEmails.length) {
        this.previewPayload.supervisorEmails = supervisorEmails;
      }
    }

    this.$validator.validateAll().then(async (validate) => {
      if (
        validate &&
        this.vaildFile &&
        !this.notFilesUploadedKeyNames.length &&
        this.validSOA &&
        this.trainingDuplicateIndexs.length === 0
      ) {
        const duplicateSupervisorEmail =
          this.previewPayload.renewalSupervisorInformation.filter(
            (supervisor: any) => {
              if (
                supervisor.supervisorEmail.toLowerCase() ===
                this.previewPayload.renewalApplicationInformation.email.toLowerCase()
              ) {
                return supervisor;
              }
            }
          );
        if (duplicateSupervisorEmail.length) {
          this.errorMessage =
            "Applicant email id and supervisor email id must be different *";
          return;
        }
        if (this.tabSelect === "renewal-supervisor-details") {
          const duplicateReferenceEmail =
            this.originalData.renewalSupervisorInformation.filter(
              (supervisor: any) => {
                if (
                  this.previewPayload &&
                  this.previewPayload.supervisorEmails &&
                  this.previewPayload.supervisorEmails[0] &&
                  supervisor.supervisorEmail.toLowerCase() ===
                    this.previewPayload.supervisorEmails[0].newEmail.toLowerCase()
                ) {
                  return supervisor;
                }
              }
            );
          if (duplicateReferenceEmail.length) {
            this.errorMessage = `Supervisor email id must be different *`;
            return;
          }
        }
        if (this.availableTraining && this.availableTraining.length) {
          this.availableTraining.map((training: any) => {
            this.previewPayload.renewalTrainingInformation.push(training);
          });
        }
        if (this.currentRoute.split("/")[2] === "my-application") {
          const id = this.applicationId;
          this.isLargeLoading = true;
          renewalFormApi
            .updateReviewerRenewalForm(this.previewPayload, id)
            .then(() => {
              this.isLargeLoading = false;
              this.isEditReviewerForm = false;
              if (
                this.previewPayload.supervisorEmails &&
                this.previewPayload.supervisorEmails.length
              ) {
                const data = {
                  isUpdateData: false,
                  isUpdateEmail: true,
                  type: "Supervisor",
                  email:
                    this.previewPayload.renewalSupervisorInformation[0]
                      .newEmail,
                };
                this.$emit("getPeerUpdatedData", data);
              } else {
                const data = {
                  isUpdateData: true,
                  isUpdateEmail: false,
                };
                this.$emit("getPeerUpdatedData", data);
              }
              this.$emit("editIndex", this.editIndex);
              this.previewPayload.supervisorEmails = [];
              this.$emit("isEditForm", false);
            })
            .catch((error: any) => {
              this.errorMessage = utils.errorMessage(error);
              this.isLargeLoading = false;
              this.$bvModal.show("error-modal-center");
              setTimeout(() => {
                this.$bvModal.hide("error-modal-center");
              }, 3000);
            });
        } else {
          this.editApplication = true;
          this.isClickSavebtn = this.changeStateForFieldUpdate(
            this.isClickSavebtn
          );
        }
      } else {
        setTimeout(() => {
          const el = document.getElementsByClassName("is-invalid")[0];
          if (el) {
            el.scrollIntoView({ behavior: "smooth", block: "end" });
          }
        }, 10);
      }
    });
  }

  public changeStateForFieldUpdate(value: string) {
    if (value === "false") {
      return "true";
    } else {
      return "false";
    }
  }

  public onCilckEdit(index: number) {
    this.editIndex = index;
    this.isEditReviewerForm = true;
    this.disabled = false;
    this.$emit("isEditForm", true);
  }

  public onCancelEdit() {
    this.notFilesUploadedKeyNames = [];
    this.isEditReviewerForm = false;
    this.trainingDuplicateIndexs = [];
    this.disabled = true;
    this.editApplication = false;
    if (this.currentRoute.split("/")[2] === "my-application") {
      this.$emit("getPeerUpdatedData", false);
    } else {
      this.$emit("getUpdatedData");
      this.$emit("isEditForm", false);
    }
  }

  public isRevertedChanges(event: boolean) {
    if (event === true) {
      this.$emit("getUpdatedData");
      this.$emit("isEditForm", false);
    }
  }

  public editedPreviewPayloadData(event: any) {
    if (event) {
      this.previewPayload = event;
    }
  }

  public isConfirmChanges(event: boolean) {
    this.isConfirm = event;
    if (this.isConfirm) {
      const id = this.$route.path.split("/")[3];
      this.isLargeLoading = true;
      this.checkValidation = false;
      this.previewPayload.renewalApplicationInformation.fullName = "";
      this.previewPayload.renewalApplicationInformation.fullAddress = "";
      if (this.previewPayload.renewalSupervisorInformation) {
        for (
          let i = 0;
          i < this.previewPayload.renewalSupervisorInformation.length;
          i++
        ) {
          this.previewPayload.renewalSupervisorInformation[
            i
          ].supervisorFullName = "";
        }
      }
      renewalFormApi
        .updateReviewerRenewalForm(this.previewPayload, id)
        .then(() => {
          this.editApplication = false;
          this.isLargeLoading = false;
          this.isEditReviewerForm = false;
          this.disabled = true;
          this.$emit("getUpdatedData");
          this.$emit("isEditForm", false);
          if (
            this.previewPayload.supervisorEmails &&
            this.previewPayload.supervisorEmails.length
          ) {
            let email;
            const data = {
              isUpdateData: false,
              isUpdateEmail: true,
              email: this.previewPayload.supervisorEmails[0].newEmail,
            };
            this.$emit("isUpdateForm", data);
          } else {
            const data = {
              isUpdateData: true,
              isUpdateEmail: false,
            };
            this.$emit("isUpdateForm", data);
          }
        })
        .catch((error) => {
          this.errorMessage = utils.errorMessage(error);
          this.isLargeLoading = false;
        });
    }
  }

  public removedTrainingIdx(event: any[]) {
    event.forEach((data: any) => {
      if (this.revomedTraningIndex.length) {
        if (!this.revomedTraningIndex.includes(data.index)) {
          this.revomedTraningIndex.push(data.index);
        }
      } else {
        this.revomedTraningIndex = [data.index];
      }
    });
  }

  public pushTrainingForm() {
    this.addTrainingForm.push(this.formData[1].fields.formInputs);
    let keys: any = {};
    if (this.isReviewerPreviewForm) {
      keys = {
        trainingName: "",
        trainingDuration: "",
        trainingFormat: "",
        proofOfAttendance: [],
        isNew: true,
      };
      if (this.currentRoute.split("/")[2] !== "my-application") {
        keys.isNewlyAdded = true;
        keys.isSaved = false;
      }
    } else {
      keys = {
        trainingName: "",
        trainingDuration: "",
        trainingFormat: "",
        proofOfAttendance: [],
        isNew: true,
      };
    }
    this.previewPayload.renewalTrainingInformation.push(keys);
  }

  public addTraining() {
    this.isFileValid = !this.isFileValid;
    const renewalFormData = this.previewPayload.renewalTrainingInformation;
    if (renewalFormData.length) {
      this.$validator.validateAll().then((validate) => {
        if (validate && !this.notFilesUploadedKeyNames.length) {
          if (this.addTrainingForm.length) {
            this.pushTrainingForm();
          }
        } else {
          setTimeout(() => {
            const el = document.getElementsByClassName("is-invalid")[0];
            if (el) {
              el.scrollIntoView({ behavior: "smooth", block: "end" });
            }
          }, 10);
        }
      });
    } else {
      this.pushTrainingForm();
    }
  }

  public scopeOfActivityValidation(event: boolean) {
    this.validSOA = event;
  }

  public removeTraining(index: number) {
    this.notFilesUploadedKeyNames = [];
    if (!this.isReviewerPreviewForm) {
      this.previewPayload.renewalTrainingInformation.splice(index, 1);
      this.addTrainingForm.splice(index, 1);
    } else {
      delete this.previewPayload.renewalTrainingInformation[index].fullName;
      this.removeMultiForm = {
        index: index,
        formName: "renewalTrainingInformation",
        data: JSON.parse(
          JSON.stringify(this.previewPayload.renewalTrainingInformation[index])
        ),
      };
      this.isTrainingReferesh = this.changeStateForTraining(
        this.isTrainingReferesh
      );
      this.editApplication = true;
      if (
        this.previewPayload.renewalTrainingInformation[index].isNewlyAdded ===
        true
      ) {
        this.previewPayload.renewalTrainingInformation.splice(index, 1);
        this.addTrainingForm.splice(index, 1);
      } else {
        this.previewPayload.renewalTrainingInformation[index].isRemoved = true;
        this.previewPayload.renewalTrainingInformation[index].isSaved = false;
      }
      if (this.currentRoute.split("/")[2] === "my-application") {
        if (
          this.previewPayload.renewalTrainingInformation[index].isRemoved ===
          true
        ) {
          this.addTrainingForm.splice(index, 1);
          this.previewPayload.renewalTrainingInformation.splice(index, 1);
        }
      }
    }
    this.showTrainingAndSummaryForm();
    setTimeout(() => {
      this.isTrainingReferesh = this.changeStateForTraining(
        this.isTrainingReferesh
      );
    }, 10);
  }

  public changeStateForTraining(value: string) {
    if (value === "false") {
      return "true";
    } else {
      return "false";
    }
  }

  public fieldUpdate(inputName: string) {
    if (!this.isEditReviewerForm && !this.isReviewerPreviewForm) {
      const nameNotVisiable = [
        "firstName",
        "lastName",
        "supervisorFirstName",
        "supervisorLastName",
        "addressLine1",
        "addressLine2",
        "state",
        "city",
        "zip",
      ];
      return nameNotVisiable.includes(inputName);
    } else {
      const nameNotVisiable = !this.isEditReviewerForm
        ? [
            "firstName",
            "lastName",
            "supervisorFirstName",
            "supervisorLastName",
            "addressLine1",
            "addressLine2",
            "state",
            "city",
            "zip",
          ]
        : ["fullName", "fullAddress", "supervisorFullName"];
      return nameNotVisiable.includes(inputName);
    }
  }

  public showTrainingAndSummaryForm() {
    if (this.previewPayload.renewalTrainingInformation) {
      const renewalFormData = this.previewPayload.renewalTrainingInformation;
      if (renewalFormData.length) {
        this.addTrainingForm = [];
        renewalFormData.forEach(() => {
          this.addTrainingForm.push(this.formData[1].fields.formInputs);
        });
      }
    }
    if (this.previewPayload.renewalSupervisorInformation) {
      const renewalSummaryFormData =
        this.previewPayload.renewalSupervisorInformation;
      if (renewalSummaryFormData.length) {
        this.supervisorFormFields = [];
        renewalSummaryFormData.forEach(() => {
          this.supervisorFormFields.push(this.tempSupervisorFormFields);
        });
      }
    }
  }

  public fullNameAndAddressBuild() {
    this.previewPayload.renewalApplicationInformation.fullName = `${this.previewPayload.renewalApplicationInformation.firstName} ${this.previewPayload.renewalApplicationInformation.lastName}`;
    const fullAddress = utils.addressBuilder(
      this.previewPayload,
      "renewalApplicationInformation",
      "default"
    );
    this.previewPayload.renewalApplicationInformation.fullAddress = fullAddress;
    if (this.previewPayload.renewalSupervisorInformation) {
      for (
        let i = 0;
        i < this.previewPayload.renewalSupervisorInformation.length;
        i++
      ) {
        this.previewPayload.renewalSupervisorInformation[
          i
        ].supervisorFullName = `${this.previewPayload.renewalSupervisorInformation[i].supervisorFirstName} ${this.previewPayload.renewalSupervisorInformation[i].supervisorLastName}`;
      }
    }
  }

  public editForm(slug: string) {
    this.$emit("applicationSlug", slug);
  }

  public numberValidation(evt: any, length?: number) {
    utils.onlyNumberKey(evt, length);
  }

  public telCharacterValidate(evt: any, length?: number) {
    utils.telCharacterValidate(evt, length);
  }

  public onlyAlphabets(evt: any, fieldName = "") {
    utils.onlyAlphabets(evt, fieldName);
  }

  public emptyEvent() {
    return;
  }

  public fieldValidation(
    value: any,
    eventName: string,
    fieldType: string,
    maxLength = 0,
    fieldName = ""
  ) {
    if (fieldType === "number") {
      this.eventBind[eventName](value, maxLength);
    } else if (fieldName === "state") {
      this.eventBind[eventName](value, fieldName);
    } else {
      this.eventBind[eventName](value);
    }
  }

  public fileValid(event: any) {
    this.vaildFile = event;
  }

  public allFilesUploaded(event: any) {
    if (this.notFilesUploadedKeyNames.includes(event)) {
      this.notFilesUploadedKeyNames.splice(event, 1);
    }
  }

  public notFileUploaded(event: string) {
    if (!this.notFilesUploadedKeyNames.includes(event)) {
      this.notFilesUploadedKeyNames.push(event);
    }
  }

  public resetFileKey(event: boolean) {
    if (event) {
      this.notFilesUploadedKeyNames = [];
    }
  }

  @Watch("checkFormValidation")
  public validation() {
    this.checkValidation = true;
    this.$validator.validateAll().then((validate: any) => {
      if (!this.isMigrationForm) {
        if (validate) {
          this.$emit("isValidForm", true);
        } else {
          const el = document.getElementsByClassName("is-invalid")[0];
          if (el) {
            el.scrollIntoView({ behavior: "smooth", block: "end" });
          }
          this.$emit("isValidForm", false);
        }
      }
    });
  }

  // This method for change status word for class

  public statusWordChange(status: string) {
    return utils.statusCaseChange(status);
  }

  public classNameChooser(inputClass: string) {
    return utils.classNameChooser(inputClass);
  }

  public closdedErrorPopup(event: any) {
    if (event) {
      this.errorMessage = "";
    }
  }
  public getTraningDetail() {
    applicantDetialsApi
      .getContinuingEducation(this.userId)
      .then((result) => {
        this.educationLists = result.data.educations;
        this.educationLists = this.educationLists.filter((item: any) => {
          item.trainingDate = item.trainingDate
            ? moment(item.trainingDate).format("MM-DD-YYYY")
            : null;
          item.trainingName = item.title;
          item.proofOfAttendance = item.attachments;
          item.trainingDuration = item.trainingHours;
          return item.isArchived === false;
        });
        this.isLoading = false;
        if (this.previewPayload) {
          this.previewPayload?.renewalTrainingInformation?.filter(
            (training: any) => {
              this.educationLists.filter((education: any) => {
                if (training.id === education.id) {
                  education.isChecked = true;
                }
              });
            }
          );
        }
      })
      .catch((error) => {
        this.errorMessage = utils.errorMessage(error);
        this.isLoading = false;
      });
  }
  public checked(training: any) {
    this.isAvailableTraining = true;
    let data: any = {};
    data = {
      trainingName: training.trainingName,
      trainingFormat: training.trainingFormat,
      trainingDuration: training.trainingDuration,
      proofOfAttendance: training.proofOfAttendance,
      id: training.id,
    };
    if (this.currentRoute.split("/")[2] !== "my-application") {
      data.isNewlyAdded = true;
      data.isSaved = false;
    }

    if (training.isChecked) {
      this.availableTraining.push(data);
    } else {
      this.availableTraining = this.availableTraining.filter(
        (item: any) => item.id !== training.id
      );
    }
  }
  public showMore(type: boolean) {
    this.isLoading = true;
    if (type) {
      this.trainingIndex = 1;
    } else {
      this.trainingIndex = this.trainingIndex + 2;
    }
    setTimeout(() => {
      this.isLoading = false;
    }, 1000);
  }
  public previewForm(rowData: any) {
    rowData.attachments.forEach((item: any) => {
      item.uploadedFileName = item.fileName;
      const temp = JSON.parse(JSON.stringify(item));
      const previewData = temp;
      if (this.imgType.includes(previewData.uploadedFileName.split(".")[1])) {
        const img = new Image();
        img.src = `${this.S3Path}/${previewData.uploadedFileName}`;
        previewData.width = img.width;
        previewData.height = img.height;
      }
      if (!this.previewFile.length) {
        this.previewFile = [previewData];
      } else {
        this.previewFile.push(previewData);
      }
      this.showPreviewPopup = true;
    });
  }
  public hidePreviewPopup(event: boolean) {
    if (event === true) {
      this.showPreviewPopup = false;
      this.previewFile = [];
    }
  }
}
