import { Component, Vue } from "vue-property-decorator";
import { gql } from "graphql-tag";
import { CYAPSS_INTRODUCTION } from "@/graphql/cyapss-introduction-query";
import { IntroductionData } from "@/models/cyapss-introduction/cyapss-introduction.interface";

@Component({})
export default class IntroductionComponent extends Vue {
  public introductionData: IntroductionData[] = [];

  public async created() {
    await this.$apollo
      .query({
        query: gql`query ${CYAPSS_INTRODUCTION}`,
      })
      .then((result) => {
        this.introductionData = result.data.certifiedYoungAdults.data;
      });
  }
}
