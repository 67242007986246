import { Component, Vue } from "vue-property-decorator";
import CyapssHeader from "../cyapss-header/cyapss-header.vue";
import CyapssFooterComponent from "../cyapss-footer/cyapss-footer.component.vue";
@Component({
  components: {
    CyapssHeader,
    CyapssFooterComponent,
  },
})
export default class BaseLayout extends Vue {
  public headerHeight = 0;
  mounted() {
    const getHeaderElement = document.getElementById("header");
    this.headerHeight = getHeaderElement?.scrollHeight as number;
  }
}
