import { Component, Vue } from "vue-property-decorator";
import { gql } from "graphql-tag";
import { ApplicationPagesData } from "./application-pages.interface";
import { APPLICATION_PAGES_QUERY } from "@/graphql/application-pages-query ";
import MoreInformation from "../more-information/more-information.vue";

@Component({
  components: {
    MoreInformation,
  },
})
export default class ApplicationPagesComponent extends Vue {
  public applicationPagesData: ApplicationPagesData[] = [];
  public currentRoute = "";
  public param = "";

  public async created() {
    this.currentRoute = this.$route.path;
    this.param = this.$route.query.form.toString();
    await this.$apollo
      .query({
        query: gql`query ${APPLICATION_PAGES_QUERY}`,
      })
      .then((result) => {
        this.applicationPagesData = result.data.applicationPages.data;
      });
  }

  public navigateTo(url: string) {
    if (url.includes("http")) {
      window.open(url, "_blank");
    } else {
      this.$router.push(url);
    }
  }
}
