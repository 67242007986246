export const MORE_INFORMATION_QUERY = `ForMoreInformations {
    forMoreInformations(sort: "id:ASC") {
        data {
            id
            attributes {
                more_information{
                  label
                  description
                }
            }
          }
    }
}`;
