import { Component, Vue } from "vue-property-decorator";
import { gql } from "graphql-tag";
import RadioButtonComponent from "../radio-button/radio-button.component.vue";
import CheckboxButtonComponent from "../checkbox-button/checkbox-button.component.vue";
import SingleCheckboxButton from "@/components/single-checkbox-button/single-checkbox-button.component.vue";
import InputOtherComponent from "../input-other/input-other.component.vue";
import SignatureSubmissionComponent from "../signature-submission/signature-submission.component.vue";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import { CERTIFICATION_APPLICATION_FORM_QUERY } from "@/graphql/certification-application-form-query";
import EligibilityCriteriaComponent from "../eligibility-criteria/eligibility-criteria.component.vue";
import {
  CertificationForm,
  FormFieldContents,
  FormNavbar,
  formtInputData,
} from "@/models/certification-form/certification-form.interface";
import CertificationPreviewForm from "../certification-preview-form/certification-preview-form.component.vue";
import { certificationFormApi } from "@/providers/apis/certification-form";
import { utils } from "@/utils/utils";
import moment from "moment";
import ScopeOfActivity from "../scope-of-activity/scope-of-activity-component.vue";
import ErrorPopupComponent from "../error-popup/error-popup.component.vue";
import FormInputDatepickerComponent from "../form-input-datepicker/form-input-datepicker.component.vue";

@Component({
  name: "certification-form",
  components: {
    "b-form-radio-group": RadioButtonComponent,
    "b-form-checkbox": CheckboxButtonComponent,
    "b-form-single-checkbox": SingleCheckboxButton,
    "b-form-checkbox-input": InputOtherComponent,
    "b-form-signature-submission": SignatureSubmissionComponent,
    "certification-preview-form": CertificationPreviewForm,
    "code-of-ethics-and-scope-of-activity": ScopeOfActivity,
    "b-form-input-datepicker": FormInputDatepickerComponent,
    AppLoaderComponent,
    EligibilityCriteriaComponent,
    ErrorPopupComponent,
  },
})
export default class CertificationApplicationForm extends Vue {
  public formPagination = 0;
  public formName = "certificationPartTwoApplicationForm";
  public applicationData: Array<CertificationForm> = [];
  public applicationNavData: FormNavbar[] = [];
  public formData: FormFieldContents[] = [];
  public isNavLoad = false;
  public isScroll = false;
  public isValid = false;
  public formDataResult: any;
  public formApplicationId = "";
  public currentForm: any;
  public signatureInvalid = false;
  public saveDataDraft = false;
  public referenceEmailDuplicateIndexs: number[] = [];
  public supervisorEmailDuplicateIndexs: number[] = [];
  public payLoadData: any = {
    fillStatus: "DRAFT",
    id: "",
    isEligibility: false,
    certificationPartTwoApplicationForm: {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      addressLine1: "",
      addressLine2: "",
      state: "",
      city: "",
      zip: "",
    },
    certificationDetails: {
      successfullyCompleted: "",
      substanceAbuse: "",
      peerSupportServices: "",
      supervisionRequirements: "",
      handbook: {
        supervisionRequirements: {
          issupervisionRequirementsChecked: [],
          supervisionRequirementsFirstName: "",
          supervisionRequirementsLastName: "",
        },
        codeOfEthics: {
          isCodeofEthicsChecked: [],
          codeofEthicsFirstName: "",
          codeofEthicsLastName: "",
        },
        scopeOfActivity: {
          isScopeofActivityChecked: [],
          scopeofActivityFirstName: "",
          scopeofActivityLastName: "",
        },
      },
    },
    certificationPartTwoSupervisorInformation: [
      {
        supervisorAgencyOrOrganization: "",
        supervisorPositionOrTitle: "",
        stillEmployed: "",
        startDate: "",
        endDate: "",
        supervisorFirstName: "",
        supervisorLastName: "",
        supervisorCredentials: "",
        supervisorPhone: "",
        supervisorEmail: "",
        descripePeerSupportResponsibity: "",
      },
    ],
    certificationPartTwoRefererenceInformation: [
      {
        firstName: "",
        lastName: "",
        referencePhoneNumber: "",
        referenceEmailAddress: "",
        reference: 1,
      },
      {
        firstName: "",
        lastName: "",
        referencePhoneNumber: "",
        referenceEmailAddress: "",
        reference: 2,
      },
      {
        firstName: "",
        lastName: "",
        referencePhoneNumber: "",
        referenceEmailAddress: "",
        reference: 3,
      },
    ],
    certificationSignature: {
      isSignature: "",
      submissionDate: moment().format("MM-DD-YYYY"),
    },
  };
  public formTitle = {
    main_title: "",
    application_name: "",
  };
  public isLoading = false;
  public isEligibility = false;
  public certificateApplicantId = "";
  public userDetails: any;
  public isReviewerUser: any;
  public applicantFullDetials: any;
  public formCompletedIndex: number[] = [];
  public isEdit = false;
  public eventBind: any = {
    acceptNumberOnly: this.numberValidation,
    avoidNumbersAndSpecialChar: this.avoidNumbersAndSpecialCharacter,
    telCharacter: this.telCharacterValidate,
    onlyAlphabets: this.onlyAlphabets,
  };
  public checkValidForm = false;
  public mainId = "app";
  public currentYearMonth = "";
  public currentMonth = "";
  public hideDiv: string[] = ["endDate"];
  public checkField: string[] = ["stillEmployed"];
  public endDateShowIdx: number[] = [];
  public checkSOAValid = false;
  public validSOA = true;
  public errorMessage = "";
  public supervisorFormFields: formtInputData[] = [];
  public tempSupervisorFormFields: formtInputData = {} as formtInputData;
  public supervisorUIUpdate = false;
  public supervisorEndDateShowIdx: number[] = [];
  public supervisorStartDateShowIdx: number[] = [];

  constructor() {
    super();
  }

  public async created() {
    const currentMonth = `${moment().month() + 1}`;
    this.currentYearMonth = `${moment().year()}-${
      currentMonth.length == 1 ? "0" : ""
    }${currentMonth}`;
    this.userDetails = utils.getUserDetails();
    const token = localStorage.getItem("access_token");
    if (this.userDetails) {
      const userRolesData = this.userDetails.roles;
      this.isReviewerUser =
        userRolesData && userRolesData.indexOf("REVIEWER") !== -1;
    } else {
      this.isReviewerUser = false;
    }
    let payloadStorage: any = localStorage.getItem("certificationFormPayload");
    if (payloadStorage) {
      payloadStorage = JSON.parse(payloadStorage);
      this.payLoadData = { ...payloadStorage };
      if (this.payLoadData.isEligibility) {
        this.isEligibility = true;
        this.formPagination = 1;
      }
    }
    const formFilledIndex: any = localStorage.getItem(
      "certificationFormFilledIndex"
    );
    if (formFilledIndex) {
      formFilledIndex.split(",").forEach((data: string) => {
        this.formCompletedIndex.push(Number(data));
      });
    } else {
      if (!this.formCompletedIndex.includes(this.formPagination)) {
        this.formCompletedIndex.push(this.formPagination);
      }
    }

    if (!this.isReviewerUser && token && !payloadStorage) {
      const userDetailsEmail = this.userDetails.email;
      certificationFormApi
        .getCertificateDetials(userDetailsEmail)
        .then((result: any) => {
          this.applicantFullDetials = result.data.data;
          this.payLoadData.certificationPartTwoApplicationForm.firstName =
            this.applicantFullDetials.certificationPartOneBasicDetails.firstName;
          this.payLoadData.certificationPartTwoApplicationForm.lastName =
            this.applicantFullDetials.certificationPartOneBasicDetails.lastName;
          this.payLoadData.certificationPartTwoApplicationForm.phone =
            this.applicantFullDetials.certificationPartOneBasicDetails.primaryPhone;
          this.payLoadData.certificationPartTwoApplicationForm.email =
            this.applicantFullDetials.certificationPartOneBasicDetails.email;
          this.payLoadData.certificationPartTwoApplicationForm.addressLine1 =
            this.applicantFullDetials.certificationPartOneBasicDetails.addressLine1;
          this.payLoadData.certificationPartTwoApplicationForm.addressLine2 =
            this.applicantFullDetials.certificationPartOneBasicDetails.addressLine2;
          this.payLoadData.certificationPartTwoApplicationForm.state =
            this.applicantFullDetials.certificationPartOneBasicDetails.state;
          this.payLoadData.certificationPartTwoApplicationForm.city =
            this.applicantFullDetials.certificationPartOneBasicDetails.city;
          this.payLoadData.certificationPartTwoApplicationForm.zip =
            this.applicantFullDetials.certificationPartOneBasicDetails.zip;
        })
        .catch((error) => {
          this.errorMessage = utils.errorMessage(error);
          this.isLoading = false;
          this.errorMessage = utils.errorMessage(error);
        });
    } else {
      if (!payloadStorage) {
        this.$router.push({
          name: "login-page",
          query: { application: "certification-form" },
        });
      }
    }

    if (this.formPagination === 1) {
      this.isNavLoad = true;
    }
    this.$apollo
      .query({
        query: gql`query ${CERTIFICATION_APPLICATION_FORM_QUERY}`,
      })
      .then((result) => {
        this.applicationData = result.data.certificationApplicationForms.data;
        this.formTitle = {
          main_title: this.applicationData[0].attributes.main_title,
          application_name: this.applicationData[0].attributes.application_name,
        };
        this.applicationNavData = this.applicationData[0].attributes.navbar;
        this.formData = this.applicationData[0].attributes.form_fields;
        this.tempSupervisorFormFields = JSON.parse(
          JSON.stringify(
            this.applicationData[0].attributes.form_fields[2].fields
              .formInputs[0]
          )
        );
        if (this.payLoadData.certificationPartTwoSupervisorInformation.length) {
          const addSupervisorForm =
            this.payLoadData.certificationPartTwoSupervisorInformation.length;
          for (let index = 0; index < addSupervisorForm; index++) {
            this.supervisorFormFields.push(this.tempSupervisorFormFields);
          }
        }
        for (let index = 0; index < this.checkField.length; index++) {
          for (
            let i = 0;
            i <
            this.payLoadData.certificationPartTwoSupervisorInformation.length;
            i++
          ) {
            if (
              this.payLoadData.certificationPartTwoSupervisorInformation[i]
                .stillEmployed
            ) {
              this.fieldUpdate(this.checkField[index], true, i);
            }
          }
        }
      });
    const el = document.getElementById("header");
    if (el) {
      el.classList.add("remove-container");
    }
  }

  public formEdit(slug: any) {
    this.isEdit = true;
    this.formName = slug;
    if (slug == "certificationPartTwoApplicationForm") {
      this.formPagination = 1;
    } else if (slug == "certificationDetails") {
      this.formPagination = 2;
    } else if (slug == "certificationPartTwoSupervisorInformation") {
      this.formPagination = 3;
    } else if (slug == "certificationPartTwoRefererenceInformation") {
      this.formPagination = 4;
    } else if (slug == "certificationSignature") {
      this.payLoadData.certificationSignature.isSignature = "";
      localStorage.setItem(
        "certificationFormPayload",
        JSON.stringify(this.payLoadData)
      );
      this.formPagination = 5;
    }
    const el = document.getElementById(this.mainId);
    if (el) {
      el.scrollIntoView({ behavior: "smooth" });
    }
  }

  public scopeOfActivityValidation(event: boolean) {
    this.validSOA = event;
  }

  public nextButton() {
    this.checkValidForm = true;
    this.checkSOAValid = !this.checkSOAValid;
    if (this.payLoadData.isEligibility) {
      if (this.formPagination == 5) {
        this.payLoadData.certificationSignature.submissionDate =
          moment().format("MM-DD-YYYY");
        if (this.payLoadData.certificationSignature.isSignature) {
          this.signatureInvalid = false;
        } else {
          this.checkValidForm = true;
          this.signatureInvalid = true;
          setTimeout(() => {
            const el = document.getElementsByClassName("is-invalid")[0];
            if (el) {
              el.scrollIntoView({ behavior: "smooth", block: "end" });
            }
          }, 100);
          return;
        }
      }

      this.$validator.validate().then(async (validate) => {
        if (this.formName === "certificationPartTwoRefererenceInformation") {
          this.referenceEmailDuplicateIndexs = utils.duplicateValueIdx(
            this.payLoadData,
            this.formName,
            true,
            "referenceEmailAddress"
          );
        }
        if (this.formName === "certificationPartTwoSupervisorInformation") {
          this.supervisorEmailDuplicateIndexs = utils.duplicateValueIdx(
            this.payLoadData,
            this.formName,
            true,
            "supervisorEmail"
          );
        }

        if (
          this.referenceEmailDuplicateIndexs.length === 0 &&
          this.supervisorEmailDuplicateIndexs.length === 0 &&
          validate &&
          this.validSOA
        ) {
          if (this.formPagination === 3) {
            const supervisorEmailValidate =
              this.payLoadData.certificationPartTwoSupervisorInformation.filter(
                (supervisor: any) => {
                  if (
                    supervisor.supervisorEmail.toLowerCase() ===
                    this.payLoadData.certificationPartTwoApplicationForm.email.toLowerCase()
                  ) {
                    return supervisor;
                  }
                }
              );
            if (supervisorEmailValidate.length) {
              this.errorMessage =
                "Supervisor email id and applicant email id must be different *";
              return;
            }
          }

          if (this.formPagination === 4) {
            const duplicateEmail =
              this.payLoadData.certificationPartTwoRefererenceInformation.filter(
                (reference: any) => {
                  if (
                    reference.referenceEmailAddress.toLowerCase() ===
                    this.payLoadData.certificationPartTwoApplicationForm.email.toLowerCase()
                  ) {
                    return reference;
                  }
                }
              );
            if (duplicateEmail.length) {
              this.errorMessage =
                "Applicant email id and reference email id must be different *";
              return;
            }
            const supervisorEmails =
              this.payLoadData.certificationPartTwoSupervisorInformation.map(
                (supervisor: any) => {
                  return supervisor.supervisorEmail.toLowerCase();
                }
              );
            const supervisorReferenceDuplicateEmail =
              this.payLoadData.certificationPartTwoRefererenceInformation.filter(
                (reference: any) => {
                  if (
                    supervisorEmails.includes(
                      reference.referenceEmailAddress.toLowerCase()
                    )
                  ) {
                    return reference;
                  }
                }
              );
            if (supervisorReferenceDuplicateEmail.length) {
              this.errorMessage =
                "Supervisor email id and reference email id must be different *";
              return;
            }
          }

          this.isLoading = true;
          this.isValid = true;
          this.checkValidForm = false;
          if (this.formPagination < this.formData.length) {
            this.isNavLoad = true;
            if (this.payLoadData.id == "") {
              await certificationFormApi
                .CertificationApplicationForm(
                  this.payLoadData.fillStatus,
                  this.payLoadData
                )
                .then((result: any) => {
                  this.isLoading = false;
                  this.certificateApplicantId = result.headers.location;
                  localStorage.setItem(
                    "certificateApplicationId",
                    this.certificateApplicantId
                  );
                  this.payLoadData.id = localStorage.getItem(
                    "certificateApplicationId"
                  );
                  localStorage.setItem(
                    "certificationFormPayload",
                    JSON.stringify(this.payLoadData)
                  );
                  if (!this.isEdit) {
                    this.nextPaginationUpdate();
                  }
                  const el = document.getElementById(this.mainId);
                  if (el) {
                    el.scrollIntoView({ behavior: "smooth" });
                  }
                })
                .catch((error) => {
                  this.errorMessage = utils.errorMessage(error);
                  this.isLoading = false;
                });
            } else {
              this.payLoadData.id = localStorage.getItem(
                "certificateApplicationId"
              )
                ? localStorage.getItem("certificateApplicationId")
                : this.payLoadData.id;
              localStorage.setItem(
                "certificationFormPayload",
                JSON.stringify(this.payLoadData)
              );
              const id = this.payLoadData.id;
              await certificationFormApi
                .updateCertificationForm(this.payLoadData, id)
                .then((data) => {
                  this.isLoading = false;
                  if (!this.isEdit) {
                    this.nextPaginationUpdate();
                  }
                  const el = document.getElementById(this.mainId);
                  if (el) {
                    el.scrollIntoView({ behavior: "smooth" });
                  }
                })
                .catch((error) => {
                  this.errorMessage = utils.errorMessage(error);
                  this.isLoading = false;
                });
            }
            if (this.isEdit) {
              this.formPagination = 6;
              this.formName = "certificationPartTwoApplicationForm";
            }
            if (!this.formCompletedIndex.includes(this.formPagination)) {
              this.formCompletedIndex.push(this.formPagination);
            }
            this.currentForm = this.payLoadData[this.formName];
            localStorage.setItem(
              "certificationFormFilledIndex",
              this.formCompletedIndex.toString()
            );
          }
        } else {
          setTimeout(() => {
            const el = document.getElementsByClassName("is-invalid")[0];
            if (el) {
              el.scrollIntoView({ behavior: "smooth", block: "end" });
            }
          }, 50);
        }
      });
    }
  }

  public nextPaginationUpdate() {
    const page = this.formPagination + 1;
    if (page == 1) {
      this.formName = "certificationPartTwoApplicationForm";
    } else if (page == 2) {
      this.formName = "certificationDetails";
    } else if (page == 3) {
      this.formName = "certificationPartTwoSupervisorInformation";
    } else if (page == 4) {
      this.formName = "certificationPartTwoRefererenceInformation";
    } else if (page == 5) {
      this.formName = "certificationSignature";
    }
    this.formPagination = page;
  }

  public saveDraft() {
    this.$bvToast.show("example-toast");
    this.isValid = true;
    localStorage.setItem(
      "certificationFormPayload",
      JSON.stringify(this.payLoadData)
    );
    this.saveDataDraft = true;

    setTimeout(() => {
      this.saveDataDraft = false;
    }, 1000);
  }

  public previousButton() {
    this.isEdit = false;
    this.validSOA = true;
    this.referenceEmailDuplicateIndexs = [];
    this.supervisorEmailDuplicateIndexs = [];
    if (this.formPagination !== 0) {
      const el = document.getElementById(this.mainId);
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
      this.formPagination = this.formPagination - 1;
      if (this.formPagination == 1) {
        this.formName = "certificationPartTwoApplicationForm";
      } else if (this.formPagination == 2) {
        this.formName = "certificationDetails";
      } else if (this.formPagination == 3) {
        this.formName = "certificationPartTwoSupervisorInformation";
      } else if (this.formPagination == 4) {
        this.formName = "certificationPartTwoRefererenceInformation";
      } else if (this.formPagination == 5) {
        this.formName = "certificationSignature";
      }
    }
  }

  public onSubmit() {
    this.$validator.validate().then((validate) => {
      if (validate) {
        this.payLoadData.certificationSignature.submissionDate =
          moment().format("MM-DD-YYYY");
        let payloadStorage: any = localStorage.getItem(
          "certificationFormPayload"
        );
        if (payloadStorage) {
          payloadStorage = JSON.parse(payloadStorage);
          this.payLoadData = { ...payloadStorage };
        }
        this.isLoading = true;
        this.payLoadData.fillStatus = "COMPLETED";
        this.payLoadData.id = localStorage.getItem("certificateApplicationId")
          ? localStorage.getItem("certificateApplicationId")
          : this.payLoadData.id;
        const id = this.payLoadData.id;
        certificationFormApi
          .updateCertificationForm(this.payLoadData, id)
          .then(() => {
            certificationFormApi
              .getCertificationApplicationForm(id)
              .then((result: any) => {
                const applicationCode = result.data.code;
                const someData = {
                  title: "Certified Young Adult Peer Support Specialist",
                  SubTitle: "Certification: Part Two",
                };
                this.$router.push({
                  path: "/form/submitted",
                  query: {
                    subTitle: someData.SubTitle,
                    applicationId: applicationCode,
                  },
                });
                this.isLoading = false;
              })
              .catch((error) => {
                this.errorMessage = utils.errorMessage(error);
                this.isLoading = false;
              });
            const el = document.getElementById(this.mainId);
            if (el) {
              el.scrollIntoView({ behavior: "smooth" });
            }
            localStorage.removeItem("certificationFormPayload");
            localStorage.removeItem("certificateApplicationId");
            localStorage.removeItem("certificationFormFilledIndex");
          })
          .catch((error) => {
            this.errorMessage = utils.errorMessage(error);
            this.isLoading = false;
          });
      }
    });
  }

  public eligibilityChecked(event: any) {
    this.payLoadData.isEligibility = event[0];
    this.isEligibility = true;
    this.formPagination = 1;
    if (!this.formCompletedIndex.includes(this.formPagination)) {
      this.formCompletedIndex.push(this.formPagination);
    }
    this.currentForm = this.payLoadData[this.formName];
    localStorage.setItem(
      "certificationFormFilledIndex",
      this.formCompletedIndex.toString()
    );
  }

  public closeToast() {
    this.$bvToast.hide("example-toast");
  }

  public numberValidation(evt: any, length?: number) {
    utils.onlyNumberKey(evt, length);
  }

  public avoidNumbersAndSpecialCharacter(evt: any) {
    utils.avoidNumbersAndSpecialCharacters(evt);
  }

  public telCharacterValidate(evt: any, length?: number) {
    utils.telCharacterValidate(evt, length);
  }

  public onlyAlphabets(evt: any, fieldName = "") {
    utils.onlyAlphabets(evt, fieldName);
  }

  public emptyEvent() {
    return;
  }

  public fieldValidation(
    value: any,
    eventName: string,
    fieldType: string,
    maxLength = 0,
    fieldName = ""
  ) {
    if (fieldType === "number") {
      this.eventBind[eventName](value, maxLength);
    } else if (fieldName === "state") {
      this.eventBind[eventName](value, fieldName);
    } else {
      this.eventBind[eventName](value);
    }
  }

  public navigateTab(slug: string, index: number) {
    if (this.formCompletedIndex.includes(index)) {
      this.isEdit = false;
      if (index > this.formPagination) {
        this.checkSOAValid = !this.checkSOAValid;

        if (index > 3) {
          this.supervisorEmailDuplicateIndexs = utils.duplicateValueIdx(
            this.payLoadData,
            "certificationPartTwoSupervisorInformation",
            true,
            "supervisorEmail"
          );
          if (this.supervisorEmailDuplicateIndexs.length) {
            this.formPagination = 3;
            this.formName = "certificationPartTwoSupervisorInformation";
            setTimeout(() => {
              const el = document.getElementsByClassName("is-invalid")[0];
              if (el) {
                el.scrollIntoView({ behavior: "smooth", block: "end" });
              }
            }, 50);
            return;
          }
          const supervisorEmailValidate =
            this.payLoadData.certificationPartTwoSupervisorInformation.filter(
              (supervisor: any) => {
                if (
                  supervisor.supervisorEmail.toLowerCase() ===
                  this.payLoadData.certificationPartTwoApplicationForm.email.toLowerCase()
                ) {
                  return supervisor;
                }
              }
            );
          if (supervisorEmailValidate.length) {
            this.formPagination = 3;
            this.formName = "certificationPartTwoSupervisorInformation";
            this.errorMessage =
              "Supervisor email id and applicant email id must be different *";
            return;
          }
        }

        if (index > 4) {
          this.referenceEmailDuplicateIndexs = utils.duplicateValueIdx(
            this.payLoadData,
            "certificationPartTwoRefererenceInformation",
            true,
            "referenceEmailAddress"
          );
          if (this.referenceEmailDuplicateIndexs.length) {
            this.formPagination = 4;
            this.formName = "certificationPartTwoRefererenceInformation";
            setTimeout(() => {
              const el = document.getElementsByClassName("is-invalid")[0];
              if (el) {
                el.scrollIntoView({ behavior: "smooth", block: "end" });
              }
            }, 50);
            return;
          }
          const referenceEmailValidate =
            this.payLoadData.certificationPartTwoRefererenceInformation.filter(
              (reference: any) => {
                if (
                  reference.referenceEmailAddress.toLowerCase() ===
                  this.payLoadData.certificationPartTwoApplicationForm.email.toLowerCase()
                ) {
                  return reference;
                }
              }
            );
          if (referenceEmailValidate.length) {
            this.formPagination = 4;
            this.formName = "certificationPartTwoRefererenceInformation";
            this.errorMessage =
              "Reference email id and applicant email id must be different *";
            return;
          }

          const supervisorEmails =
            this.payLoadData.certificationPartTwoSupervisorInformation.map(
              (supervisor: any) => {
                return supervisor.supervisorEmail.toLowerCase();
              }
            );
          const supervisorReferenceDuplicateEmail =
            this.payLoadData.certificationPartTwoRefererenceInformation.filter(
              (reference: any) => {
                if (
                  supervisorEmails.includes(
                    reference.referenceEmailAddress.toLowerCase()
                  )
                ) {
                  return reference;
                }
              }
            );
          if (supervisorReferenceDuplicateEmail.length) {
            this.formPagination = 4;
            this.formName = "certificationPartTwoRefererenceInformation";
            this.errorMessage =
              "Supervisor email id and reference email id must be different *";
            return;
          }
        }

        //signature validation
        this.checkValidForm = true;
        if (this.formPagination == 5) {
          this.payLoadData.certificationSignature.submissionDate =
            moment().format("MM-DD-YYYY");
          if (this.payLoadData.certificationSignature.isSignature) {
            this.signatureInvalid = false;
          } else {
            this.checkValidForm = true;
            this.signatureInvalid = true;
            setTimeout(() => {
              const el = document.getElementsByClassName("is-invalid")[0];
              if (el) {
                el.scrollIntoView({ behavior: "smooth", block: "end" });
              }
            }, 100);
            return;
          }
        }

        this.$validator.validateAll().then((validate: any) => {
          if (
            this.referenceEmailDuplicateIndexs.length === 0 &&
            this.supervisorEmailDuplicateIndexs.length === 0 &&
            validate &&
            this.validSOA
          ) {
            this.checkValidForm = false;
            const el = document.getElementById(this.mainId);
            if (el) {
              el.scrollIntoView({ behavior: "smooth" });
            }
            this.isValid = true;
            localStorage.setItem(
              "certificationFormPayload",
              JSON.stringify(this.payLoadData)
            );

            if (index === 6) {
              this.formPagination = index;
              this.formName = "certificationPartTwoApplicationForm";
            } else {
              this.formName = slug;
              this.formPagination = index;
            }
          } else {
            const el = document.getElementsByClassName("is-invalid")[0];
            if (el) {
              el.scrollIntoView({ behavior: "smooth", block: "end" });
            }
          }
        });
      } else {
        this.referenceEmailDuplicateIndexs = [];
        this.supervisorEmailDuplicateIndexs = [];
        this.validSOA = true;
        if (index === 6) {
          this.formPagination = index;
          this.formName = "certificationPartTwoApplicationForm";
        } else {
          this.formName = slug;
          this.formPagination = index;
        }
        const el = document.getElementById(this.mainId);
        if (el) {
          el.scrollIntoView({ behavior: "smooth" });
        }
      }
    }
  }

  public fieldUpdate(groupName = "", initial = false, supervisorIdx: number) {
    switch (groupName) {
      case "stillEmployed":
        if (
          this.payLoadData.certificationPartTwoSupervisorInformation[
            supervisorIdx
          ].stillEmployed === "No"
        ) {
          if (supervisorIdx !== undefined) {
            if (this.supervisorEndDateShowIdx.length) {
              this.supervisorEndDateShowIdx.push(supervisorIdx);
            } else {
              this.supervisorEndDateShowIdx = [supervisorIdx];
            }
            if (!this.supervisorStartDateShowIdx.includes(supervisorIdx)) {
              if (this.supervisorStartDateShowIdx.length) {
                this.supervisorStartDateShowIdx.push(supervisorIdx);
              } else {
                this.supervisorStartDateShowIdx = [supervisorIdx];
              }
            }
          }
          this.supervisorUIUpdate = utils.UIUpdateUsingKey(
            this.supervisorUIUpdate
          );
        } else {
          if (!this.supervisorStartDateShowIdx.includes(supervisorIdx)) {
            if (this.supervisorStartDateShowIdx.length) {
              this.supervisorStartDateShowIdx.push(supervisorIdx);
            } else {
              this.supervisorStartDateShowIdx = [supervisorIdx];
            }
          }
          const idx = this.supervisorEndDateShowIdx.findIndex(
            (element) => element === supervisorIdx
          );
          if (idx > -1) {
            this.supervisorEndDateShowIdx.splice(idx);
            this.payLoadData.certificationPartTwoSupervisorInformation[
              supervisorIdx
            ].endDate = "";
          }
          this.supervisorUIUpdate = utils.UIUpdateUsingKey(
            this.supervisorUIUpdate
          );
        }
        break;
      default:
        break;
    }
  }

  public addAnother() {
    this.payLoadData.certificationPartTwoSupervisorInformation.push({
      supervisorAgencyOrOrganization: "",
      supervisorPositionOrTitle: "",
      stillEmployed: "",
      startDate: "",
      endDate: "",
      supervisorFirstName: "",
      supervisorLastName: "",
      supervisorCredentials: "",
      supervisorPhone: "",
      supervisorEmail: "",
      descripePeerSupportResponsibity: "",
    });
    this.supervisorFormFields.push(this.tempSupervisorFormFields);
    this.supervisorUIUpdate = utils.UIUpdateUsingKey(this.supervisorUIUpdate);
  }

  public removeAddedSupervisor(indexForm: number) {
    this.payLoadData.certificationPartTwoSupervisorInformation.splice(
      indexForm,
      1
    );
    this.supervisorFormFields.splice(indexForm, 1);
    this.supervisorUIUpdate = utils.UIUpdateUsingKey(this.supervisorUIUpdate);
  }

  public classNameChooser(inputClass: string) {
    return utils.classNameChooser(inputClass);
  }

  public closdedErrorPopup(event: any) {
    if (event) {
      this.errorMessage = "";
    }
  }
  public goBack() {
    if (this.$route.query.isDraft) {
      localStorage.removeItem("certificationFormPayload");
      localStorage.removeItem("certificationFormFilledIndex");
    }
    this.$router.push({
      path: `/application`,
      query: {
        form: "certification",
      },
    });
    const el = document.getElementById("header");
    if (el) {
      el.classList.add("active");
      el.classList.remove("remove-container");
    }
  }
}
