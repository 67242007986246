import { http } from "@/providers/http";
export class LetterOfReferenceFormApi {
  private static INSTANCE = new LetterOfReferenceFormApi();

  static get instance() {
    return this.INSTANCE;
  }

  // GET for Renewal application

  public getRenewalApplicationForm(token: string) {
    const endpoint = `cyapss/certificate/applicant/details?formName=reference`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Basic ${token}`,
    };
    return http.get(endpoint, headers).then((response) => {
      return response;
    });
  }

  // POST FOR Letter Of Reference

  public postRenewalApplicationForm(
    token: string,
    letterOfReferencePayload: any
  ) {
    const data = {
      data: {
        letterOfReferenceReferenceDetails:
          letterOfReferencePayload.letterOfReferenceReferenceDetails,
        letterOfReferenceApplicantInformation:
          letterOfReferencePayload.letterOfReferenceApplicantInformation,
        letterOfReferenceSignatureAndSubmission:
          letterOfReferencePayload.letterOfReferenceSignatureAndSubmission,
      },
    };
    const endpoint = `cyapss/certificate/reference`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Basic ${token}`,
    };
    return http.post(endpoint, headers, data).then((response) => {
      return response;
    });
  }
}

export const letterOfReferenceFormApi = LetterOfReferenceFormApi.instance;
